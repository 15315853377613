import { React, useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import axios from "axios";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import MapProject from '../Components/project/MapComponent';
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import { CMS_API_ENDPOINT, CMS_PORTAL_BUCKET, WEBSITE_X_API_KEY, WEBSITE_URL, AGENT_PORTAL_URL } from '../common/Constants';
import FbLikeGridGallery from "../Components/project-detail/FbLikeGridGallery";
import AmenitiesComponent from "../Components/project-detail/AmenitiesComponent"
import EnquireAboutThisPropertyComponent from "../Components/project-detail/EnquireAboutThisPropertyComponent"
import { useApp } from '../hooks/AppHooks';
import { Spin, Card, Row, Col, Image, Carousel, Typography, Space, Button } from "antd";
import Titles from "antd/es/typography/Title";
import BookDemoComponent from '../Components/contactPage/BookDemoComponent';

function ProjectDetail() {
    let { id } = useParams();
    const { state } = useLocation();
    const { projectValue, specificProjectValue, projectBannerValue, headerValue } = useApp();

    const [open, setOpen] = useState(false);
    const [eventName, setEventName] = useState();
    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(false);
    const [isBookDemo, setIsBookDemo] = useState(false);

    const [content, setContent] = useState([]);
    const [projectFeatures, setProjectFeatures] = useState([]);
    const [projectProperties, setprojectProperties] = useState([]);
    const [categoryImages, setCategoryImages] = useState("");
    const [selectedCategoryName, setCategoryName] = useState("");
    const [showCreateAppointmentModal, setShowCreateAppointmentModal] = useState(false);
    const [categoryDisable, setCategoryDisable] = useState(false);
    const { Text } = Typography;
    const location = useLocation();
    const langValue = location.search.split('=');
    const publicPath = process.env.PUBLIC_URL;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        fetchProjectMedia("gallery");
    }, [id]);

    useEffect(() => {
        if (projectBannerValue && Object.keys(projectBannerValue)?.length) {
            setProjectFeatures(projectBannerValue?.Banner && projectBannerValue?.Banner?.length ? projectBannerValue?.Banner[0]?.projectFeature : "");
            setContent(projectBannerValue?.Content ? projectBannerValue?.Content[0] : null);
            setprojectProperties(projectBannerValue?.Content ? projectBannerValue?.Content[0]?.projectProperties : null)
        }
    }, [projectBannerValue]);

    const showBecomeOurPartnerModal = (eventName) => {
        document.body.style.overflow = '';
        setEventName(eventName);
        setIsBecomeOurPartnerModalShow(true);
    }

    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto';
        setIsBecomeOurPartnerModalShow(false);
    }

    async function fetchProjectMedia(categoryName) {
        try {
            setCategoryDisable(true);
            setCategoryName(categoryName);
            const projectMedia = await axios.get(`${CMS_API_ENDPOINT}project/project-media-website?id=${state?.id}&category=${categoryName}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });
            if (projectMedia?.data?.status) {
                setCategoryImages(projectMedia?.data?.data);
            }
        } catch (error) {
            console.error("error ", error);
        } finally {
            setCategoryDisable(false);
        }
    }

    const handleShare = async () => {
        try {
            await navigator.share({ 
                url: `${WEBSITE_URL}projects/${specificProjectValue?.slug}`,
                title: `${specificProjectValue?.title} project website link`
            });
        } catch (error) {
            console.log('Failed while sharing', error);
        }
    }
    const handleShareOnWhatsApp = async () => {
        window.open(`https://web.whatsapp.com/send?text=${WEBSITE_URL}projects/${specificProjectValue?.slug}`, '_blank');
    };

    const supportOnWhatsApp = () => {
        window.open("https://wa.me/908505327532");
    };

    const handleLoginAgentPortal = () => {
        window.open(`${AGENT_PORTAL_URL}`);
    };

    const openModal = () => {
        setOpen(true);
    }

    const closeModal = () => setOpen(false);

    const showBookDemo = () => {
        document.body.style.overflow = ''
        setIsBookDemo(true);
    }

    const closeBookDemo = () => {
        document.body.style.overflow = 'auto'
        setIsBookDemo(false);
    }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    return (
        <>
            <BecomeOurPartnerButton event="contactsleadForm" />
            <>
                {
                    specificProjectValue && projectBannerValue && headerValue ?
                        <>
                            <div className="ProjectDetail mt-70" >
                                <div className="ProjectDetailSlider">
                                    <div className='projectDetailHeading'>
                                        <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${specificProjectValue?.featuredImage && specificProjectValue?.featuredImage}`} className="img-fluid" />
                                        <div className='bannerImageHeading container'>
                                            <div className='projectDetailHeadingBox'>
                                                <h3> {specificProjectValue?.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <EnrollmentModalComponent
                                    isOpen={isBecomeOurPartnerModalShow}
                                    onRequestClose={closeBecomeOurPartnerModalModal}
                                    event="partnerleadForm"
                                    insiderEventName={eventName}
                                />
                                  <BookDemoComponent
                                    isOpen={isBookDemo}
                                    onRequestClose={closeBookDemo}
                                    event="contactsleadForm"
                                    insiderEventName="create-appointment" />
                                <div className='newProjectDetailSection'>
                                    <div className="headingWithButton">
                                        <div className="project-container">
                                            <div className="row justify-content-between">
                                                <Row gutter={24} id="project">
                                                    <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 1 }} className="details">
                                                        <Card className="projectDetails-card1 cards-shadow" >
                                                            <Titles className="title" level={2}>{specificProjectValue?.title}</Titles>
                                                            <p className='location-info'> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 24.789 35.413">
                                                                <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M19.895,3A12.385,12.385,0,0,0,7.5,15.395c0,9.3,12.395,23.018,12.395,23.018S32.289,24.69,32.289,15.395A12.385,12.385,0,0,0,19.895,3Zm0,16.821a4.427,4.427,0,1,1,4.427-4.427A4.428,4.428,0,0,1,19.895,19.821Z" transform="translate(-7.5 -3)" fill="#dc6641" />
                                                            </svg>   {specificProjectValue?.address}, {specificProjectValue?.city}, {specificProjectValue?.country}</p>
                                                            <Row gutter={24} className='project-content'>
                                                                <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                                                    <p className="content">
                                                                        {specificProjectValue?.mediumDescription}
                                                                    </p>
                                                                </Col>
                                                                <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }} className="long-description">
                                                                    <div className='long-description-ui' dangerouslySetInnerHTML={{ __html: `${specificProjectValue?.longDescription}` }}>
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                            <Row justify="end" className="projects-delivery-date">
                                                                <Col>
                                                                    <h4 className='distance-name'>{content?.projectDetails && content?.projectDetails[6]?.deliveryDate} : {specificProjectValue?.deliveryDate}</h4>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={[16, 16]} >
                                                                {projectProperties && projectProperties?.length ?
                                                                    <>
                                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} sm={{ span: 24 }} className="project-type">
                                                                            <div className='projects'>
                                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${projectProperties && projectProperties[0]?.imageLink}`} />
                                                                                <p className="project-detailscard">{projectProperties[0]?.title}</p>
                                                                                <Text className="project-details">{(Math.round(specificProjectValue?.minBuiltUpArea * 100) / 100).toFixed(2)}-{(Math.round(specificProjectValue?.maxBuiltUpArea * 100) / 100).toFixed(2)} sqm.</Text>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} sm={{ span: 24 }} className="project-type">
                                                                            <div className='projects'>
                                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${projectProperties && projectProperties[1]?.imageLink}`} />
                                                                                <p className="project-detailscard">{projectProperties[1]?.title}</p>
                                                                                <Text className="project-details">{specificProjectValue?.unitType} </Text>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} sm={{ span: 24 }} className="project-type">
                                                                            <div className="projects">
                                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${projectProperties && projectProperties[2]?.imageLink}`} />
                                                                                <p className="project-detailscard">{projectProperties[2]?.title}</p>
                                                                                <Text className="project-details">{specificProjectValue?.propertyType}</Text>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={{ span: 24 }} md={{ span: 12 }} xl={{ span: 6 }} sm={{ span: 24 }} className="project-type">
                                                                            <div className="projects">
                                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${projectProperties && projectProperties[3]?.imageLink}`} />
                                                                                <p className="project-detailscard">{projectProperties[3]?.title}</p>
                                                                                <Text className="project-details">{Number((Math.round(specificProjectValue?.minPrice * 100) / 100).toFixed(2)).toLocaleString("en-US")}-{Number((Math.round(specificProjectValue?.maxPrice * 100) / 100).toFixed(2)).toLocaleString("en-US")}</Text>
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                    : null}
                                                            </Row>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                                        <Card className="projectDetails-card cards-shadow" >
                                                            <Row>
                                                                <Col span={24}>
                                                                        <Carousel
                                                                            autoplay
                                                                            dots={false}>
                                                                            {categoryImages?.length && categoryImages?.map((row, index) => (
                                                                                <div key={index} className="projectDetail-carousel">
                                                                                    <img className="img" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${encodeURIComponent(row?.fileS3Path)}`}></img>
                                                                                </div>
                                                                            ))}
                                                                        </Carousel >
                                                                </Col>
                                                                <Col span={24} className="project-image" >
                                                                    <Space className='info-div'>
                                                                        <Button  className={langValue[1]==='2'?'turkey-info-icons':'info-icons'} onClick={handleLoginAgentPortal}><svg className='btn-icons' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 20 16">
                                                                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                                                                        </svg> {content?.projectDetails && content?.projectDetails[5]?.marketing}</Button>
                                                                        <Button  className={langValue[1]==='2'?'turkey-info-icons':'info-icons'} onClick={handleLoginAgentPortal}> <svg className='btn-icons' width="18" height="15" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M22.2204 17.9554C21.7012 17.6525 21.009 17.7823 20.6629 18.3015C18.8457 20.8109 15.8604 22.455 12.529 22.455C6.94775 22.455 2.44816 17.9121 2.49143 12.3309C2.53469 6.7929 7.03428 2.33658 12.6155 2.37984C15.9469 2.42311 18.8457 4.02392 20.6629 6.53331C21.009 7.00923 21.7012 7.18229 22.2204 6.87944C22.8261 6.53331 22.9992 5.71127 22.6098 5.10556C20.36 2.03372 16.7257 0.000249862 12.6155 0.000249862C5.86612 -0.0430155 0.198363 5.53821 0.111833 12.2876C0.0253019 19.2533 5.60653 24.9211 12.5722 24.9211C16.7257 24.9211 20.36 22.8876 22.6531 19.8158C22.9992 19.1235 22.8261 18.3015 22.2204 17.9554Z" fill="#eb5f1f" />
                                                                            <path d="M9.37086 7.74478C10.1929 7.22559 11.188 6.966 12.2264 6.966C15.0819 7.00927 17.4615 9.34559 17.548 12.2011C17.6345 15.2297 15.1684 17.7391 12.1831 17.7391C11.188 17.7391 10.2362 17.4362 9.41412 16.9603C8.9382 16.6574 8.33249 16.744 7.9431 17.1334C7.38065 17.6958 7.51045 18.6044 8.15943 19.037C9.37086 19.7725 10.7553 20.1619 12.1831 20.1619C14.2598 20.1619 16.2068 19.3399 17.7211 17.8689C19.1921 16.3978 20.0141 14.4509 20.0141 12.3309C20.0141 10.2109 19.1921 8.30723 17.7211 6.79294C16.25 5.32192 14.3031 4.49988 12.1831 4.49988C10.7553 4.49988 9.32759 4.88927 8.15943 5.62478C7.51045 6.01416 7.38065 6.966 7.9431 7.52845C8.28922 7.9611 8.89494 8.04763 9.37086 7.74478Z" fill="#eb5f1f" />
                                                                            <path d="M11.7502 15.4892C13.4945 15.4892 14.9085 14.0752 14.9085 12.3309C14.9085 10.5865 13.4945 9.17249 11.7502 9.17249C10.0058 9.17249 8.5918 10.5865 8.5918 12.3309C8.5918 14.0752 10.0058 15.4892 11.7502 15.4892Z" fill="#eb5f1f" />
                                                                            <path d="M16.2933 27.4738C16.5096 28.1228 17.2451 28.4689 17.8941 28.2093C20.5765 27.0844 22.9129 25.3538 24.73 23.104C25.1627 22.5415 25.0761 21.7628 24.4704 21.3734C23.9512 21.0272 23.259 21.1138 22.8696 21.5897C21.312 23.4934 19.2786 25.0509 16.9423 26.0028C16.4231 26.2624 16.1202 26.9113 16.2933 27.4738Z" fill="white" />
                                                                        </svg> {content?.projectDetails && content?.projectDetails[5]?.cuverse}</Button>
                                                                        <Button onClick={()=> showBookDemo()}  className={langValue[1]==='2'?'turkey-info-icons':'info-icons'}><svg className='btn-icons' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 20 16">
                                                                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                        </svg> {content?.projectDetails && content?.projectDetails[5]?.appointment}</Button>
                                                                        <Button  className={langValue[1]==='2'?'turkey-info-icons':'info-icons'} onClick={() => handleShare()} ><svg className='btn-icons' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 20 16">
                                                                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3" />
                                                                        </svg> {content?.projectDetails && content?.projectDetails[5]?.share}</Button>
                                                                        <Button className={langValue[1]==='2'?'turkey-info-icons':'info-icons'} onClick={() => supportOnWhatsApp()} ><svg  className='btn-icons' fill="#6DCAA9" xmlns="http://www.w3.org/2000/svg" width="16" height="15px" viewBox="0 0 737.509 740.824" id="whatsapp"><path d="M630.056 107.658C560.727 38.271 468.525.039 370.294 0 167.891 0 3.16 164.668 3.079 367.072c-.027 64.699 16.883 127.855 49.016 183.523L0 740.824l194.666-51.047c53.634 29.244 114.022 44.656 175.481 44.682h.151c202.382 0 367.128-164.689 367.21-367.094.039-98.088-38.121-190.32-107.452-259.707m-259.758 564.8h-.125c-54.766-.021-108.483-14.729-155.343-42.529l-11.146-6.613-115.516 30.293 30.834-112.592-7.258-11.543c-30.552-48.58-46.689-104.729-46.665-162.379C65.146 198.865 202.065 62 370.419 62c81.521.031 158.154 31.81 215.779 89.482s89.342 134.332 89.311 215.859c-.07 168.242-136.987 305.117-305.211 305.117m167.415-228.514c-9.176-4.591-54.286-26.782-62.697-29.843-8.41-3.061-14.526-4.591-20.644 4.592-6.116 9.182-23.7 29.843-29.054 35.964-5.351 6.122-10.703 6.888-19.879 2.296-9.175-4.591-38.739-14.276-73.786-45.526-27.275-24.32-45.691-54.36-51.043-63.542-5.352-9.183-.569-14.148 4.024-18.72 4.127-4.11 9.175-10.713 13.763-16.07 4.587-5.356 6.116-9.182 9.174-15.303 3.059-6.122 1.53-11.479-.764-16.07-2.294-4.591-20.643-49.739-28.29-68.104-7.447-17.886-15.012-15.466-20.644-15.746-5.346-.266-11.469-.323-17.585-.323-6.117 0-16.057 2.296-24.468 11.478-8.41 9.183-32.112 31.374-32.112 76.521s32.877 88.763 37.465 94.885c4.587 6.122 64.699 98.771 156.741 138.502 21.891 9.45 38.982 15.093 52.307 19.323 21.981 6.979 41.983 5.994 57.793 3.633 17.628-2.633 54.285-22.19 61.932-43.616 7.646-21.426 7.646-39.791 5.352-43.617-2.293-3.826-8.41-6.122-17.585-10.714"></path></svg> {content?.projectDetails && content?.projectDetails[5]?.support}</Button>
                                                                        <Button  className={langValue[1]==='2'?'turkey-info-icons':'info-icons'} onClick={() => handleShareOnWhatsApp()}  > <svg className='btn-icons' fill="#6DCAA9" xmlns="http://www.w3.org/2000/svg" width="16px" height="15px" viewBox="0 0 737.509 740.824" id="whatsapp"><path d="M630.056 107.658C560.727 38.271 468.525.039 370.294 0 167.891 0 3.16 164.668 3.079 367.072c-.027 64.699 16.883 127.855 49.016 183.523L0 740.824l194.666-51.047c53.634 29.244 114.022 44.656 175.481 44.682h.151c202.382 0 367.128-164.689 367.21-367.094.039-98.088-38.121-190.32-107.452-259.707m-259.758 564.8h-.125c-54.766-.021-108.483-14.729-155.343-42.529l-11.146-6.613-115.516 30.293 30.834-112.592-7.258-11.543c-30.552-48.58-46.689-104.729-46.665-162.379C65.146 198.865 202.065 62 370.419 62c81.521.031 158.154 31.81 215.779 89.482s89.342 134.332 89.311 215.859c-.07 168.242-136.987 305.117-305.211 305.117m167.415-228.514c-9.176-4.591-54.286-26.782-62.697-29.843-8.41-3.061-14.526-4.591-20.644 4.592-6.116 9.182-23.7 29.843-29.054 35.964-5.351 6.122-10.703 6.888-19.879 2.296-9.175-4.591-38.739-14.276-73.786-45.526-27.275-24.32-45.691-54.36-51.043-63.542-5.352-9.183-.569-14.148 4.024-18.72 4.127-4.11 9.175-10.713 13.763-16.07 4.587-5.356 6.116-9.182 9.174-15.303 3.059-6.122 1.53-11.479-.764-16.07-2.294-4.591-20.643-49.739-28.29-68.104-7.447-17.886-15.012-15.466-20.644-15.746-5.346-.266-11.469-.323-17.585-.323-6.117 0-16.057 2.296-24.468 11.478-8.41 9.183-32.112 31.374-32.112 76.521s32.877 88.763 37.465 94.885c4.587 6.122 64.699 98.771 156.741 138.502 21.891 9.45 38.982 15.093 52.307 19.323 21.981 6.979 41.983 5.994 57.793 3.633 17.628-2.633 54.285-22.19 61.932-43.616 7.646-21.426 7.646-39.791 5.352-43.617-2.293-3.826-8.41-6.122-17.585-10.714"></path></svg> {content?.projectDetails && content?.projectDetails[5]?.shareOnWhatsApp}</Button>
                                                                    </Space>
                                                                </Col>
                                                            </Row>

                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row gutter={24} className="amenities-card">
                                                    <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                                        <Card className="projectDetails-card1 cards-shadow">
                                                            <h3 className="distance">{content?.projectDetails && content?.projectDetails[1]?.amenities}</h3>
                                                            <div className="location-part-content">
                                                                <p className="content" >{specificProjectValue?.amenitiesDescription}</p>
                                                                <AmenitiesComponent />
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                    {content?.projectDetails && specificProjectValue?.nearbyPlace?.length ?
                                                        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                                            <Card className="projectDetails-card distance-card cards-shadow" >
                                                                <h3 className="distance">{content?.projectDetails && content?.projectDetails[2]?.distances}</h3>
                                                                <div className='projectDetails-distance location-part-content'>
                                                                    <Row justify="space-between" gutter={24}>
                                                                        <Col xs={{ span: 24, order: 1 }} xl={{ span: 12, order: 2 }}>
                                                                            <h4 className='distance-name'>{content?.projectDetails[4]?.airport}</h4>
                                                                            {specificProjectValue?.nearbyPlace[0]?.airportFields
                                                                                && specificProjectValue?.nearbyPlace[0]?.airportFields?.length > 0
                                                                                && specificProjectValue?.nearbyPlace[0]?.airportFields?.map((item, index) => {

                                                                                    return (
                                                                                        <div key={index} className="menu-content">
                                                                                            <a>{item?.location}</a><span>{item?.distance}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </Col>
                                                                        <Col xs={{ span: 24, order: 2 }} xl={{ span: 12, order: 2 }}>
                                                                            <h4 className='distance-name'>{content?.projectDetails[4]?.mall}</h4>
                                                                            {specificProjectValue?.nearbyPlace[0]?.mallFields
                                                                                && specificProjectValue?.nearbyPlace[0]?.mallFields?.length > 0
                                                                                && specificProjectValue?.nearbyPlace[0]?.mallFields?.map((item, index) => {
                                                                                    return (
                                                                                        <div key={index} className="menu-content">
                                                                                            <a>{item?.location}</a><span>{item?.distance}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row justify="space-between" gutter={24}>
                                                                        <Col xs={{ span: 24, order: 3 }} xl={{ span: 12, order: 2 }}>
                                                                            <h4 className='distance-name' >{content?.projectDetails && content?.projectDetails[4]?.publicTransport}</h4>
                                                                            {specificProjectValue?.nearbyPlace[0]?.transportFields
                                                                                && specificProjectValue?.nearbyPlace[0]?.transportFields?.length > 0
                                                                                && specificProjectValue?.nearbyPlace[0]?.transportFields?.map((item, index) => {
                                                                                    return (
                                                                                        <div key={index} className="menu-content">
                                                                                            <a>{item?.location}</a><span>{item?.distance}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </Col>
                                                                        <Col xs={{ span: 24, order: 4 }} xl={{ span: 12, order: 2 }}>
                                                                            <h4 className='distance-name' >{content?.projectDetails[4]?.hospital}</h4>
                                                                            {specificProjectValue?.nearbyPlace[0]?.hospitalFields
                                                                                && specificProjectValue?.nearbyPlace[0]?.hospitalFields?.length > 0
                                                                                && specificProjectValue?.nearbyPlace[0]?.hospitalFields?.map((item, index) => {
                                                                                    return (
                                                                                        <div key={index} className="menu-content">
                                                                                            <a>{item?.location}</a><span>{item?.distance}</span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                        : null}
                                                </Row>
                                                <Row justify="start" gutter={24} className="amenities-card location-card">
                                                    <Col xs={{ span: 24 }} xl={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                                        <Card className='cards-shadow'>
                                                            <h4 className="distance">{content?.projectDetails && content?.projectDetails[3]?.location}</h4>
                                                            {specificProjectValue?.googleMapEmbed ?
                                                                <iframe src={specificProjectValue?.googleMapEmbed} className="projectDetails-location" aria-hidden="false" frameborder="0 0 0 0"></iframe>
                                                                :
                                                                <div className="col-12 mb-2 text-center text-light">No data available. </div>
                                                            }
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row justify="start" gutter={24} className="inventory-card">
                                                    <Col xs={{ span: 24 }} xl={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                                                        <Card className='cards-shadow'>
                                                            <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                                <img className="projectDetails-inventory" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${projectProperties && projectProperties[4]?.imageLink}`} />
                                                                <div className='login-inventory' onClick={handleLoginAgentPortal}>
                                                                    {content?.projectDetails && content?.projectDetails[7]?.loginInventory}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    open ?
                                        <MapProject closeModal={() => closeModal()} openModal={() => openModal()} show={open} />
                                        :
                                        ''
                                }
                               
                            </div>
                        </>
                        : <Spin className="page-spin-loading" />}
            </>
        </>
    );
}

export default ProjectDetail;