import React, { useEffect, useState } from "react";

import { Link, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Select, Form } from "antd";

import { AGENT_PORTAL_URL, CMS_PORTAL_BUCKET } from "./Constants";
import { useApp } from "../hooks/AppHooks";

function Navbar() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { languageValue, headerValue, language } = useApp();
  const langId = location.search.split("=")[1];
  const publicPath = process.env.PUBLIC_URL;
  const pathName = location.pathname.split('/')[1];

  useEffect(() => {
    $('.navLinkMenu').on('click', function () {
      $('#navbarNavAltMarkup').collapse('hide');
    });
    $('.navbar-brand').on('click', function () {
      $('#navbarNavAltMarkup').collapse('hide');
    });
    if (languageValue) {
      const data = languageValue?.find((e) => langId ? e.id === Number(langId) : e.name === language);
      form.setFieldsValue({
        language: data?.id
      })
    }
  }, [location, languageValue, langId]);

  const handleLanguageChange = (value) => {
    navigate(`?lang=${value}`);
    window.location.reload();
    $(".lang-option").on("click", function () {
      $("#languageOption").collapse("hide");
    });
    $(".navbar-brand").on("click", function () {
      $("#languageOption").collapse("hide");
    });
  }
  $(".nav-btn").on("click", function () {
    $(".collapse.show").removeClass("show");
  });
  const handleUrlChange = (value) => {
    window.location.replace(pathName === "projects" ? `/projects?lang=${value}` : `/blogs?lang=${value}`)
  }

  const getPopupContainer = () => {
    return document.getElementById('languageSelect');
  };

  return (
    headerValue && Object.keys(headerValue)?.length ?
      <>
        <div className="mainNavBar" id="languageSelect">
          <nav className="navbar navbar-expand-lg" id="navbarMain">
            <div className="container">
              <button className="navbar-toggler nav-btn" id="myCollapsible" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" ></span>
              </button>
              {headerValue?.logo ?
                <Link className="navbar-brand" to={langId ? `/?lang=${langId}` : "/"}>
                  <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${headerValue.logo}`} height="28" />
                </Link> : null
              }
              <a className="d-sm-none d-block " href={AGENT_PORTAL_URL}>
                <img className="login-icon" src={publicPath + "/assets/images/logo/portalLoginImg.svg"} height="28" width="28" /></a>
              <ul className="signNavbar d-lg-none d-sm-block d-none">
                <li className="nav-item">
                  <a className="cursor-pointer" href={AGENT_PORTAL_URL}>
                    <text className="signInButton">
                      {headerValue?.loginButtonName ? headerValue?.loginButtonName : null}
                    </text>
                    <div className="loginImagePosition">
                      <img src={publicPath + "/assets/images/logo/portalLoginImg.svg"} />
                    </div>
                  </a>
                </li>
              </ul>
              <button
                className="d-sm-block d-md-block d-lg-none d-block nav-btn lang-btn"
                id="nav-btn"
                data-bs-target="#languageOption"
                data-bs-toggle="collapse"
                aria-controls="languageOption"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <img
                  src={publicPath + "/assets/images/logo/lang-logo.svg"}
                  className="lang-logo"
                />
              </button>
              <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
                {headerValue?.menu ?
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbarMenu">
                    <li className="nav-item">
                      <NavLink className="nav-link navLinkMenu" to={langId ? `/aboutus?lang=${langId}` : '/aboutus'}>{headerValue?.menu[0]?.aboutUs}</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link navLinkMenu" to={langId ? `/agents?lang=${langId}` : '/agents'}>{headerValue?.menu[0]?.agents}</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link navLinkMenu" to={langId ? `/developer?lang=${langId}` : '/developer'}>{headerValue?.menu[0]?.developers}</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link navLinkMenu" to={langId ? `/projects?lang=${langId}` : '/projects'}>{headerValue?.menu[0]?.projects}</NavLink>
                    </li>
                    <div className="dropdown">
                      <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <li>
                          <NavLink className="dropdown-item" style={{
                            color: pathName === "blogs" || pathName === "blog" ? '#dc6641' : '',
                            fontWeight: pathName === "blogs" || pathName === "blog" ? "700" : ''
                          }}>{headerValue?.menu[0]?.articles}
                          </NavLink>
                        </li>
                      </button>
                      <ul className="dropdown-menu">
                        <li className="blogList navLinkMenu"><NavLink className="dropdown-item dropdownBlog" to={langId ? `/blogs?lang=${langId}` : '/blogs'}>{headerValue?.menu[0]?.blogs}</NavLink></li>
                      </ul>
                    </div>
                    <li className="nav-item">
                      <NavLink className="nav-link navLinkMenu" to={langId ? `/contact?lang=${langId}` : '/contact'}>{headerValue?.menu[0]?.contactUs}</NavLink>
                    </li>
                  </ul> : null}
              </div>
              <div className="collapse navbar-collapse" id="languageOption">
                {languageValue && languageValue.length ?
                  <ul id="nav-lang-list" className="navbar-nav me-auto mb-2 mb-lg-0 navbarMenu d-lg-none">
                    {languageValue?.map((languageList, index) => {
                      return (
                        <li
                          onClick={() => pathName === "projects" || pathName === "blog" && id ? handleUrlChange(languageList.id) : handleLanguageChange(languageList.id)}
                          className={`lang-option ${langId ? Number(langId) === languageList.id && 'active' : languageList.id === 1 ? 'active' : ''}`}
                          key={index}
                        >
                          {languageList.name}
                        </li>
                      );
                    })}
                  </ul> : null}
              </div>
              <ul className={`${headerValue ? "" : "hidden-header-btn"} signNavbar d-lg-block d-none`}>
                <li className="nav-item">
                  <a className="cursor-pointer" href={AGENT_PORTAL_URL}>
                    <text className="signInButton">
                      {headerValue ? headerValue?.loginButtonName : null}
                    </text>
                    <div className="loginImagePosition">
                      <img src={publicPath + "/assets/images/logo/portalLoginImg.svg"} />
                    </div>
                  </a>
                </li>
              </ul>
              {(languageValue && languageValue?.length && pathName != "mipim" &&
                pathName != "alya4mevsim_en" && pathName != "alya4mevsim_tr" &&
                pathName != "skyland" && pathName != "temaIstanbul" && !location.hash.length) ?
                <ul className="signNavbar languageDropDown d-lg-block d-none">
                  <li className="nav-item">
                    <Form form={form} className="languagePosition">
                      <Form.Item name="language">
                        <Select
                          getPopupContainer={getPopupContainer}
                          className="select-box" placeholder="Select Your language"
                          style={{ width: 150, marginLeft: "10%", marginTop: "-5%" }}
                          onChange={pathName === "projects" || pathName === "blog" && id ? (e) => handleUrlChange(e) : handleLanguageChange}
                          options={languageValue && languageValue?.map((languageList) => {
                            return { value: languageList.id, label: languageList.name }
                          })}
                        />
                      </Form.Item>
                    </Form>
                  </li>
                </ul> : null}
            </div>
          </nav>
        </div>
      </> : null
  )
}
export default Navbar;
