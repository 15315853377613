import React from "react";
import { DefaultPlayer as Video } from 'react-html5video';
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function EventComponent() {
    const { agentsValue } = useApp();
    return (
        <>
            {agentsValue["Events"] && agentsValue["Events"]?.length ?
                <div className="eventSection" id="events">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <h3>
                                <span className="underLineClass">
                                    {agentsValue["Events"][0].heading}
                                </span>
                            </h3>
                            <div className="col-md-5">
                                <div className="eventContent">
                                    <p>{agentsValue["Events"][0].content}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="eventImage">
                                    <Video className="videoPlayer" preload playsInline autoPlay controls={false}
                                        playButton loop muted
                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Events"][0].videoLink}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </>
    )
}
export default EventComponent;