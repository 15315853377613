import React from "react";

const FifthCitizenShip = () => {
    return (
        <>
            <div className="citizenShipSection">
                <div className="container-fluid">
                    <div className="row justify-content-evenly">
                        <div className="col-xxl-5 col-xl-6">
                            <div className="bookPayment d-flex">
                                <img src="/assets/images/cityscape/doller.png" className="img-fluid doller" />
                                <div>
                                    <h6>Book With A Small Down</h6>
                                    <h6>Payment Of $5000 USD</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5">
                            <div className="elgibleCiti d-flex">
                                <img src="/assets/images/cityscape/passport.png" className="img-fluid passport" />
                                <div>
                                    <h6>Eligible For</h6>
                                    <h6>Citizenship</h6>
                                </div>
                                <img src="/assets/images/cityscape/turkey_flag.png" className="img-fluid flag" />
                            </div>
                        </div>
                    </div>
                    <div className="visitDetail">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="visitContent d-flex">
                                    <img src="/assets/images/cityscape/location.png" className="img-fluid location" />
                                    <div>
                                        <h6>VISIT US AT BOOTH NO. H1.R40</h6>
                                        <h6>CITYSCAPE, RIYADH</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="visitDate d-flex">
                                    <img src="/assets/images/cityscape/calender.png" className="img-fluid calender" />
                                    <div>
                                        <h6>ON SEPT</h6>
                                        <h6>10-13TH, 2023</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FifthCitizenShip;