import React from "react";

const FifthCitizenShip = () => {
    return (
        <>
            <div className="citizenShipSection">
                <div className="container-fluid">
                    <div className="row justify-content-evenly">
                        <h6 className="seveTwo">7/24 Multilingual Agency Support <img src="/assets/images/cityscape/dot.svg" width={'50px'} />Cubedots Agent Portal <img src="/assets/images/cityscape/dot.svg" width={'50px'} />After Sales Service Exclusive Projects From Top Developers <img src="/assets/images/cityscape/dot.svg" width={'50px'} />Remote Sales Ability / 3D Platform Citizenship Advisory & Support
                        </h6>
                    </div>
                    <div className="visitDetail">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="visitContent d-flex">
                                    <img src="/assets/images/cityscape/location.png" className="img-fluid location" />
                                    <div>
                                        <h6>VISIT US AT BOOTH NO. H1.R40</h6>
                                        <h6>CITYSCAPE, RIYADH</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="visitDate d-flex">
                                    <img src="/assets/images/cityscape/calender.png" className="img-fluid calender" />
                                    <div>
                                        <h6>ON SEPT</h6>
                                        <h6>10-13TH, 2023</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FifthCitizenShip;