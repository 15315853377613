import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import WatsupComponent from "../Components/WatsupComponent"
import moment from "moment";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import ShareIconsComponent from "../Components/blog/ShareIconsComponent"
import { Spin } from "antd";
import { useApp } from "../hooks/AppHooks";

function BlogDetailPage() {
    const {headerValue} = useApp();
    const location = useLocation();
    const blogDetails = location.state

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            {blogDetails && headerValue ?
                <>
                    <BecomeOurPartnerButton />
                    <div className="newPortalNewsDetailPage">
                        <WatsupComponent />
                        <div className="newsDetailImagebox commanBannerImageMobile">
                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${blogDetails["image"]}`} className="img-fluid" alt={"blogBanner"} />
                            <div className="bannerImageHeading container">
                                <div className="newsDetailContentImage">
                                    <h3 className="marginBottom">{blogDetails.heading}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="newsDetailContentSection">
                            <div className="container">
                                <div className="row justify-content-between">
                                    <div className="col-md-12">
                                        <div className="newsDetailContent">
                                            <h4>{moment(blogDetails.createdAt).utc().format('DD MMMM YYYY')}</h4>
                                            <h3>{blogDetails.heading}</h3>
                                            <div className="cusocialDetailPara" style={{ lineHeight: "28px", color: '#262626', opacity: 0.8 }} dangerouslySetInnerHTML={{ __html: `${blogDetails.content? blogDetails.content : ""}` }} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                    </div>
                                </div>
                                <ShareIconsComponent />
                            </div>
                        </div>
                    </div>
                </> : <Spin className="page-spin-loading"/>}
        </>
    )
}
export default BlogDetailPage;