import React, { useState } from "react";

import { useApp } from "../hooks/AppHooks";
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";


function BecomeOurPartnerButton(props) {
    const { headerValue } = useApp();

    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(props?.isOpen ? true : false);

    const showBecomeOurPartnerModal = () => {
        document.body.style.overflow = '';
        setIsBecomeOurPartnerModalShow(true);
    }

    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto';
        setIsBecomeOurPartnerModalShow(false);
    }

    return (
        <>
            {headerValue && Object.keys(headerValue)?.length ?
                <>
                    <div className="becomeOurPartnerBtnSection">
                        <button onClick={showBecomeOurPartnerModal}><span>{headerValue?.becomeOurPartner}</span></button>
                    </div>
                    <EnrollmentModalComponent
                        isOpen={isBecomeOurPartnerModalShow}
                        onRequestClose={closeBecomeOurPartnerModalModal}
                        event={props?.event ? props.event : "partnerleadForm"}
                        insiderEventName={"become-our-partner"} />
                </>
                : null
            }
        </>
    )
}
export default BecomeOurPartnerButton; 