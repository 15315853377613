import React, { useEffect } from "react";

import Loader from "../Components/Loader";
import { useApp } from "../hooks/AppHooks";
import Employee from "../Components/terms/Employee";
import Information from "../Components/terms/Information"
import CookiePolicy from "../Components/terms/CookiePolicy"
import Clarification from "../Components/terms/Clarification"
import DataSubjectForm from "../Components/terms/DataSubjectForm"
import PolicyOnProtection from "../Components/terms/PolicyOnProtection"
import DeclarationExplict from "../Components/terms/DeclarationExplict"
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import { CMS_PORTAL_BUCKET } from "./Constants";
import { Spin } from "antd";

import { useParams, useLocation } from "react-router-dom"
import KVKK from "../Components/terms/KVKKFORM";
import GDPR from "../Components/terms/GDPR";


function TermsOfUsePage() {
    const { termsValue } = useApp();
    const { id } = useParams();
    const location = useLocation()


    const scroll = async () => {
        if (id) {
            let atag = await document.createElement("a");
            atag.href = `#${id}`;
            atag.click();
        }
        $(document).ready(function () {
            $(`#${id}`).click(function () {
                window.scrollTo({ top: 650, left: 0, behavior: 'smooth' });
            });
            $(`#${id}`).trigger('click');
        });
    }

    useEffect(() => {
        scroll();
    }, [location, id, termsValue]);

    return termsValue && Object.keys(termsValue)?.length ? (
        <>
            <BecomeOurPartnerButton />
            {termsValue && Object.keys(termsValue).length ?
                <div className="mt-70 termsCondition">
                    {termsValue.Banner && termsValue.Banner?.length ?
                        <div className="banner_box">
                            <img className="bannerImage img-fluid" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${termsValue.Banner[0] && termsValue.Banner[0]?.imageLink}`} />
                            <div className="bannerImageHeading container">
                                <div className="aboutContentImage">
                                    <h3 className="marginBottom">{termsValue.Banner[0]?.heading}</h3>
                                </div>
                            </div>
                        </div> : null}
                    <div className='multipleTours policiesSection' id="termsCondition">
                        <div className='container '>
                            <div className="d-md-block d-none">
                                <div className="termsandconditions">
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {termsValue["KVKK"] ?
                                            <button className="nav-link linksButton active" id="v-pills-kvkk-tab" data-bs-toggle="pill" data-bs-target="#v-pills-kvkk" type="button" role="tab" aria-controls="v-pills-kvkk" aria-selected="true">
                                                <h5>{termsValue["KVKK"][0]?.heading}</h5> </button> : null
                                        }
                                        {termsValue["GDPR"] ?
                                            <button className="nav-link linksButton" id="v-pills-gdpr-tab" data-bs-toggle="pill" data-bs-target="#v-pills-gdpr" type="button" role="tab" aria-controls="v-pills-gdpr" aria-selected="false">
                                                <h5>{termsValue["GDPR"][0]?.heading}</h5> </button> : null
                                        }
                                        {termsValue["Clarification Text Relating Personal Data Processing"] ?
                                            <button className="nav-link linksButton" id="v-pills-Clarification-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Clarification" type="button" role="tab" aria-controls="v-pills-Clarification" aria-selected="false">
                                                <h5>{termsValue["Clarification Text Relating Personal Data Processing"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Policy on Protection and Process of Personal Data"] ?
                                                <button className="nav-link linksButton" id="v-pills-Policy-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Policy" type="button" role="tab" aria-controls="v-pills-Policy" aria-selected="false">
                                                    <h5>{termsValue["Policy on Protection and Process of Personal Data"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Cookie Policy"] ?
                                                <button className="nav-link linksButton" id="v-pills-Cookie-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Cookie" type="button" role="tab" aria-controls="v-pills-Cookie" aria-selected="false">
                                                    <h5>{termsValue["Cookie Policy"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Data Subject Application Form"] ?
                                                <button className="nav-link linksButton" id="v-pills-Data-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Data" type="button" role="tab" aria-controls="v-pills-Data" aria-selected="false">
                                                    <h5>{termsValue["Data Subject Application Form"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Processing of Personal Data via Website/Mobile"] ?
                                                <button className="nav-link linksButton" id="v-pills-Declaration-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Declaration" type="button" role="tab" aria-controls="v-pills-Declaration" aria-selected="false">
                                                    <h5>{termsValue["Processing of Personal Data via Website/Mobile"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Information Security Policy Of Cubedots"] ?
                                                <button className="nav-link linksButton" id="v-pills-information-tab" data-bs-toggle="pill" data-bs-target="#v-pills-information" type="button" role="tab" aria-controls="v-pills-information" aria-selected="false">
                                                    <h5>{termsValue["Information Security Policy Of Cubedots"][0]?.heading}</h5> </button> : null
                                        }
                                        {
                                            termsValue["Employee Candidate Clarification Text"] ?
                                                <button className="nav-link linksButton" id="v-pills-emplyoee-tab" data-bs-toggle="pill" data-bs-target="#v-pills-emplyoee" type="button" role="tab" aria-controls="v-pills-emplyoee" aria-selected="false">
                                                    <h5>{termsValue["Employee Candidate Clarification Text"][0]?.heading}</h5> </button> : null
                                        }
                                    </div>
                                    <div className="termsPolicyData">
                                        <div className="tab-content " id="v-pills-tabContent">
                                            <div className="tab-pane fade show active clarificationSection" id="v-pills-kvkk" role="tabpanel" aria-labelledby="v-pills-kvkk-tab">
                                                <KVKK />
                                            </div>
                                            <div className="tab-pane fade show  clarificationSection" id="v-pills-gdpr" role="tabpanel" aria-labelledby="v-pills-gdpr-tab">
                                                <GDPR />
                                            </div>
                                            <div className="tab-pane fade show  clarificationSection" id="v-pills-Clarification" role="tabpanel" aria-labelledby="v-pills-Clarification-tab">
                                                <Clarification />
                                            </div>
                                            <div className="tab-pane fade protectionSection" id="v-pills-Policy" role="tabpanel" aria-labelledby="v-pills-Policy-tab">
                                                <PolicyOnProtection />
                                            </div>
                                            <div className="tab-pane fade cookiePolicySection" id="v-pills-Cookie" role="tabpanel" aria-labelledby="v-pills-Cookie-tab">
                                                <div>
                                                    <CookiePolicy />
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Data" role="tabpanel" aria-labelledby="v-pills-Data-tab">
                                                <div>
                                                    <DataSubjectForm />
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Declaration" role="tabpanel" aria-labelledby="v-pills-Declaration-tab">
                                                <div>
                                                    <DeclarationExplict />
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-information" role="tabpanel" aria-labelledby="v-pills-information-tab">
                                                <div>
                                                    <Information />
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-emplyoee" role="tabpanel" aria-labelledby="v-pills-emplyoee-tab">
                                                <div>
                                                    <Employee />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion d-md-none d-block" id="accordionExample">
                                <div className="accordion-item">
                                    {
                                        termsValue["KVKK"] ?
                                            <h2 className="accordion-header" id="headingZero">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                                    <h5>{termsValue["KVKK"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseZero" className="accordion-collapse collapse show" aria-labelledby="headingZero" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <KVKK />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["GDPR"] ?
                                            <h2 className="accordion-header" id="headingZeroOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZeroOne" aria-expanded="false" aria-controls="collapseZeroOne">
                                                    <h5>{termsValue["GDPR"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseZeroOne" className="accordion-collapse collapse show" aria-labelledby="headingZeroOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <GDPR />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Clarification Text Relating Personal Data Processing"] ?
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    <h5>{termsValue["Clarification Text Relating Personal Data Processing"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <Clarification />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Policy on Protection and Process of Personal Data"] ?
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <h5>{termsValue["Policy on Protection and Process of Personal Data"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <PolicyOnProtection />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Cookie Policy"] ?
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <h5>{termsValue["Cookie Policy"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <CookiePolicy />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Data Subject Application Form"] ?
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <h5>{termsValue["Data Subject Application Form"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <DataSubjectForm />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Processing of Personal Data via Website/Mobile"] ?
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    <h5>{termsValue["Processing of Personal Data via Website/Mobile"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <DeclarationExplict />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Information Security Policy Of Cubedots"] ?
                                            <h2 className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    <h5>{termsValue["Information Security Policy Of Cubedots"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <Information />
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    {
                                        termsValue["Employee Candidate Clarification Text"] ?
                                            <h2 className="accordion-header" id="headingSeven">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    <h5>{termsValue["Employee Candidate Clarification Text"][0]?.heading}</h5>
                                                </button>
                                            </h2> : null
                                    }
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <Employee />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div > : null}
        </>
    ) : <Spin className="page-spin-loading" />;
}

export default TermsOfUsePage;