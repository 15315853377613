import React from "react";
import Header from "../cityscape/components/Header";
import Banner from "../cityscape/components/Banner";
import SecondUltra from "../cityscape/components/SecondUltra";
import ThirdModern from "../cityscape/components/ThirdModer";
import FourthSkyalnd from "../cityscape/components/FourthSkyalnd";
import FifthCitizenShip from "../cityscape/components/FifthCitizenShip";
import FormSection from "../cityscape/components/FormSection";
import FooterSection from "../cityscape/components/FooterSection";
import "./city.scss";

const CityScape = () => {
    return (
        <>
            <div className="cityScapeLandingPage">
                <Header />
                <Banner />
                <SecondUltra />
                <FormSection />
                <ThirdModern />
                <FourthSkyalnd />
                <FifthCitizenShip />
                <FooterSection />
            </div>


        </>
    )
}
export default CityScape;