import React from "react";

const FooterSection = () => {
    return (
        <>
            <div className="footerSection">
                <span>
                    <div className="d-flex  justify-content-center align-items-baseline">
                        <img src="/assets/images/cityscape/address.png" className="img-fluid" />
                        <div>
                            <h6>Veko Giz Plaza, Meydan Sokak, No:34396, Maslak,</h6>
                            <h6>Kat:5, 34396 Sariyer/isanbul</h6>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <img src="/assets/images/cityscape/phone.png" className="img-fluid" />
                        <h6>+90 850 532 35 32</h6>
                    </div>
                    <div className="d-flex justify-content-center flex-wrap">
                        <div className="d-flex align-items-center">
                            <img src="/assets/images/cityscape/email.png" className="img-fluid" />
                            <h6>info@cubedots.com</h6>
                        </div>
                        <div className="d-flex align-items-center">
                            <img src="/assets/images/cityscape/website.png" className="img-fluid" />
                            <h6> <a href="https:/cubedots.com" target="_blank">cubedots.com</a></h6>
                        </div>
                    </div>
                </span>
                <div className="footerLine">

                </div>
            </div>
        </>
    )
}
export default FooterSection;