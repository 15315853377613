import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CharactorLimitContent from "../CharactorLimitContent";
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function ProjectsComponent() {
    const location = useLocation();
    const langId = location.search.split("=")[1];
    const { projectValue, unsoldProjectValue } = useApp()

    const [projectData, setProjectData] = useState([]);
    const [projectTitle, setProjectsTitle] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");

    useEffect(() => {
        if (projectValue && unsoldProjectValue) {
            setProjectData(unsoldProjectValue);
            setProjectsTitle(projectValue?.Banner ? projectValue.Banner[0]?.title : "");
            setDeliveryDate(projectValue?.Projects ? projectValue.Projects[0]?.deliveryDate : "");
        }
    }, [projectValue, unsoldProjectValue])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        Largedesktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1366, min: 1250 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 500 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1
        }
    };

    return (
        <>
            {projectData && projectData.length && projectTitle ?
                <div className="projectSection">
                    <div className="container">
                        <div className="position-relative">
                            <div className="row justify-content-center">
                                <h3 className="SubHeadingBottomMargin">
                                    <span className="underLineClass">
                                        {projectTitle}
                                    </span>
                                </h3>
                                <div className="col-md-11">
                                    <div className="projectSectionList">
                                        <Carousel responsive={responsive} infinite={true}>
                                            {
                                                projectData.map((row, index) => {
                                                    return row.saleStatus ? (
                                                        <div className="projectContentSection" key={index}>
                                                            {row.saleStatus && <Link to={langId ? `/projects/${row.slug}?lang=${langId}` : `/projects/${row.slug}`} state={{ id: row.id, bannerImage: row.projectMedia ? row.projectMedia[0]?.fileS3Path : null }}>
                                                                <div className="projectImageZoom">
                                                                    <img className="img-fluid" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.featuredImage ? row.featuredImage : null}`} />
                                                                </div>
                                                            </Link>
                                                            }
                                                            <div className="projectContent">
                                                                {row.saleStatus ? <Link to={langId ? `/projects/${row.slug}?lang=${langId}` : `/projects/${row.slug}`} state={{ id: row.id }}>
                                                                    <h4>{row.title}</h4>
                                                                </Link>:null
                                                                }
                                                                <small>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f8400c" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                                    </svg>
                                                                    {row.city}, {row.country}
                                                                </small>
                                                                <p><CharactorLimitContent isShowbutton={false} content={row.smallDescription} limit={48} /></p>
                                                                <div className="projectPositionProgressBar d-flex align-items-center">
                                                                    <small className='d-flex statusBox'>{!Number(row.deliveryDate?.split(" ")[1]) ? row.deliveryDate : deliveryDate + " : " + row.deliveryDate}</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    )
}
export default ProjectsComponent;