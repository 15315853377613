import React, { useEffect, useState } from "react";

import $ from 'jquery';

import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function CopyPortalFeatureInfoBeans() {
    const { devloperValue } = useApp();

    const [benefitList, setBenefitList] = useState([]);

    useEffect(() => {
        if (devloperValue && Object.keys(devloperValue).length && devloperValue["Why Trust Us?"]) {
            const scrollingData = devloperValue["Why Trust Us?"]?.map((data, i) => ({ ...data, id: i, subTitle: data.content }))
            setBenefitList(scrollingData)
        }
    }, [devloperValue]);

    $(document).ready(function () {
        let lastScrollTop = 0;
        $(window).scroll(function () {
            let windowWidth = $(window).width();
            if (windowWidth > 769) {
                let windowTop = $(window).scrollTop();
                let windowBottom = $(window).scrollTop() + $(window).height();
                let scrollSterTop = $("#text-image-scroll").position()?.top - 60;
                let scrollSterBottom = $("#text-image-scroll").position().top +
                    $("#text-image-scroll").outerHeight(true);
                let textWrapperHeight = $("#text-wrapper").height();

                if (windowTop > scrollSterTop) {
                    $("#images-wrapper").addClass("images-wrapper-fixed");
                    $(".bean-bullets").addClass("fixed");
                } else {
                    $("#images-wrapper").removeClass("images-wrapper-fixed");
                    $(".bean-bullets").removeClass("fixed");
                }

                if (windowBottom > scrollSterBottom) {
                    $("#images-wrapper").removeClass("images-wrapper-fixed");
                    $(".bean-bullets").removeClass("fixed");
                    $("#images-wrapper").addClass("images-wrapper-fixed-stop");
                } else {
                    $("#images-wrapper").removeClass("images-wrapper-fixed-stop");
                }

                if ($("#images-wrapper").hasClass("images-wrapper-fixed") &&
                    windowBottom < scrollSterBottom - 100) {
                    let imageWrapperWidth = $("#images-wrapper").width() * benefitList?.length;
                    let scrolled = windowTop - scrollSterTop;
                    let percScrolledOfScrollSter = scrolled / textWrapperHeight;
                    let pxOfImageWrapper = imageWrapperWidth * percScrolledOfScrollSter;
                    let minus = Math.abs(pxOfImageWrapper) * -1;
                    $(".images").css("right", minus);
                }
                lastScrollTop = windowTop;
            }
        });
    });

    return (
        <>
            {devloperValue && Object.keys(devloperValue)?.length ?
                <>
                    <div className="portalFeatureSection d-xl-block d-none">
                        <div className="container" id="why-trust-us">
                            <h3 className="SubHeadingBottomMargin">
                                <span className="underLineClass">
                                    {devloperValue["Why Trust Us?"] && devloperValue["Why Trust Us?"]?.length ? devloperValue["Why Trust Us?"][0]?.heading : null}
                                </span>
                            </h3>
                            <div id="text-image-scroll" className="text-image-scroll-viewer">
                                <div id="text-wrapper" className="text-wrapper">
                                    {
                                        benefitList && benefitList?.length ? benefitList?.map((row, index) => {
                                            return (
                                                <div key={index} id={row.title} className="slide-text-container">
                                                    <div className="hero-content">
                                                        <h5 className="main-heading">{row.title}</h5>
                                                        <p>{row.subTitle}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : null
                                    }
                                </div>
                                <div id="images-wrapper" className="images-wrapper">
                                    {
                                        benefitList && benefitList?.length ? benefitList.map((row, index) => {
                                            return (
                                                <div id={`center-image${index + 1}`} className="images">
                                                    <img className="ios_devices" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row?.imageLink}`} alt={row.title} />
                                                </div>
                                            )
                                        }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portalFeatureSection d-xl-none d-block">
                        <div className="container">
                            <h3 className="SubHeadingBottomMargin">
                                <span className="underLineClass">
                                    {devloperValue["Why Trust Us?"] && devloperValue["Why Trust Us?"]?.length && devloperValue["Why Trust Us?"][0]?.heading}
                                </span>
                            </h3>
                            <div className="row justify-content-evenly align-items-center">
                                <div className="col-md-10">
                                    {
                                        benefitList && benefitList.length && benefitList.map((row, index) => {
                                            return index % 2 == 0 ?
                                                <>
                                                    <div className="row devTrustContentImage justify-content-between align-items-center">
                                                        <div className='col-md-5'>
                                                            <div className="portalFeatureFreeContent">
                                                                <h5>{row.title}</h5>
                                                                <p>{row.subTitle}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <div className="portalFetatureImage">
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row?.imageLink}`} className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="row devTrustContentImage justify-content-between align-items-center">
                                                        <div className='col-md-5 order-md-1 order-2'>
                                                            <div className="portalFetatureImage">
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row?.imageLink}`} className="img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 order-md-2 order-1'>
                                                            <div className="portalFeatureFreeContent">
                                                                <h5>{row.title}</h5>
                                                                <p>{row.subTitle}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
            }
        </>
    )
}
export default CopyPortalFeatureInfoBeans