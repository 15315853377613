import React, { useEffect, useState } from "react";

import "react-multi-carousel/lib/styles.css";
import { useLocation, useParams } from "react-router-dom";

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import PortalFeature from "../Components/agentpage/PortalFeature";
import EventComponent from "../Components/agentpage/EventComponent"
import ProjectsComponent from "../Components/agentpage/ProjectsComponent"
import ForAgentcomponent from "../Components/agentpage/ForAgentComponent"
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import WhyDevelopersTrustCaourselComponent from "../Components/home/WhyDevelopersTrustCaourselComponent"
import { Spin } from "antd";

function AgentsPage() {
    const { id } = useParams();
    const location = useLocation();
    const { agentsValue , headerValue} = useApp();

    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(false);

    useEffect(() => {
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                window.scrollTo({ top: elem.offsetTop - 100, left: 0, behavior: "smooth" });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location])

    const getAgentsData = () => {
        if (id) {
            setTimeout(() => {
                let anchor = document.createElement('a');
                anchor.href = `#${id}`;
                anchor.click();
            }, 1000)
        }
    };

    useEffect(() => {
        if (agentsValue && Object.keys(agentsValue).length) {
            getAgentsData();
        }
    }, [location, id, agentsValue]);

    const showBecomeOurPartnerModal = () => {
        document.body.style.overflow = '';
        setIsBecomeOurPartnerModalShow(true);
    };
    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto';
        setIsBecomeOurPartnerModalShow(false);
    };

    return (
        <>
            {agentsValue && Object.keys(agentsValue).length && headerValue ?
                <>
                    <BecomeOurPartnerButton />
                    <div className="newPortalAgentSection">
                        {agentsValue["Banner"] && agentsValue["Banner"].length ?
                            <div className="agentImage commanBannerImageMobile" id="banner">
                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Banner"][0]?.backgroundImage}`} className="bannerImage img-fluid" />
                                <div className="bannerImageHeading container">
                                    <div className="agentContentImage">
                                        <div className="agentContent">
                                            <h3 className="marginBottom">{agentsValue["Banner"][0].heading}</h3>
                                            <button onClick={showBecomeOurPartnerModal}>{agentsValue["Banner"][0].buttonName}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        <ForAgentcomponent />
                        <ProjectsComponent />
                        <PortalFeature />
                        <EventComponent />
                        {agentsValue["Why Agents Trust Us?"] && agentsValue["Why Agents Trust Us?"].length ? <WhyDevelopersTrustCaourselComponent data={agentsValue["Why Agents Trust Us?"]} /> : null}
                        {agentsValue["Our Agents Network"] && agentsValue["Our Agents Network"].length ?
                            <div className="ourOffices" id="our-agents-network">
                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Our Agents Network"][0]?.backgroundImage}`} className="img-fluid" />
                                <div className="bannerImageHeading container">
                                    <div className="aboutContentImage">
                                        <h3 className="marginBottom">
                                            <span className="underLineOurOffices">
                                                {agentsValue["Our Agents Network"][0]?.heading}
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <EnrollmentModalComponent
                        isOpen={isBecomeOurPartnerModalShow}
                        onRequestClose={closeBecomeOurPartnerModalModal}
                        insiderEventName={"join-our-network"}
                        event="agentsForm"
                    />
                </>
                : <Spin className="page-spin-loading"/>}
        </>
    )
}
export default AgentsPage;