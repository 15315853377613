import React from "react";

const ThirdModern = () => {
    return (
        <>
            <div className="thirdSection">
                <div className="bestErupe">
                    <img src="/assets/images/cityscape/3.jpg" className="img-fluid" />
                    <div className="bestEurpeContent">
                        <div>
                            <img src="/assets/images/cityscape/tema_logo.png" width={150} />
                        </div>
                        <div className="bestText">
                            <h6>Join Hands With Cubedots, And It Will Be</h6>
                            <h6>5 Tumes Easier To Sell Luxurious Turkish Properties</h6>
                            <h6>To Your Clients In Saudi Arabia</h6>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="row p-0 m-0">
                        <div className="col-md-6 p-0">
                            <div className="thirdSectionImage">
                                <img src="/assets/images/cityscape/4.jpg" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="thirdSectionImage">
                                <img src="/assets/images/cityscape/5.jpg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="moderInfo">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-2">
                                <img src="/assets/images/cityscape/konaklari_logo.png" className="img-fluid" width={200} />
                            </div>
                            <div className="col-md-8">
                                <div className="moderInfoContent">
                                    <h6>Fine Modern Luxurious Residences</h6>
                                    <h6>The future landmark of luxury</h6>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <img src="/assets/images/cityscape/Valory_logo.png" className="img-fluid" width={200} style={{ height: '114px', objectFit: 'contain' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ThirdModern;