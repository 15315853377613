import React, { useEffect } from 'react';

import { useApp } from '../../hooks/AppHooks';

function CookiePolicy() {
    const { termsValue } = useApp();
    const [cookiesData, setCookiesData] = React.useState();

    useEffect(() => {
        if (termsValue["Cookie Policy"]
            && termsValue["Cookie Policy"]?.length) {
            setCookiesData(termsValue["Cookie Policy"])
        }
    }, [termsValue]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            {cookiesData ?
                <div className="cookieSection">
                    <div className="privacyPolicyTerms ">
                        <div className="">
                            <div className="cookiesContent ">
                                <h2 className="vegonHeading">{cookiesData[0]?.paragraph}</h2>
                                {
                                    cookiesData.map((data, i) => {
                                        return (
                                            <div key={i} className="cookieItem">
                                                <h4 className='subHeading'>{data.title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: `${data.content ? data.content : ""}` }} />
                                            </div>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div> : null}
        </>
    )

}
export default CookiePolicy;