import { createContext, useState, useEffect, useRef } from 'react';

import axios from "axios";
import { useLocation } from "react-router-dom";

import { CMS_API_ENDPOINT, WEBSITE_X_API_KEY, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT } from "../common/Constants";


const useContextAppValue = () => {
    const location = useLocation();
    const pathName = location.pathname;
    const pageName = pathName.split("/")[1];
    const languageId = location.search?.split('=')[1];

    const [homeValue, setHomeValue] = useState();
    const [aboutUsValue, setAboutUsValue] = useState();
    const [agentsValue, setAgentsValue] = useState();
    const [getInTouchValue, setGetInTouchValue] = useState();
    const [devloperValue, setDeveloperValue] = useState();
    const [projectValue, setProjectValue] = useState();
    const [unsoldProjectValue, setUnsoldProjectValue] = useState();
    const [soldProjectValue, setSoldProjectValue] = useState();
    const [languageValue, setLanguageValue] = useState();
    const [headerValue, setHeaderValue] = useState();
    const [footerValue, setFooterValue] = useState();
    const [specificProjectValue, setSpecificProjectValue] = useState();
    const [projectBannerValue, setProjectBannerValue] = useState();
    const [getEnrollmentValue, setGetEnrollmentValue] = useState();
    const [enrollmentValue, setEnrollmentValue] = useState();
    const [enquireValue, setEnquireValue] = useState();
    const [enquireAboutValue, setEnquireAboutValue] = useState();
    const [enquireAboutProjectValue, setEnquireAboutProjectValue] = useState();
    const [disclaimerValue, setDisclaimerValue] = useState();
    const [privacyPolicyValue, setprivacyPolicyValue] = useState();
    const [blogBanner, setBlogBanner] = useState();
    const [blogDetails, setBlogDetails] = useState();
    const [termsValue, setTermsValue] = useState();
    const [sitMapValue, setSitMapValue] = useState();
    const [cookiePolicyValue, setcookiePolicyValue] = useState();
    const [ourOfficesData, setOurOficesData] = useState();
    const [contactData, setContactData] = useState();
    const [sendUsMessageData, setSendUsMessageData] = useState();
    const [language, setLanguage] = useState('');
    const [projectId, setProjectId] = useState();
    const [countries, setCountries] = useState([]);
    const publicPath = process.env.PUBLIC_URL;

    useEffect(() => {
        if (!languageId) {
            getGeoLocation();
        }
    }, []);

    useEffect(() => {
        if (languageId || language) {
            fetchCommonData();
        }
    }, [languageId, language]);

    useEffect(() => {
        if (projectId) {
            fetchProjectData();
        }
    }, [projectId]);

    useEffect(() => {
        if (languageId || language) {
            fetchContextData();
        }
    }, [pathName, languageId, language]);

    // TODO: HANU - Need to fix turkish language by default for turkey location
    const getGeoLocation = async () => {
        const response = await axios.get(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + 'get-location');
        if (response.data?.headers["CloudFront-Viewer-Country"]) {
            const country = response.data?.headers["CloudFront-Viewer-Country"];
            if (country === "TR" || country === "Turkey" || country === "turkey") {
                setLanguage("Turkish");
                return;
            }
        }
        setLanguage("English");
    }

    async function fetchProjectData() {
        const langId = languageId ? languageId : language === "Turkish" ? 2 : 1;
        const projectData = await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/project-info?id=${location.state?.id ? location.state?.id : projectId}&languageId=${langId}`, {
                headers: {
                    'x-api-key': WEBSITE_X_API_KEY,
                }
            });
        setSpecificProjectValue(projectData.data?.data);
        return;
    }

    async function fetchCommonData() {
        const langId = languageId ? languageId : language === "Turkish" ? 2 : 1;
        const languageData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/language-list?published=${true}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const headerData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/header-content-list?sectionName=Header&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const footerData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/footer-website-content?sectionName=Footer&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const unsoldProjectData = async () => await axios
            .get(`${CMS_API_ENDPOINT}project/project-list?saleStatus=unsold&languageId=${langId}&category=banner`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const enrollmentData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Enrollment Request&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })

        const homeData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=home&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const projectData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/project-website-content?page=Projects&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const disclaimerData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/footer-website-content?sectionName=Disclaimer&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const siteMapData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/side-map-website-content?page=Site Map&languageId=${langId}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });

        const cookieData = async () => await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/policy-website-content?page=${encodeURIComponent("Cookie Policy")}&languageId=${langId}`, {
                headers: {
                    'x-api-key': WEBSITE_X_API_KEY,
                }
            })

        const countryData = async () => await axios.get(publicPath + "/assets/data/countries.json");

        await Promise.all([languageData(), headerData(), footerData(), unsoldProjectData(), enrollmentData(), homeData(), projectData(), disclaimerData(), siteMapData(), cookieData(), countryData()])
            .then((res) => {
                if (pageName === "projects" && typeof pathName.split("/")[2] === "string") {
                    setProjectId(res[3].data?.data.find((e) => e.slug === decodeURIComponent(pathName.split("/")[2])).id);
                }
                setLanguageValue(res[0].data?.data?.sort((a, b) => (b.name < a.name) ? 1 : -1));
                setHeaderValue(res[1].data?.data && res[1].data?.data[0]);
                setFooterValue(res[2].data?.data);
                setUnsoldProjectValue(res[3].data?.data);
                setEnrollmentValue(res[4].data?.data);
                setHomeValue(res[5].data?.data);
                setProjectValue(res[6]?.data?.data);
                setDisclaimerValue(res[7].data?.data);
                setSitMapValue(res[8]?.data?.data);
                setcookiePolicyValue(res[9]?.data?.data);
                setCountries(res[10]?.data.data);
            })
    }

    async function fetchContextData() {
        const langId = languageId ? languageId : language === "Turkish" ? 2 : 1;
        if (pageName === "aboutus") {
            const abotUsData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=about us&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            const getInTouchData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Get In Touch&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            setAboutUsValue(abotUsData.data?.data);
            setGetInTouchValue(getInTouchData.data?.data);
        }
        if (pageName === "agents") {
            const agentsData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=agents&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            setAgentsValue(agentsData.data?.data);
        }
        if (pageName === "developer") {
            const developerData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=Developers&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            setDeveloperValue(developerData.data?.data);
        }
        if (pageName === "projects" || pageName === "enquire-for-project") {

            const specificProjectData = async () => await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/project-info?id=${location.state?.id ? location.state?.id : projectId}&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                });

            const soldProjectData = async () => await axios
                .get(`${CMS_API_ENDPOINT}project/project-list?saleStatus=sold&languageId=${langId}&category=banner`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                });

            const projectBannerData = async () => await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/project-website-content?page=Project Details&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                });

            const enquireData = async () => await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Enquire For Project&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            const enquireAboutData = async () => await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Enquire About This Project&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            await Promise.all([specificProjectData(), soldProjectData(), projectBannerData(), enquireData(), enquireAboutData()])
                .then((res) => {
                    setSpecificProjectValue(res[0].data?.data);
                    setSoldProjectValue(res[1].data?.data);
                    setProjectBannerValue(res[2].data?.data);
                    setEnquireValue(res[3].data?.data)
                    setEnquireAboutValue(res[4].data?.data)
                })
        }
        if (pageName === "contact" || pageName === "enquire-about-project" || pageName === "create-appointment" || pageName === "projects") {
            const getEnrollmentData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Create Appointment&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            const enquireAboutProjectData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Enquire About Project&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            setGetEnrollmentValue(getEnrollmentData.data?.data);
            setEnquireAboutProjectValue(enquireAboutProjectData.data?.data);

            const ourOffice = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/our-office-page-content-list?section=Our Offices&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })
            setOurOficesData(ourOffice.data?.data);

            const contactUs = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=Contact Us&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })
            setContactData(contactUs.data?.data);

            const sentUsMesaage = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/form-content-list?sectionName=Send Us a Message&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })
            setSendUsMessageData(sentUsMesaage.data ? sentUsMesaage.data?.data[0] : null);
        }
        if (pageName === "privacy_policy") {
            const privacyData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/policy-website-content?page=Privacy Policy&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                });
            setprivacyPolicyValue(privacyData.data?.data)
        }
        if (pageName === "blogs") {
            const blogBannerData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/website-content?page=articles/ blogs&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            const blogDetailData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/blog-content-list?page=articles/ blogs&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })

            setBlogBanner(blogBannerData.data?.data);
            setBlogDetails(blogDetailData.data?.data);
        }
        if (pageName === "terms_and_conditions") {
            const termsData = await axios
                .get(`${CMS_API_ENDPOINT}cms-portal-content-management/policy-website-content?page=${encodeURIComponent("Terms & Conditions")}&languageId=${langId}`, {
                    headers: {
                        'x-api-key': WEBSITE_X_API_KEY,
                    }
                })
            setTermsValue(termsData.data?.data)
        }
    }

    return {
        homeValue,
        headerValue,
        footerValue,
        agentsValue,
        projectValue,
        aboutUsValue,
        languageValue,
        devloperValue,
        getInTouchValue,
        soldProjectValue,
        unsoldProjectValue,
        specificProjectValue,
        projectBannerValue,
        languageId,
        getEnrollmentValue,
        enrollmentValue,
        enquireValue,
        enquireAboutValue,
        enquireAboutProjectValue,
        disclaimerValue,
        privacyPolicyValue,
        blogBanner,
        blogDetails,
        termsValue,
        sitMapValue,
        cookiePolicyValue,
        ourOfficesData,
        contactData,
        sendUsMessageData,
        language,
        countries
    }
}
export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const value = useContextAppValue();
    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}