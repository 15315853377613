import React, { useState, useEffect } from 'react';

import { useApp } from '../../hooks/AppHooks';

function Employee() {
    const { termsValue } = useApp();
    const [employeeData, setEmployeeData] = useState();

    useEffect(() => {
        if (termsValue["Employee Candidate Clarification Text"]
            && termsValue["Employee Candidate Clarification Text"]?.length) {
            setEmployeeData(termsValue["Employee Candidate Clarification Text"]);
        }
    }, [termsValue]);

    return (
        <>
            {employeeData ?
                <>
                    <h3 className="vegonHeading">{employeeData[0]?.paragraph}</h3>
                    <p dangerouslySetInnerHTML={{ __html: `${employeeData[0]?.content ? employeeData[0].content : null}` }} />
                </> : null}
        </>
    )
}
export default Employee;