import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import FormSection from "../Components/aboutus/FormSection";
import OurPartners from "../Components/aboutus/OurPartners";
import AboutUsComponents from "../Components/aboutus/AboutUsComponent";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import ManagementComponent from "../Components/aboutus/ManagementComponent";
import { Spin } from "antd";

function AboutUs() {
  const { id } = useParams();
  const location = useLocation();
  const { section } = useParams();
  const { aboutUsValue , headerValue} = useApp();

  useEffect(() => {
    if (aboutUsValue && Object.keys(aboutUsValue)?.length) {
      getAboutUsData();
    }
  }, [location, section, aboutUsValue]);

  const getAboutUsData = async () => {
    if (id) {
      let anchor = await document.createElement('a');
      anchor.href = `#${id}`;
      anchor.click();
    }
  }

  return (
    <>
      {aboutUsValue && Object.keys(aboutUsValue)?.length && headerValue ?
        <>
          <BecomeOurPartnerButton />
          <div className="newPortalAboutUsPage">
            <div className="aboutSection">
              {aboutUsValue["Banner"] && aboutUsValue["Banner"].length ?
                <div className="aboutImagebox commanBannerImageMobile" id="about-us">
                  <img
                    className="bannerImage img-fluid"
                    src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["Banner"][0]?.backgroundImage}`} />
                  <div className="bannerImageHeading container">
                    <div className="aboutContentImage">
                      <h3 className="marginBottom">{aboutUsValue["Banner"][0].title}</h3>
                    </div>
                  </div>
                </div>
                : null}
              <AboutUsComponents />
              {aboutUsValue["Partners"] ? <OurPartners ourPartnersData={aboutUsValue["Partners"]} /> : null}
              <ManagementComponent />
              <FormSection />
              {aboutUsValue["Our Agents Network"] && aboutUsValue["Our Agents Network"].length ?
                <div className="ourOffices" id="our-agents-network">
                  <img
                    src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["Our Agents Network"][0].backgroundImage}`}
                    className="img-fluid"
                  />
                  <div className="bannerImageHeading container">
                    <div className="aboutContentImage">
                      <h3 className="marginBottom ">
                        <span className="underLineOurOffices">{aboutUsValue["Our Agents Network"][0]?.heading}</span>
                      </h3>
                    </div>
                  </div>
                </div>
                : null
              }
            </div>
          </div>
        </>
        : <Spin className="page-spin-loading"/>}
    </>
  );
}
export default AboutUs;
