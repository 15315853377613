import React from "react";
import AgentDevelopers from "../Components/home/agentDevelopsPage/AgentDevelopers";

function AgentDevelopersPage() {
    return (
        <>
            <AgentDevelopers />
        </>
    )
}
export default AgentDevelopersPage;