import AgentPage from "./AgentPage";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function AgentDevelopers() {
    return (
        <>
            <AgentPage />
        </>
    )
}
export default AgentDevelopers;