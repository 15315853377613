import React, { useEffect, useState } from 'react';
import { useApp } from '../../hooks/AppHooks';

function Clarification() {
    const { termsValue } = useApp();
    const [clarificationData, setClarificationData] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        if (termsValue["Clarification Text Relating Personal Data Processing"]
            && termsValue["Clarification Text Relating Personal Data Processing"]?.length) {
            setClarificationData(termsValue["Clarification Text Relating Personal Data Processing"])
        }
    }, [termsValue])

    return (
        <>
            {clarificationData ?
                <div className='container'>
                    <div className="row">
                        <div>
                            <h4 className="clarificationHeding vegonHeading">{clarificationData[0]?.paragraph}</h4>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: `${clarificationData[0].content[0]?.content && clarificationData[0].content[0].content}` }} />
                        <div className="table-responsive ClarificationTable">
                            {clarificationData[0]?.content
                                && clarificationData[0]?.content.length ?
                                <table className="table table-bordered" style={{ lineHeight: "28px" }}>
                                    <thead>
                                        <tr className="tableHedingSection">
                                            <th dangerouslySetInnerHTML={{ __html: `${clarificationData[0].content[0]?.data ? clarificationData[0].content[0]?.data : null}` }} />
                                            <th dangerouslySetInnerHTML={{ __html: `${clarificationData[0].content[0]?.category ? clarificationData[0].content[0]?.category : null}` }} />
                                            <th dangerouslySetInnerHTML={{ __html: `${clarificationData[0].content[0]?.process ? clarificationData[0].content[0]?.process : null}` }} />
                                            <th dangerouslySetInnerHTML={{ __html: `${clarificationData[0].content[0]?.legal ? clarificationData[0].content[0]?.legal : null}` }} />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clarificationData?.map((data, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td dangerouslySetInnerHTML={{ __html: `${data.content[0]?.dataSubject ? data.content[0]?.dataSubject : null}` }} />
                                                    <td dangerouslySetInnerHTML={{ __html: `${data.content[0]?.dataCategory ? data.content[0]?.dataCategory : null}` }} />
                                                    <td dangerouslySetInnerHTML={{ __html: `${data.content[0]?.purpose ? data.content[0]?.purpose : null}` }} />
                                                    <td dangerouslySetInnerHTML={{ __html: `${data.content[0]?.legalProcessing ? data.content[0]?.legalProcessing : null}` }} />
                                                </tr>)
                                        })}
                                    </tbody>
                                </table> : null}
                        </div>
                        <div style={{ lineHeight: "28px", opacity: 0.8 }}>
                            <p>{clarificationData[0]?.title}</p>
                            <p dangerouslySetInnerHTML={{ __html: `${clarificationData[0]?.content[0]?.subContent ? clarificationData[0].content[0].subContent : null}` }} />
                        </div>
                    </div>
                </div> : null}
        </>
    )
}
export default Clarification;