export function validatePhoneNumber(phoneNumber, countryCode, phoneNumberUtil) {
  try {
    // Check if the phone number meets the minimum length requirement
    if (phoneNumber?.length < 5) {
      return false;
    }
    // Parse the phone number
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, countryCode);
    // Check if it's a valid number
    const isValid = phoneNumberUtil.isValidNumber(parsedNumber);
    return isValid;
  } catch (error) {
    // Error occurred while parsing the number
    return false;
  }
}

export function validateLandlinePhoneNumber(phoneNumber, countryCode, phoneNumberUtil, PhoneNumberType) {
  try {
    // Check if the phone number meets the minimum length requirement
    if (phoneNumber?.length < 5) {
      return false;
    }
    // Parse the phone number
    const parsedNumber = phoneNumberUtil.parse(phoneNumber, countryCode);
    // Check if it's a valid landline number
    const isValid = phoneNumberUtil.isValidNumberForRegion(parsedNumber, countryCode) &&
      phoneNumberUtil.getNumberType(parsedNumber) === PhoneNumberType.FIXED_LINE;
    return isValid;
  } catch (error) {
    // Error occurred while parsing the number
    return false;
  }
}