import React from "react";
import Marquee from "react-fast-marquee";
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useLocation } from "react-router-dom";


function OurPartners(props) {
    const publicPath = process.env.PUBLIC_URL;
    const location = useLocation();

    return (
        <>
            {props && props?.ourPartnersData && props?.ourPartnersData.length || location.pathname.split('/')[1] === "mipim" ?
                <div className="ourPartners" id="our-partners">
                    <div className="container">
                        <h3 className="SubHeadingBottomMargin">
                            <span className="underLineClass">
                                {location.pathname.split('/')[1] === "mipim" ? "Our Partners" : props?.ourPartnersData[0].heading}
                            </span>
                        </h3>
                        <Marquee gradientColor={false} speed={50} pauseOnHover={true}>
                            {location.pathname.split('/')[1] === "mipim" ?
                                <>
                                    <img src={publicPath + "assets/images/ourpartnerlogo/1.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/2.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/3.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/4.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/5.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/6.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/7.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/8.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/10.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/11.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/12.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/13.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/14.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/15.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/16.svg"} />
                                    <img src={publicPath + "assets/images/ourpartnerlogo/17.svg"} />
                                    {/* <img src={publicPath+"assets/images/ourpartnerlogo/18.svg"} /> */}
                                    <img src={publicPath + "assets/images/ourpartnerlogo/19.svg"} />
                                </>
                                :
                                typeof props?.ourPartnersData[0]?.imageLink === "string" && props?.ourPartnersData.map((partnerLogo) =>
                                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${partnerLogo.imageLink}`} />)
                            }
                        </Marquee>
                    </div>
                </div>
                : null
            }
        </>
    )
}
export default OurPartners;