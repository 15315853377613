import React, { useEffect, useState } from 'react';
import ScrollToTop from "react-scroll-to-top";
import { useLocation, useParams } from "react-router-dom";
import { useApp } from '../../hooks/AppHooks';

function PolicyOnProtection() {
    const { id } = useParams();
    const location = useLocation()

    const { privacyPolicyValue, termsValue } = useApp();

    const [policiesData, setPoliciesData] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState(
        {
            purpose: [],
            scopeAndImplementation: [],
            definitions: [],
            processingofPersonalData: [],
            disclosureObligation: [],
            dataSecurity: [],
            transferofPersonalData: [],
            inventoryofPersonalData: [],
            rolesandResponsibilities: [],
            RightsoftheDatSubjectsandExerciseoftheRights: [],
            publicationandEntryIntoForceofthePolicy: [],
            updatingthePolicy: []
        }
    )

    useEffect(() => {
        if (privacyPolicyValue) {
            setPrivacyPolicy({
                purpose: privacyPolicyValue["Purpose"],
                scopeAndImplementation: privacyPolicyValue["Scope and Implementation"],
                definitions: privacyPolicyValue["Definitions"],
                processingofPersonalData: privacyPolicyValue["Processing of Personal Data"],
                disclosureObligation: privacyPolicyValue["Disclosure Obligation"],
                dataSecurity: privacyPolicyValue["Data Security"],
                transferofPersonalData: privacyPolicyValue["Transfer of Personal Data"],
                inventoryofPersonalData: privacyPolicyValue["Inventory of Personal Data"],
                rolesandResponsibilities: privacyPolicyValue["Roles and Responsibilities"],
                RightsoftheDatSubjectsandExerciseoftheRights: privacyPolicyValue["Rights of the Data Subjects and Exercise of the Rights"],
                publicationandEntryIntoForceofthePolicy: privacyPolicyValue["Publication and Entry Into Force of the Policy"],
                updatingthePolicy: privacyPolicyValue["Updating the Policy"]
            })
        }
    }, [privacyPolicyValue]);

    useEffect(() => {
        if (termsValue) {
            setPoliciesData(termsValue["Policy on Protection and Process of Personal Data"])
        }
    }, [termsValue]);

    useEffect(() => {
        scroll()
    }, [id, location, policiesData]);

    const scroll = async () => {
        if (id) {
            let atag = document.createElement("a");
            atag.href = `#${id}`;
            atag.click();
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    if (location.pathname?.split('/')[1] === "terms_and_conditions") {
        return (
            <>
                {policiesData ?
                    <div>
                        <h5 className="policyProtectionHeading vegonHeading" style={{ lineHeight: '35px' }}>{policiesData[0]?.paragraph ? policiesData[0].paragraph : null}</h5>
                        <div>
                            {policiesData.map((data, i) => {
                                return (
                                    <div key={i}>
                                        <ul><li className='pusposeHeading policyFontWieght' id="purpose">{data.title}</li></ul>
                                        <div dangerouslySetInnerHTML={{ __html: `${data.content ? data.content : null}` }} />
                                    </div>)
                            })}
                        </div>
                    </div> : null}
            </>)
    }

    return (
        privacyPolicy ?
            <>
                <div className='policyProtection'>
                    <h5 className="policyProtectionHeading vegonHeading">{privacyPolicy?.purpose && privacyPolicy?.purpose?.length ? privacyPolicy?.purpose[0]?.heading : ""}</h5>
                    <div>
                        <div>
                            {privacyPolicy?.purpose && privacyPolicy?.purpose.length ?
                                <div>
                                    <ul><li className='pusposeHeading policyFontWieght' id="purpose">{privacyPolicy.purpose[0]?.title}</li></ul>
                                    <div className="policyOnProtectionPara">
                                        <p dangerouslySetInnerHTML={{ __html: `${privacyPolicy.purpose[0]?.content}` }}>
                                        </p>
                                    </div>
                                </div> : null}
                            {privacyPolicy.scopeAndImplementation && privacyPolicy.scopeAndImplementation.length ?
                                <div>
                                    <ul><li className='pusposeHeading policyFontWieght' id="scope">{privacyPolicy.scopeAndImplementation[0]?.heading}</li></ul>
                                    <div className="policyOnProtectionPara">
                                        <p dangerouslySetInnerHTML={{ __html: `${privacyPolicy.scopeAndImplementation[0]?.content}` }}>
                                        </p>
                                    </div>
                                </div> : null}
                        </div>
                        {privacyPolicy.definitions && privacyPolicy?.definitions?.length ?
                            <div id="privacy-policy-definitions">
                                <ul><li className='pusposeHeading'>{privacyPolicy.definitions[0]?.heading}</li></ul>
                                <div className="policyOnProtectionPara policyMargin" >
                                    <p><b></b> <span
                                        dangerouslySetInnerHTML={{ __html: `${privacyPolicy.definitions[0]?.content}` }}></span>
                                    </p>
                                </div>
                            </div> : null}
                        {privacyPolicy.processingofPersonalData && privacyPolicy.processingofPersonalData.length ?
                            <div id="privacy-policy-processing">
                                <ul ><li className='pusposeHeading '>{privacyPolicy.processingofPersonalData[0]?.heading}</li></ul>
                                {
                                    privacyPolicyValue["Processing of Personal Data"] &&
                                    privacyPolicyValue["Processing of Personal Data"].map((data, i) => {
                                        return (
                                            <div key={i}>
                                                <p className="policyParaHeading policyMargin policyMarginColor"
                                                    dangerouslySetInnerHTML={{ __html: `${data?.title}` }} ></p>
                                                <div className='policySubPara'>
                                                    <div className="policyOnProtectionPara policyContent">
                                                        <p dangerouslySetInnerHTML={{ __html: `${data?.content}` }} />
                                                    </div>
                                                </div>
                                            </div>)
                                    })
                                }
                            </div> : null}
                        <div>
                            {/* <p className="policyParaHeading policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[1]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[1]?.content}` }}></p>
                        <p className="policyParaHeading policyMargin policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[2]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[2]?.content}` }}></p>
                        <p className="policyParaHeading policyMargin policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[3]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.processingofPersonalData[3]?.content}` }}></p> */}
                            {privacyPolicy.disclosureObligation && privacyPolicy.disclosureObligation.length ?
                                <div id="privacy-policy-disclosure">
                                    <div>
                                        <ul><li className='pusposeHeading '>{privacyPolicy.disclosureObligation[0]?.heading}</li></ul>
                                    </div>
                                    <p className="policyParaHeading "
                                        dangerouslySetInnerHTML={{ __html: `${privacyPolicy.disclosureObligation[0]?.content}` }}></p>
                                </div> : null}
                            {privacyPolicy.dataSecurity && privacyPolicy.dataSecurity?.length ?
                                <div id="privacy-policy-data-security">
                                    <div>
                                        <div>
                                            <ul><li className='pusposeHeading'>{privacyPolicy.dataSecurity[0]?.heading}</li></ul>
                                        </div>
                                        <p className="policyOnProtectionPara">
                                            {privacyPolicy.dataSecurity[0]?.paragraph}
                                        </p>
                                        {
                                            privacyPolicyValue["Data Security"] &&
                                            privacyPolicyValue["Data Security"].map((data, i) => {
                                                return (
                                                    <div key={i}>
                                                        <p className="policyParaHeading policyMarginColor"
                                                            dangerouslySetInnerHTML={{ __html: `${data?.title}` }}></p>
                                                        <ul className="policyParaHeading dataSecurity policySubPara ps-3"
                                                            dangerouslySetInnerHTML={{ __html: `${data?.content}` }}>
                                                        </ul>
                                                    </div>)
                                            })
                                        }
                                        {/* <p className="policyParaHeading policyMarginColor"
                                dangerouslySetInnerHTML={{ __html: `${privacyPolicy.dataSecurity[1]?.title}` }}></p>
                            <ul className="policyParaHeading policySubPara ps-3"
                                dangerouslySetInnerHTML={{ __html: `${privacyPolicy.dataSecurity[1]?.content}` }}>
                            </ul> */}
                                    </div>
                                </div> : null}
                            {privacyPolicy.transferofPersonalData && privacyPolicy.transferofPersonalData?.length ?
                                <ul><li className='pusposeHeading' id="privacy-policy-transfer">{privacyPolicy.transferofPersonalData[0]?.heading}</li></ul> : null}
                            {
                                privacyPolicyValue["Transfer of Personal Data"] &&
                                privacyPolicyValue["Transfer of Personal Data"].map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <p className="policyParaHeading policyMarginColor"
                                                dangerouslySetInnerHTML={{ __html: `${data?.title}` }}></p>
                                            <p className="policyOnProtectionPara policySubPara"
                                                dangerouslySetInnerHTML={{ __html: `${data?.content}` }} ></p>
                                        </div>)
                                })
                            }
                            {/* <p className="policyParaHeading policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.transferofPersonalData[1]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.transferofPersonalData[1]?.content}` }}></p> */}
                            {privacyPolicy.inventoryofPersonalData && privacyPolicy.inventoryofPersonalData?.length ?
                                <div>
                                    <ul><li className='pusposeHeading' id="privacy-policy-inventory">{privacyPolicy.inventoryofPersonalData[0]?.heading}</li></ul>
                                    <p className="policyOnProtectionPara"
                                        dangerouslySetInnerHTML={{ __html: `${privacyPolicy.inventoryofPersonalData[0]?.content}` }} ></p>
                                </div> : null}
                            {privacyPolicy.rolesandResponsibilities && privacyPolicy.rolesandResponsibilities.length ?
                                <div>
                                    <ul><li className='pusposeHeading' id="privacy-policy-roles">{privacyPolicy.rolesandResponsibilities[0]?.heading}</li></ul></div> : null}
                            <p className="policyOnProtectionPara"
                                dangerouslySetInnerHTML={{ __html: `${privacyPolicy.rolesandResponsibilities && privacyPolicy.rolesandResponsibilities?.length ? privacyPolicy.rolesandResponsibilities[0]?.content : ""}` }}></p>
                            {privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights && privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights?.length ?
                                <ul ><li className='pusposeHeading' id="privacy-policy-rights">{privacyPolicyValue["Rights of the Data Subjects and Exercise of the Rights"][0]?.heading}</li></ul> : null}
                            {
                                privacyPolicyValue["Rights of the Data Subjects and Exercise of the Rights"] &&
                                privacyPolicyValue["Rights of the Data Subjects and Exercise of the Rights"].map((data, i) => {
                                    return (
                                        <div key={i}>
                                            <p className="policyParaHeading policyMarginColor"
                                                dangerouslySetInnerHTML={{ __html: `${data?.title}` }} ></p>
                                            <p className="policyOnProtectionPara policySubPara"
                                                dangerouslySetInnerHTML={{ __html: `${data?.content}` }}></p>
                                        </div>)
                                })
                            }
                            {/* <p className="policyParaHeading policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[1]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[1]?.content}` }}></p>
                        <div>
                            <p className="policyParaHeading policyMarginColor"
                                dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[2]?.title}` }}></p>
                        </div>
                        <div className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[2]?.content}` }}>
                        </div>
                        <p className="policyParaHeading policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[3]?.title}` }}></p>
                        <p className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[3]?.content}` }}></p>
                        <p className="policyParaHeading policyMarginColor"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[4]?.title}` }}></p>
                        <div className="policyOnProtectionPara policySubPara"
                            dangerouslySetInnerHTML={{ __html: `${privacyPolicy.RightsoftheDatSubjectsandExerciseoftheRights[4]?.content}` }}>
                        </div> */}
                            {privacyPolicy.publicationandEntryIntoForceofthePolicy && privacyPolicy.publicationandEntryIntoForceofthePolicy.length ?
                                <div>
                                    <ul ><li className='pusposeHeading' id="privacy-policy-publication">{privacyPolicy.publicationandEntryIntoForceofthePolicy[0]?.heading}</li></ul>
                                </div> : null}
                            <p dangerouslySetInnerHTML={{ __html: `${privacyPolicy.publicationandEntryIntoForceofthePolicy && privacyPolicy.publicationandEntryIntoForceofthePolicy.length ? privacyPolicy.publicationandEntryIntoForceofthePolicy[0]?.content : ""}` }}></p>
                            {privacyPolicy.updatingthePolicy && privacyPolicy.updatingthePolicy.length ?
                                <div>
                                    <ul><li className='pusposeHeading' id="privacy-policy-updating">{privacyPolicy.updatingthePolicy[0]?.heading}</li></ul>
                                </div> : null}
                            <p dangerouslySetInnerHTML={{ __html: `${privacyPolicy.updatingthePolicy && privacyPolicy.updatingthePolicy.length ? privacyPolicy.updatingthePolicy[0]?.content : ""}` }} />
                        </div>
                    </div>
                </div>
            </> : null
    )
}
export default PolicyOnProtection;