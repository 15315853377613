import React, { useState, useEffect } from 'react';

import { useApp } from '../../hooks/AppHooks';

function GDPR() {

    const { termsValue } = useApp();
    const [informationData, setInfmationData] = useState();

    useEffect(() => {
        if (termsValue.GDPR) {
            setInfmationData(termsValue.GDPR);
        }
    }, [termsValue]);

    return (
        <>
            {informationData ?
                <>
                    <h3 className="vegonHeading">{informationData[0]?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: `${informationData[0]?.paragraph}` }} />
                    {
                        informationData[0].content &&
                        informationData.map((data, i) => {
                            return (
                                <div key={i}>
                                    <h3
                                    >  {data?.content[0]?.subTitle}</h3>
                                    <div >
                                        <div >
                                            <p dangerouslySetInnerHTML={{ __html: `${data?.content[0]?.content}` }} />
                                        </div>
                                    </div>
                                </div>)
                        })
                    }
                </> : null}
        </>
    )
}
export default GDPR;