import React, { useEffect } from "react";
import { useApp } from "../../hooks/AppHooks";

function WhoWeAreComponent() {
    const { homeValue } = useApp();
    return (
        <>
            {homeValue ?
                <div className="newPortalWhoWeAreSection" id="who-we-are">
                    <div className="container">
                        <h3 className="whoWeHeading SubHeadingBottomMargin">
                            <span className="underLineClass">
                                {homeValue["Who We Are"]?.length && homeValue["Who We Are"][0]?.heading ? homeValue["Who We Are"][0]?.heading : null}
                            </span>
                        </h3>
                        <div className="row justify-content-between">
                            <div className="col-xxl-6 col-lg-7">
                                <div className="whoWeAreContent">
                                    {homeValue["Who We Are"]?.map((element) =>
                                        <p> {element.content}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row">
                                    <div className="col-lg-11 col-12">
                                        {homeValue["Agent Features"] && homeValue["Agent Features"].length ?
                                            <div className="whoWeAreAgentContent">
                                                <h5>
                                                    <span className="underLineClass">
                                                        {homeValue["Agent Features"][0]?.heading ? homeValue["Agent Features"][0]?.heading : null}
                                                    </span>
                                                </h5>
                                                <ul>
                                                    {homeValue["Agent Features"]?.map((element) =>
                                                        <li>{element.content}</li>
                                                    )}
                                                </ul>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {homeValue["Developer Features"] && homeValue["Developer Features"]?.length ?
                                        <div className="col-lg-11 col-12">
                                            <div className="whoWeAreDevelopersContent">
                                                <h5>
                                                    <span className="underLineClass">
                                                        {homeValue["Developer Features"][0]?.heading ? homeValue["Developer Features"][0]?.heading : null}
                                                    </span>
                                                </h5>
                                                <ul>
                                                    {homeValue["Developer Features"]?.map((element) =>
                                                        <li>{element.content}</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    )
}
export default WhoWeAreComponent;
