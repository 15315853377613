import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./skyland.scss";
import { Spinner } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../../common/PhoneNumberValidation";
import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, WEBSITE_URL, WEBSITE_X_API_KEY, CMS_API_ENDPOINT, ORGZIT_LANDING_CALL_PERMISSION, INTERNAL_CRM_LANDING_CALL_PERMISSION } from "../../common/Constants";

function SkylandMarketingPage() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const [landingFormData, setLandingFormData] = useState({
        // projectInterest: "",
        firstName: "",
        email: "",
        source: "Landing Page - SKYLAND ISTANBUL",
        phone: "",
        dial_code: "+90",
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');
    const [dialCodeWarn, setDialCodeWarn] = useState('');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setLoading(false)
        setLandingFormData({
            firstName: "",
            email: "",
            phone: "",
            dial_code: "+90"
        })
    }

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_LANDING_CALL_PERMISSION) {
          const orgzit = await axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + 
            "become-our-partner/landing-enrollment-request", landingFormData
            )
          return orgzit;
        }
      };

      async function fetchCountries() {
        try {
            const res = await axios.get("/assets/data/countryCode.json");
            if (res) {
                setCountries(res.data)
            }
        } catch (error) {
            console.error("error ", error);
        }
      }

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_LANDING_CALL_PERMISSION) {
            console.log("INTERNAL_CRM_PERMISSION", typeof INTERNAL_CRM_PERMISSION);
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const onSuccessForm = () => {
        setLandingFormData()
        resetStateValues();
        $("#exampleModal").modal('show')
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if ((landingFormData.firstName.length == "" || landingFormData.firstName.trim() === "" ||
            landingFormData.email.length == "" || landingFormData.email.trim() === "" ||
            landingFormData.phone.length == "") ||
            simpleValidator.current.errorMessages.email || dialCodeWarn) {
            setFirstNameWarn("please enter valid first name");
            setPhoneWarn("please enter phone number");
            setEmailWarn("please enter valid email address");
            setLoading(false)
            return false;
        }
        const postDataValue = {
            name: landingFormData.firstName.trim(),
            email: landingFormData.email.trim(),
            phoneNumber: landingFormData.dial_code + landingFormData.phone.trim(),
            source: "Landing Page - Skyland Istanbul",
            fromWebsite: true,
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'alya4MevsimLPTR' });
        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error("Error while form submitting");
        }
        setLoading(false);
    }
    $(function () {
        setTimeout(
            function () {
                $(document).ready(function () {
                    $("#carouselExampleIndicatorsMain").carousel();
                    // alert("started");
                });
            }, 5000);
    })

    $(function () {
        setTimeout(
            function () {
                $(document).ready(function () {
                    $("#carouselExampleIndicator").carousel();
                    // alert("started");
                });
            }, 5000);
    })

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCountries();
    }, []);

    const checkTheLandlineAndPhoneNumber = (PhoneNumber, dialCode) => {
            let validationData = PhoneNumber && dialCode ? `${dialCode}${PhoneNumber}`: "";
            const parsedNumber = phoneNumberUtil.parse(validationData);
            const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
            if (validationData && isoCode) {
                if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                    validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                    setDialCodeWarn("")
                } else {
                    setDialCodeWarn("please enter valid phone number");
                }
            }
    }

    const handleSelectChange = (event) => {
        setLandingFormData({
            ...landingFormData,
            dial_code: event.target.value
        })
        checkTheLandlineAndPhoneNumber(landingFormData?.phone, event.target.value)
    };

    return (
        <>
            <div className="skylandSection">
                <section className="tema_istanbul_main_section" id="desktop-nav">
                    <article className="main_banner_section" id="01">
                        <div className="main_banner_carousel">
                            <div id="carouselExampleIndicatorsMain" className="carousel slide d-md-block d-none" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" id="btn1" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" id="btn2" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" id="btn3" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" id="btn4" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/2.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/1.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/3.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/4.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel_slider d-block d-md-none">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                                        aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/2.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/1.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/3.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/skyland/4.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main_banner_image_section">
                            <div className="container">
                                <div className="position-relative">
                                    <div className="row m-0">
                                        <div className="col text-md-start text-center">
                                            <a href={WEBSITE_URL} target="_blank"> <img className="cubedots_logo_image"
                                                src="/assets/images/landingpageimage/skyland/cubedots.png" alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="social_icons">
                                        <p>Follow us</p>
                                        <div className="social_icons_image">
                                            <div>
                                                <a href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=" target="_blank">
                                                    <img src="/assets/images/landingpageimage/skyland/instagram.png" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 justify-content-center">
                                        <div className="col-md-8">
                                            <div className="main_banner_content">
                                                <img src="/assets/images/landingpageimage/skyland/Skyland_logo.png" className="img-fluid" />
                                                <div className="main_banner_content_box_section">
                                                    <small className="small_text">SKYLAND ISATNBUL </small>
                                                    <h1>A New Premium Destination</h1>
                                                    <p>
                                                        An esteemed and well-known developer is behind this project,
                                                        which brings the best of residence, work and commerce together.
                                                        An exceptional experience awaits you with beautifully designed
                                                        apartments, elevated office spaces and concept commercial areas.

                                                    </p>
                                                </div>
                                                <div className="form_box">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-4 name_col">
                                                            <input type="text" placeholder="Name" className="form-control"
                                                                name="firstName"
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("firstName")}
                                                                value={landingFormData?.firstName}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    firstName: e.target.value
                                                                })} />
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{landingFormData?.firstName.length == "" || landingFormData.firstName.trim() === "" ? firstNameWarn : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 code_col">
                                                            <div className="input-group">
                                                                <select value={landingFormData.dial_code} className="country-dropdown" onChange={handleSelectChange} >
                                                                {countries.length > 0 && countries.map((row, index) =>
                                                                        <option value={row.dial_code} key={index} >{row.dial_code}</option>)}
                                                                </select>
                                                                <input type="tel" placeholder="Phone Number" id="txtPhone" className="txtbox form-control"
                                                                    name="mobile"
                                                                    value={landingFormData?.phone}
                                                                    onKeyUp={() => simpleValidator?.current?.showMessageFor("mobile")}
                                                                    onChange={(e) => {
                                                                        setLandingFormData({
                                                                            ...landingFormData,
                                                                            phone: e.target.value.replace(/\D/g, "")
                                                                        })
                                                                        checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""), landingFormData.dial_code)
                                                                    }} />
                                                            </div>
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{landingFormData?.phone?.length == "" ? phoneWarn : dialCodeWarn ? dialCodeWarn : ""}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 email_col">
                                                            <input type="text" placeholder="E-mail" className="form-control"
                                                                name="email"
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("email")}
                                                                value={landingFormData?.email}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    email: e.target.value
                                                                })} />
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{landingFormData?.email?.length == "" || landingFormData.email.trim() === "" ? emailWarn : ""}</p>
                                                                <p className='errorMsg'>{simpleValidator?.current?.message('email', landingFormData?.email, 'email')}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-md-center text-end">
                                                            {loading ? (
                                                                <button className="second_send_btn" type="button"
                                                                    disabled id="btnSubmit">
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />{" "}
                                                                    Submit
                                                                </button>
                                                            ) : (
                                                                <button className="second_send_btn" id="btnSubmit"
                                                                    onClick={onSubmit}>Submit</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="https://goo.gl/maps/WX9YX4LBffccs25a9" target="_blank"><button className="location_btn">
                                        Location
                                    </button></a>
                                </div>
                            </div>

                        </div>
                    </article>
                    <article className="main_content_section" id="02">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-9">
                                    <div className="content_box">
                                        <div className="row">
                                            <div className="col-md-6 order-sm-1 order-2">
                                                <div className="ideal_content">
                                                    <small className="small_text">GET STARTED</small>
                                                    <h1>
                                                        A New Premium Destination
                                                    </h1>
                                                    <p>Located in the heart of Istanbul, Skyland is the rising symbol of prestige in
                                                        the city. The project is made up of three main towers and has numerous
                                                        onsite facilities, including indoor & outdoor swimming pool, fitness centre
                                                        and spa. Each apartment is state of the art and offers total luxury and
                                                        comfort. With a young, diverse and elite community, Maslak grants its
                                                        residents endless opportunities when it comes to entertainment, recreation
                                                        and relaxation. Skyland is a beacon of total excellence in Istanbul.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="family_friendly_main_section d-md-block d-none">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/luxury.png" />
                                                        <h6>Luxury</h6>
                                                        <p>
                                                            There are many on-site amenities such as the project mall HOM Design,
                                                            Gastro Court and the fully equipped Spa & fitness centre. Each apartment
                                                            has a smart home system and a top end finish throughout.


                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/location.png" />
                                                        <h6>Location</h6>
                                                        <p>
                                                            Maslak is rich with opportunities when it comes to business, education
                                                            and entertainment. Not to mention, the district is home to Istanbul’s
                                                            biggest forest,
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/entertainment.png" />
                                                        <h6>Entertainment</h6>
                                                        <p>
                                                            For shopping enthusiasts there are countless malls nearby such as
                                                            Vadi Istanbul, Istanbul Sapphire, Istinye Park, Özdilek Park, AXIS and
                                                            Kanyon. Moreover, Maslak has also become an attraction for sports lovers
                                                            with the Nef (Galatasaray) Stadium and other sports complexes.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="carouselExampleIndicator"
                                            className="family_friendly_main_section d-block d-md-none carousel slide "
                                            data-ride="carousel">
                                            <ol className="carousel-indicators">
                                                <li data-target="#carouselExampleIndicator" data-slide-to="0" className="active"></li>
                                                <li data-target="#carouselExampleIndicator" data-slide-to="1"></li>
                                                <li data-target="#carouselExampleIndicator" data-slide-to="2"></li>
                                            </ol>
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/luxury.png" />
                                                        <h6>Luxury</h6>
                                                        <p>
                                                            There are many on-site amenities such as the project mall HOM Design,
                                                            Gastro Court and the fully equipped Spa & fitness centre. Each apartment
                                                            has a smart home system and a top end finish throughout.


                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/location.png" />
                                                        <h6>Location</h6>
                                                        <p>
                                                            the Belgrade Forest which leads to an ideal combination of the vibrant
                                                            city lifestyle and green living.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/skyland/entertainment.png" />
                                                        <h6>Entertainment</h6>
                                                        <p>
                                                            For shopping enthusiasts there are countless malls nearby such as
                                                            Vadi Istanbul, Istanbul Sapphire, Istinye Park, Özdilek Park, AXIS and
                                                            Kanyon. Moreover, Maslak has also become an attraction for sports lovers
                                                            with the Nef (Galatasaray) Stadium and other sports complexes.

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="position-relative ">
                        <div className="image_section_footer1">
                            <div id="carouselExampleIndicatorsImageContent" className="carousel slide d-md-block d-none"
                                data-bs-ride="true">
                                <div className="carousel-indicators">
                                    {/* <!-- <h3>Start</h3> --> */}
                                    <button type="button" id="btn1" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" id="btn2" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" id="btn3" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" id="btn4" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" id="btn5" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="4" aria-label="Slide 5"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="/assets/images/landingpageimage/skyland/01.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/skyland/02.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/skyland/03.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/skyland/04.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/skyland/05.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image_section_footer2">
                            <div className="carousel_sliders d-block d-md-none">
                                <div id="carouselExampleIndicatorss" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="0"
                                            className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="1"
                                            aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="2"
                                            aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="3"
                                            aria-label="Slide 4"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="4"
                                            aria-label="Slide 5"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src="/assets/images/landingpageimage/skyland/01.png" className="img-fluid d-block w-100" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/skyland/02.png" className="img-fluid d-block w-100" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/skyland/03.png" className="img-fluid d-block w-100" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/skyland/04.png" className="img-fluid d-block w-100" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/skyland/05.png" className="img-fluid d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="cubedots_last" id="03">
                        <a href={WEBSITE_URL} target="_blank"><img src="/assets/images/landingpageimage/skyland/cubedots.png" /></a>
                    </article>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#7D003D"
                                        className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </p>
                                <div className="modal-body">
                                    <h3>Thank You !</h3>
                                    <h6>We will contact you soon.</h6>
                                    <button type="button" className="okBtn" data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}
export default SkylandMarketingPage;