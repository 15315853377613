import React, { useState, useEffect } from "react";
import PolicyOnProtection from "../Components/terms/PolicyOnProtection"
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import { CMS_PORTAL_BUCKET } from '../common/Constants';
import { useApp } from "../hooks/AppHooks";
import { Spin } from "antd";

function PrivacyPolicyPage() {
    const { privacyPolicyValue , headerValue} = useApp();
    const banner = privacyPolicyValue?.Banner;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (privacyPolicyValue && Object.keys(privacyPolicyValue)?.length) && headerValue ? (
        <>
            <BecomeOurPartnerButton />
            <div className="mt-70 privacySection">
                { banner &&
                <div className="banner_box" id="privacy-policy-banner">
                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${banner[0].imageLink}`} className="bannerImage img-fluid" />
                    <div className=" bannerImageHeading container">
                        <div className="privacyPolicies">
                            <h3 className="marginBottom">
                                {banner[0].heading}</h3>
                            {/* <button>Know More</button> */}
                        </div>
                    </div>
                </div> }
                <div className="container">
                    <div className="privacy_content_box" id="privacy-content">
                        <PolicyOnProtection />
                    </div>
                </div>
            </div>
        </>
    ) : <Spin className="page-spin-loading"/>;
}

export default PrivacyPolicyPage;