import { useState } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, } from "react-share";
import { useEffect } from "react";


function SocialShareButtons(props) {
  const [url, setUrl] = useState("None");
  const shareUrl = url;

  useEffect(() => {
    setUrl(window.location.href);
  })

  return (
    <>
      <FacebookShareButton
        url={shareUrl}
        fill={"red"}
        bgStyle={"fill:red"}
        className="Demo__some-network__share-button me-3">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton
        url={shareUrl}
        className="Demo__some-network__share-button me-3">
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <WhatsappShareButton
        url={shareUrl}
        separator=":: "
        className="Demo__some-network__share-button me-3">
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <LinkedinShareButton
        url={shareUrl}
        className="Demo__some-network__share-button me-3">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </>
  );
}
export default SocialShareButtons;
