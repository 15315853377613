import React, { useEffect } from 'react';
import { useApp } from '../../hooks/AppHooks';

function DataSubjectForm() {
    let publicPath = process.env.PUBLIC_URL;
    const { termsValue } = useApp();
    const [applicationData, setApplicationData] = React.useState();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    useEffect(() => {
        if (termsValue["Data Subject Application Form"] && termsValue["Data Subject Application Form"]?.length) {
            setApplicationData(termsValue["Data Subject Application Form"]);
        }
    }, [termsValue]);
    return (
        <>
            {applicationData ?
                <div>
                    <h3 className="vegonHeading">{applicationData[0]?.paragraph}</h3>
                    <div>
                        {applicationData.map((data, i) => {
                            return (
                                <div key={i}>
                                    <h5>{data.title}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: `${data.content ? data.content : null}` }} />
                                </div>)
                        })

                        }
                    </div>
                </div> : null}
        </>
    )
}
export default DataSubjectForm;