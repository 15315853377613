import { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import { Spin } from "antd";

function SiteMapPage() {
  const { id } = useParams();
  const location = useLocation();
  const langId = location.search.split("=")[1];

  const { sitMapValue } = useApp();

  const [banner, setbanner] = useState([]);
  const [pagesName, setpagesName] = useState([]);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}, []);

useEffect(() => {
  if (sitMapValue) {
    scroll();
    getFooterData();
  }
}, [id, location, sitMapValue]);

  const scroll = async () => {
    if (id) {
      let atag = await document.createElement("a");
      atag.href = `#${id}`;
      atag.click();
    }
    $(document).ready(function () {
      $(`#${id}`).click(function () {
          window.scrollTo({ top: 650, left: 0, behavior: 'smooth' });
      });
      $(`#${id}`).trigger('click');
  });
  };
  const getFooterData = () => {
    setbanner(sitMapValue["Banner"] && sitMapValue["Banner"][0]);
    setpagesName(sitMapValue["Site Map"] && sitMapValue["Site Map"][0]?.pageName);
  };
 
  return (
    <>
      <BecomeOurPartnerButton />
      {sitMapValue && Object.keys(sitMapValue)?.length ?
        <div className="mt-70 sitemapSection">
          {banner?.logo ?
            <div className="banner_box" id="banner">
              <img
                class="bannerImage img-fluid"
                src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${banner?.logo && banner.logo}`}
              />
              <div className="bannerImageHeading container">
                <div className="aboutContentImage">
                  <h3 className="marginBottom">{banner["title"]}</h3>
                </div>
              </div>
            </div>
            : null
          }
          <div className="container" id="siteMapList">
            {pagesName && pagesName.length ?
              <ul className="siteMapList">
                <Link to={langId ? `/?lang=${langId}` : `/`} >
                  <li>{pagesName[0]?.page}</li>
                </Link>
                <Link to={langId ? `/aboutus/?lang=${langId}` : `/aboutus`}>
                  <li>{pagesName[1]?.page}</li>
                </Link>
                <Link to={langId ? `/agents/?lang=${langId}` : `/agents`}>
                  <li>{pagesName[2]?.page}</li>
                </Link>
                <Link to={langId ? `/developer/?lang=${langId}` : `/developer`}>
                  <li>{pagesName[3]?.page}</li>
                </Link>
                <Link to={langId ? `/projects/?lang=${langId}` : `/projects`}>
                  <li>{pagesName[4]?.page}</li>
                </Link>
                <Link to={langId ? `/blogs/?lang=${langId}` : `/blogs`}>
                  <li>{pagesName[5]?.page}</li>
                </Link>
                <Link to={langId ? `/contact/?lang=${langId}` : `/contact`}>
                  <li>{pagesName[6]?.page}</li>
                </Link>
                <Link to={langId ? `/privacy_policy/?lang=${langId}` : `/privacy_policy`}>
                  <li>{pagesName[7]?.page}</li>
                </Link>
                <Link to={langId ? `/terms_and_conditions/?lang=${langId}` : `/terms_and_conditions`}>
                  <li>{pagesName[8]?.page}</li>
                </Link>
                <Link to={langId ? `/cookie-policy/?lang=${langId}` : `/cookie-policy`}>
                  <li>{pagesName[9]?.page}</li>
                </Link>
              </ul>
              : null
            }
          </div>
        </div> : <Spin className="page-spin-loading"/>}
    </>
  ) ;
}

export default SiteMapPage;
