import React from "react";

const FourthSkyalnd = () => {
    return (
        <>
            <div className="fourthSection">
                <div className="container-fluid p-0 ">
                    <div className="row p-0 m-0">
                        <div className="col-md-4 p-0">
                            <div className="slylandImages">
                                <img src="/assets/images/cityscape/6.jpg" className="img-fluid skylandImage" />
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="fourthSectionContent">
                                <img src="/assets/images/cityscape/skyland_logo.png" className="img-fluid skyalandLogo" width={400} />
                                <div>
                                    <h6>High Commissions For Agents</h6>
                                    <h6>& Exclusive Investment And</h6>
                                </div>
                                <div>
                                    <h6>Citizenship Opportunities</h6>
                                    <h6>For you Clients!</h6>
                                </div>
                                <div>
                                    <img src="/assets/images/cityscape/customer.png" className="img-fluid customeImage"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FourthSkyalnd;