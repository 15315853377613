import React, { useState } from 'react';
import Modal from 'react-modal';
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from '../../hooks/AppHooks';

function ForAgentcomponent() {
    const { agentsValue } = useApp();
    const publicPath = process.env.PUBLIC_URL;

    const [show2, setShow2] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [isActiveHover, setIsActiveHover] = useState(false);
    const [isActiveHandleShow1, setIsActiveHandleShow1] = useState(false);
    const [isActiveHandleShow1Hover, setIsActiveHandleShow1Hover] = useState(false);
    const [isHandleShow2, setIsHandleShow2] = useState(false);
    const [isHandleShow2Hover, setIsHandleShow2Hover] = useState(false);
    const [isHandleShow3, setIsHandleShow3] = useState(false);
    const [isHandleShow3Hover, setIsHandleShow3Hover] = useState(false);

    const handleClose1 = () => {
        document.body.style.overflow = 'auto';
        setShow1(false);
        setIsActive(false)
    }
    const handleShow1 = () => {
        document.body.style.overflow = '';
        setIsActive(current => !current);
        setShow1(true);
    }
    const handleClose2 = () => {
        document.body.style.overflow = 'auto';
        setShow2(false);
        setIsActiveHandleShow1(false)
    }
    const handleShow2 = () => {
        document.body.style.overflow = '';
        setIsActiveHandleShow1(current => !current);
        setShow2(true);
    }
    const handleClose3 = () => {
        document.body.style.overflow = 'auto';
        setShow3(false);
        setIsHandleShow2(false)
    }

    const handleShow3 = () => {
        document.body.style.overflow = '';
        setIsHandleShow2(current => !current);
        setShow3(true);
    }
    const handleClose4 = () => {
        document.body.style.overflow = 'auto';
        setShow4(false);
        setIsHandleShow3(false)
    }
    const handleShow4 = () => {
        document.body.style.overflow = '';
        setIsHandleShow3(current => !current);
        setShow4(true);
    }

    const contentValue = agentsValue["Agent/ Sales Service Support"] ? Object.values(agentsValue["Agent/ Sales Service Support"]?.reduce((acc, obj, ind) => {
        const { subTitle, content } = obj;
        acc[subTitle] = acc[subTitle] || { subTitle }
        acc[subTitle].contentFields = acc[subTitle].contentFields || []
        acc[subTitle].contentFields.push({ content })
        return acc;
    }, {})) : {};

    return (
        <>
            {agentsValue && Object.keys(agentsValue).length ?
                <div className="forAgentContent" id='for-agent-content'>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-6" id='services'>
                                {agentsValue && agentsValue["Agents"]?.map((data) => {
                                    return (
                                        <p>{data.content}</p>
                                    )
                                })}
                                <ul className="agencyBoxModal">
                                    <div className='row'>
                                        {agentsValue["Agent/ Sales Service Support"] && agentsValue["Agent/ Sales Service Support"]?.length ?
                                            <div className='col-md-3 col-6 p-0'>
                                                <li className='SubHeadingBottomMargin'>
                                                    <button variant="primary" onClick={handleShow1} href="#" className='buttonOnAgents'
                                                        onMouseEnter={() => setIsActiveHover(true)}
                                                        onMouseLeave={() => setIsActiveHover(false)}
                                                    >
                                                        {
                                                            !isActive && !isActiveHover
                                                                ?
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Sales Service Support"][0]?.imageLink}`} />
                                                                :
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Sales Service Support"][0]?.backgroundImage}`} />
                                                        }

                                                        <p
                                                            style={{
                                                                color: isActive ? '#dc6641' : '',
                                                            }}>{agentsValue["Agent/ Sales Service Support"][0]?.buttonName}</p>
                                                    </button>
                                                </li>
                                            </div>
                                            : null
                                        }
                                        {agentsValue["Agent/ After Sales Service"] && agentsValue["Agent/ After Sales Service"]?.length ?
                                            <div className='col-md-3 col-6 p-0'>
                                                <li className='SubHeadingBottomMargin'>
                                                    <button href="#" onClick={handleShow2} className='buttonOnAgents'
                                                        onMouseEnter={() => setIsActiveHandleShow1Hover(true)}
                                                        onMouseLeave={() => setIsActiveHandleShow1Hover(false)}
                                                    >
                                                        {
                                                            !isActiveHandleShow1 && !isActiveHandleShow1Hover ?
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ After Sales Service"][0]?.imageLink}`} />
                                                                :
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ After Sales Service"][0]?.backgroundImage}`} />
                                                        }

                                                        <p style={{
                                                            color: isActiveHandleShow1 ? '#dc6641' : '',
                                                        }}>{agentsValue["Agent/ After Sales Service"][0]?.buttonName}</p></button>
                                                </li>
                                            </div>
                                            : null
                                        }
                                        {agentsValue["Agent/ Partnership Benefits"] && agentsValue["Agent/ Partnership Benefits"]?.length ?
                                            <div className='col-md-3 col-6 p-0'>
                                                <li className='SubHeadingBottomMargin'>
                                                    <button href="#" onClick={handleShow3} className='buttonOnAgents'
                                                        onMouseEnter={() => setIsHandleShow2Hover(true)}
                                                        onMouseLeave={() => setIsHandleShow2Hover(false)}
                                                    >
                                                        {
                                                            !isHandleShow2 && !isHandleShow2Hover ?
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Partnership Benefits"][0]?.imageLink}`} />
                                                                :
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Partnership Benefits"][0]?.backgroundImage}`} />
                                                        }

                                                        <p style={{
                                                            color: isHandleShow2 ? '#dc6641' : '',

                                                        }}>{agentsValue["Agent/ Partnership Benefits"][0]?.buttonName}</p> </button>
                                                </li>
                                            </div>
                                            : null
                                        }
                                        {agentsValue["Agent/ Agency Relations Support"] && agentsValue["Agent/ Agency Relations Support"]?.length ?
                                            <div className='col-md-3 col-6 p-0'>
                                                <li className='SubHeadingBottomMargin'>
                                                    <button href="#" onClick={handleShow4} className='buttonOnAgents'
                                                        onMouseEnter={() => setIsHandleShow3Hover(true)}
                                                        onMouseLeave={() => setIsHandleShow3Hover(false)}
                                                    >
                                                        {
                                                            !isHandleShow3 && !isHandleShow3Hover
                                                                ?
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Agency Relations Support"][0]?.imageLink}`} />
                                                                :
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agent/ Agency Relations Support"][0]?.backgroundImage}`} />
                                                        }
                                                        <p style={{
                                                            color: isHandleShow3 ? '#dc6641' : '',
                                                        }}>{agentsValue["Agent/ Agency Relations Support"][0]?.buttonName}</p></button>
                                                </li>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </ul>
                            </div>
                            {agentsValue["Agents"] && Object.keys(agentsValue.Agents).length ?
                                <div className="col-md-5">
                                    <div className='forAgentImage'>
                                        <img className="img-fluid" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${agentsValue["Agents"]?.length && agentsValue["Agents"][0]?.imageLink}`} />
                                    </div>
                                </div> : null
                            }
                        </div>
                    </div>
                    {agentsValue["Agent/ Sales Service Support"] && agentsValue["Agent/ Sales Service Support"]?.length ?
                        <Modal isOpen={show1} onRequestClose={handleClose1}>
                            <div className="agentZFeatModal">
                                <div className='position-relative'>
                                    <h4>
                                        <span className='underLineClassAgent'>{agentsValue["Agent/ Sales Service Support"][0]?.title}</span>
                                    </h4>
                                    <a className="cloneBtnNewModal" onClick={handleClose1}><img src={publicPath + "/assets/images/Close.png"} /></a>
                                </div>
                                {contentValue.map((data, index) => {
                                    return (
                                        <ul className='salesServiceEvents' key={index}>
                                            <p>
                                                <span className='underLineClassAgent'>{data.subTitle}</span>
                                            </p>
                                            {data.contentFields.map((contentList, index) => {
                                                return (
                                                    <li key={index}>{contentList.content}</li>
                                                )
                                            })}
                                        </ul>
                                    )
                                })}
                            </div>
                        </Modal>
                        : null
                    }
                    {agentsValue["Agent/ After Sales Service"] && agentsValue["Agent/ After Sales Service"]?.length ?
                        <Modal isOpen={show2} onRequestClose={handleClose2}>
                            <div className="agentZFeatModal">
                                <div className='position-relative'>
                                    <h4>
                                        <span className='underLineClassAgent'>{agentsValue["Agent/ After Sales Service"][0]?.title}</span>
                                    </h4>
                                    <a className="cloneBtnNewModal" onClick={handleClose2}><img src={publicPath + "/assets/images/Close.png"} /></a>
                                </div>
                                <ul className='agentsModalLi'>
                                    {agentsValue["Agent/ After Sales Service"]?.map((data, index) => {
                                        return (
                                            <li key={index}>{data.content}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Modal>
                        : null
                    }
                    {agentsValue["Agent/ Partnership Benefits"] && agentsValue["Agent/ Partnership Benefits"]?.length ?
                        <Modal isOpen={show3} onRequestClose={handleClose3}>
                            <div className="agentZFeatModal">
                                <div className='position-relative'>
                                    <h4> <span className='underLineClassAgent'>{agentsValue["Agent/ Partnership Benefits"][0]?.title}</span></h4>
                                    <a className="cloneBtnNewModal" onClick={handleClose3}><img src={publicPath + "/assets/images/Close.png"} /></a>
                                </div>
                                <ul className='agentsModalLi'>
                                    {agentsValue["Agent/ Partnership Benefits"]?.map((data, index) => {
                                        return (
                                            <li key={index}>{data.content}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Modal>
                        : null
                    }
                    {agentsValue["Agent/ Agency Relations Support"] && agentsValue["Agent/ Agency Relations Support"]?.length ?
                        <Modal isOpen={show4} onRequestClose={handleClose4}>
                            <div className="agentZFeatModal">
                                <div className='position-relative'>
                                    <h4><span className='underLineClassAgent'>{agentsValue["Agent/ Agency Relations Support"][0]?.title}</span></h4>
                                    <a className="cloneBtnNewModal" onClick={handleClose4}><img src={publicPath + "/assets/images/Close.png"} /></a>
                                </div>
                                <ul className='agentsModalLi'>
                                    {agentsValue["Agent/ Agency Relations Support"]?.map((data, index) => {
                                        return (
                                            <li key={index}>{data.content}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </Modal>
                        : null
                    }
                </div> : null
            }
        </>
    )
}
export default ForAgentcomponent;