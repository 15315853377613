import React from "react";
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function ManagementComponent() {
    const { aboutUsValue } = useApp();
    return (
        <>
            {aboutUsValue["Founders"] && aboutUsValue["Founders"]?.length ?
                <div className="managementSection" id="message-from-founders">
                    <div className="container">
                        <h3 className="managementMessageHeading SubHeadingBottomMargin">
                            <span className="underLineClass">
                                {aboutUsValue["Founders"][0]?.heading}
                            </span>
                        </h3>
                        <div className="row ">
                            <div className="col-lg-4 col-md-6">
                                <div className="managementImages">
                                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["Founders"][0]?.imageLink}`} className="img-fluid mb-md-0 mb-4" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="managementImages">
                                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["Founders"][0]?.backgroundImage}`} className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-4 mt-lg-0 mt-3">
                                <p>
                                    {aboutUsValue["Founders"][0].content}
                                </p>
                                <p className="d-xl-block d-none">
                                    {aboutUsValue["Founders"][1]?.content}
                                </p>
                                <p className="d-xxl-block d-none">
                                    {aboutUsValue["Founders"][2]?.content}
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="secondParaManagement mt-4">
                                    <p className="d-xl-none d-block">{aboutUsValue["Founders"][1]?.content}</p>
                                    <p className="d-xxl-none d-block">{aboutUsValue["Founders"][2]?.content}</p>
                                    {aboutUsValue["Founders"]?.map((foundersData, index) => {
                                        if (index > 2) {
                                            return (
                                                <p key={index}>
                                                    {foundersData.content}
                                                </p>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                : null
            }
        </>
    )
}
export default ManagementComponent;