import React from "react";
import Header from "./component/Header";
import Banner from "./component/Banner";
import SecondUltra from "./component/SecondUltra";
import FormSection from "./component/FormSection";
import ThirdModern from "./component/ThirdModer"
import FourthSkyalnd from "./component/FourthSkyalnd";
import FifthCitizenShip from "./component/FifthCitizenShip";
import FooterSection from "./component/FooterSection";
import "./cityscape1.scss";

const CityScape = () => {
    return (
        <>
            <div className="cityScapeLandingPage1">
                <Header />
                <Banner />
                <SecondUltra />
                <FormSection />
                <ThirdModern />
                <FourthSkyalnd />
                <FifthCitizenShip />
                <FooterSection />
            </div>


        </>
    )
}
export default CityScape;