import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom"
import axios from "axios";
import SimpleReactValidator from 'simple-react-validator';
import { Spinner } from "react-bootstrap";
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import Calendar from 'react-calendar';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import ContactEnrollmentModalComponent from "../Components/ContactEnrollmentModalComponent";
import BookDemoComponent from "../Components/contactPage/BookDemoComponent"
import { insiderEvent, WEBSITE_X_API_KEY, CMS_API_ENDPOINT, ORGZIT_CALL_PERMISSION, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../common/Constants";
import SuccessfulPopupComponent from "../Components/SuccessfulPopupComponent";
import { Spin } from "antd";
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../common/PhoneNumberValidation";

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";


function ContactPage(props) {
    const location = useLocation()
    const { id } = useParams();
    const [value, onChange] = useState(new Date());
    const format = 'h:mm a';
    const now = moment().hour(0).minute(0);

    const publicPath = process.env.PUBLIC_URL;
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const simpleValidator = useRef(new SimpleReactValidator())
    const [formData, setFormData] = useState({ firstName: "", lastName: "", email: '', mobile: '', message: '', projectinterest: '', country: '', occupation: '', dial_code: '', event: 'send-us-a-message' });
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [mobileerror, setMobileError] = useState("");
    const [showInput, setShowInput] = useState("");
    const [countryselect, setCountrySelect] = useState("");
    const [projects, setProjects] = useState([]);
    const [occupationslected, setOccupationSelected] = useState("");
    const [projectinterest, setProjectInterest] = useState("");
    const [loading, setLoading] = useState(false);
    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(false);
    const [isBookDemo, setIsBookDemo] = useState(props?.isOpenForAppointment ? true : false);
    const [isContactEnquiryModalShow, setContactEnquiryModalShow] = useState(props?.isOpenForEnquireProject ? true : false);
    const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
    const [dropDown, setDropDown] = useState([]);
    const { sendUsMessageData, contactData, ourOfficesData, unsoldProjectValue, headerValue, countries, languageId, language, languageValue } = useApp();

    useEffect(() => {
        scroll();
    }, [id, ourOfficesData, projects, contactData, sendUsMessageData])

    const scroll = async () => {
        if (id) {
            let atag = await document.createElement("a");
            atag.href = `#${id}`;
            atag.click();
        }
    }

    async function fetchProjects() {
        if (unsoldProjectValue && unsoldProjectValue.length) {
            setProjects(unsoldProjectValue ? unsoldProjectValue?.map((data) => ({
                "value": data.id,
                "label": data.title
            })) : null)
        }
    }

    async function fetchInterestedAsDropdownValues() {
        if (sendUsMessageData && Object.keys(sendUsMessageData).length) {
            setDropDown(sendUsMessageData ? sendUsMessageData.interestedAsDropdownValues?.map((data) => ({
                "value": data.paragraph,
                "label": data.paragraph
            })) : null)
        }
    }

    const showBookDemo = () => {
        document.body.style.overflow = ''
        setIsBookDemo(true);
    }

    const closeBookDemo = () => {
        document.body.style.overflow = 'auto'
        setIsBookDemo(false);
    }

    const showContactEnquiryModal = () => {
        document.body.style.overflow = ''
        setContactEnquiryModalShow(true);
    }

    const closeContactEnquiryModal = () => {
        document.body.style.overflow = 'auto'
        setContactEnquiryModalShow(false);
    }

    const showBecomeOurPartnerModal = () => {
        document.body.style.overflow = ''
        setIsBecomeOurPartnerModalShow(true);
    }

    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto'
        setIsBecomeOurPartnerModalShow(false);
    }

    const HandleInputHide = (event) => {
        const setUserInput = event.target.value;
        setShowInput(setUserInput);
    };

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios
                .post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/enrollment-request", formData);
            return orgzit;
        }
    };

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const resetFrom = () => {
        setFormData({
            projectinterest: "",
            firstName: "",
            lastName: "",
            email: "",
            country: "",
            dial_code: "",
            mobile: "",
            occupation: "",
            message: "",
            event: "send-us-a-message"
        })
        document.getElementById("form1").reset();
    };

    const onSuccessForm = () => {
        setFirstName("");
        setLastName("");
        setEmailError("");
        setMobileError("");
        setProjectInterest("");
        setCountrySelect("");
        setOccupationSelected("");
        setLoading(false);
        resetFrom();
        setShowInput('');
        setTimeout(() => {
            // alert(res.data.message);
            setIsSuccessModalShow(true);
        }, 1000);
        if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': 'contact-usleadForm' });
            insiderEvent("send-us-a-message");
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formData.firstName.length == "" || formData.lastName.length == "" || formData.email.length == "" ||
            formData.occupation.length == "" || formData.country.length == "" || formData.mobile.length == "" ||
            formData.mobile.length < 4 || formData.mobile.length > 20) {
            setFirstName(sendUsMessageData.firstNameValidation);
            setLastName(sendUsMessageData.lastNameValidation);
            setEmailError(sendUsMessageData.emailValidation);
            setMobileError(sendUsMessageData.mobileNumberValidation);
            setOccupationSelected(sendUsMessageData.interestedAsValidation);
            setCountrySelect(sendUsMessageData.countryValidation);
            return false;
        }
        if (formData.occupation === dropDown[0]?.value && formData.projectinterest === "") {
            setProjectInterest(sendUsMessageData.projectValidation);
            return false;
        }
        if (!simpleValidator.current.fields.email) {
            return false;
        }
        setLoading(true);
        const postDataValue = {
            name: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            phoneNumber: formData.dial_code + formData.mobile,
            notes: formData.message,
            source: "Cubedots Portal",
            fromWebsite: true,
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error('Error while form submitting');
        };
        setLoading(false);
    }

    const handleChangeCountry = (e) => {
        e.persist();
        let selectedCountryName = e.target.value;
        setFormData((formData) => ({ ...formData, country: selectedCountryName }));
        let countriesList = countries;
        let newDialCode = Object.keys(countriesList)
            .filter((x) => {
                return countriesList[x].country_name === selectedCountryName;
            })
            .map((x) => {
                return countriesList[x].dial_code;
            });
        let dial_code = newDialCode.length ? newDialCode[0] : "";
        setFormData((formData) => ({ ...formData, dial_code: dial_code }));
    };


    function onChangeTime(value) { }

    useEffect(() => {
        if (sendUsMessageData) {
            fetchProjects();
            fetchInterestedAsDropdownValues();
        }
    }, [location, sendUsMessageData]);

    const checkTheLandlineAndPhoneNumber = (PhoneNumber) => {
        const { dial_code } = formData;
        let validationData = PhoneNumber && dial_code ? `${dial_code}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setMobileError("")
            } else {
                setMobileError(sendUsMessageData.mobileNumberValidation)
            }
        }
    }

    return (
        <>
            {((sendUsMessageData && Object.keys(sendUsMessageData)?.length) || (contactData && Object.keys(contactData)?.length) ||
                (ourOfficesData && Object.keys(ourOfficesData)?.length)) && headerValue ?
                <>
                    <BecomeOurPartnerButton /><div className="newPortalContactSection">
                        {/* <WatsupComponent /> */}
                        {contactData?.Banner?.length && contactData?.Banner[0] ?
                            <div className="contactImagebox commanBannerImageMobile" id="contactBanner">
                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${contactData?.Banner[0].backgroundImage}`} className="bannerImage img-fluid" />
                                <div className="container">
                                    <div className="contactContentImage">
                                        <div className="">
                                            <div className="">
                                                <h3 className="marginBottom">{contactData?.Banner[0]?.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            null
                        }
                        <div className="contactContentSection" id="contactUs">
                            <div className="container">
                                <div className="coantactFormMainSection">
                                    <div className="row justify-content-between">
                                        {sendUsMessageData && Object.keys(sendUsMessageData).length ?
                                            <div className="col-md-5 mb-4">
                                                <h3>
                                                    <span className="underLineClass">
                                                        {sendUsMessageData?.heading}
                                                    </span>
                                                </h3>
                                                <form id="form1" onSubmit={onSubmit}>
                                                    <div className="contactFormContent">
                                                        <div className="contactFormInputData">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label className="pt-0">{sendUsMessageData?.firstName}</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder={sendUsMessageData?.firstNamePlaceholder}
                                                                        name="firstName"
                                                                        onKeyUp={() => simpleValidator.current.showMessageFor("firstName")}
                                                                        value={formData.firstName}
                                                                        onChange={(e) => setFormData({
                                                                            ...formData,
                                                                            firstName: e.target.value.replace(/[^a-z]/gi, ''),
                                                                        })} />
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.firstName.length == 0 ? firstName : ""}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="pt-0">{sendUsMessageData?.lastName}</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder={sendUsMessageData?.lastNamePlaceholder}
                                                                        name="lastName"
                                                                        onKeyUp={() => simpleValidator.current.showMessageFor("lastName")}
                                                                        value={formData.lastName}
                                                                        onChange={(e) => setFormData({
                                                                            ...formData,
                                                                            lastName: e.target.value.replace(/[^a-z]/gi, ''),
                                                                        })} />
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.lastName.length == 0 ? lastName : ""}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label>{sendUsMessageData?.emailAddress}</label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder={sendUsMessageData?.emailAddressPlaceholder}
                                                                        name="email"
                                                                        onKeyUp={() => simpleValidator.current.showMessageFor("email")}
                                                                        value={formData.email}
                                                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.email.length == 0 ? emailerror : ""}</p>
                                                                        <div className='errorMsg'>{simpleValidator.current.message('email', formData.email, 'email', {
                                                                            messages: {
                                                                                required: emailerror,
                                                                                email: sendUsMessageData.emailValidation
                                                                            }
                                                                        })}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-6" onChange={(e) => {
                                                                    HandleInputHide(e);
                                                                }}>
                                                                    <label>{sendUsMessageData?.interestedAs}</label>
                                                                    <select className="form-select" placeholder="Occupation" name="occupation"
                                                                        onChange={(e) => setFormData({
                                                                            ...formData,
                                                                            occupation: e.target.value,
                                                                        })}
                                                                        defaultValue={formData.occupation}>
                                                                        <option value="" disabled selected hidden>{sendUsMessageData?.interestedAsPlaceholder} </option>
                                                                        {dropDown?.map((data) => {
                                                                            return (
                                                                                <option value={data.label}>{data.label}</option>);
                                                                        })}
                                                                    </select>
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.occupation === "" ? occupationslected : ""}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    {countries.length > 0 ? (
                                                                        <>
                                                                            <label>{sendUsMessageData?.country}</label>
                                                                            <select
                                                                                className="form-select scroller"
                                                                                placeholder={sendUsMessageData?.countryPlaceholder}
                                                                                name="country"
                                                                                onChange={handleChangeCountry}
                                                                                defaultValue={formData.country_name}>
                                                                                <option value="" hidden> {sendUsMessageData?.countryPlaceholder}
                                                                                </option>
                                                                                {countries.length > 0 &&
                                                                                    countries.map((row, index) => (
                                                                                        <option value={row.country_name} key={index}>
                                                                                            {row.country_name}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                        </>
                                                                    ) : null}
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.country.length == "" ? countryselect : ""}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <label>{sendUsMessageData?.mobileNumber} </label>
                                                                    <div className="input-group">
                                                                        <span className="input-group-text" id="basic-addon1">
                                                                            {formData.dial_code ? formData.dial_code :
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                                    className="bi bi-telephone-plus" viewBox="0 0 16 16">
                                                                                    <path
                                                                                        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                                    <path fill-rule="evenodd"
                                                                                        d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                                                </svg>}
                                                                        </span>
                                                                        <input
                                                                            className="form-control"
                                                                            type="text"
                                                                            placeholder={sendUsMessageData?.mobileNumberPlaceholder}
                                                                            name="mobile"
                                                                            value={formData.mobile}
                                                                            onKeyUp={() => simpleValidator.current.showMessageFor("mobile")}
                                                                            onChange={(e) => {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    mobile: e.target.value.replace(/\D/g, "")
                                                                                })
                                                                                if (formData?.country) {
                                                                                    checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""))
                                                                                }
                                                                            }
                                                                            } />
                                                                    </div>
                                                                    <div className="text-danger formErrorMsg"></div>
                                                                    <div className='validationError'>
                                                                        <p className='errorMsg'>{formData.mobile.length == "" ||
                                                                            mobileerror ? mobileerror : ""}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {showInput == dropDown[0]?.value && (
                                                                <div className="row">
                                                                    <div className="col mb-3">
                                                                        {projects.length > 0 ? (
                                                                            <>
                                                                                <label>{sendUsMessageData?.project} </label>
                                                                                <select
                                                                                    className="contactComponent form-select"
                                                                                    placeholder={sendUsMessageData?.projectPlaceholder}
                                                                                    name="country"
                                                                                    onChange={(e) => setFormData({
                                                                                        ...formData,
                                                                                        projectinterest: e.target.value,
                                                                                    })}
                                                                                >
                                                                                    <option value="" disabled selected hidden> {sendUsMessageData?.projectPlaceholder}
                                                                                    </option>
                                                                                    {projects.length > 0 &&
                                                                                        projects.map((row, index) => (
                                                                                            <option
                                                                                                value={row.value}
                                                                                                key={index}> {row.label}
                                                                                            </option>
                                                                                        ))}
                                                                                </select>
                                                                                <div className='validationError'>
                                                                                    <p className='errorMsg'>{formData.projectinterest == "" ? projectinterest : ""}</p>
                                                                                </div>
                                                                            </>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="col">
                                                                <label>{sendUsMessageData?.message} </label>
                                                                <textarea
                                                                    className="form-control"
                                                                    placeholder={sendUsMessageData?.messagePlaceholder}
                                                                    value={formData.message}
                                                                    name="message"
                                                                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {loading ? (
                                                            <button type="button" disabled>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true" />
                                                                {" "}
                                                                {sendUsMessageData?.submitButton}
                                                            </button>
                                                        ) : (
                                                            <button type="submit">{sendUsMessageData?.buttonName} </button>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                            : null
                                        }
                                        <div className="col-md-6">
                                            {contactData && Object.keys(contactData).length && contactData["How Can We Help?"] ?
                                                <div className="contactFormHeadQurter">
                                                    <div className="">
                                                        <h3>
                                                            <span className="underLineClass">{contactData["How Can We Help?"][0]?.heading}</span>
                                                        </h3>
                                                        <p>{contactData["How Can We Help?"][0]?.content}</p>
                                                    </div>
                                                    <div className="bookDemoBox" onClick={showBookDemo}>
                                                        <div className="bookContactFormModalBox">
                                                            <h5>{contactData["How Can We Help?"][1]?.title}</h5>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.401" height="14.802" viewBox="0 0 8.401 14.802">
                                                                <path id="Path_52" data-name="Path 52" d="M0,0,5.987,5.987,0,11.974" transform="translate(1.414 1.414)" fill="none" stroke="#262626" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" opacity="1" />
                                                            </svg>
                                                        </div>
                                                        <div className="contactModalBox">
                                                            <p>{contactData["How Can We Help?"][1]?.content}</p>
                                                        </div>
                                                    </div>
                                                    <div className="bookDemoBox" variant="primary" onClick={showContactEnquiryModal}>
                                                        <div className="bookContactFormModalBox">
                                                            <h5>{contactData["How Can We Help?"][2]?.title}</h5>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.401" height="14.802" viewBox="0 0 8.401 14.802">
                                                                <path id="Path_52" data-name="Path 52" d="M0,0,5.987,5.987,0,11.974" transform="translate(1.414 1.414)" fill="none" stroke="#262626" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" opacity="1" />
                                                            </svg>
                                                        </div>
                                                        <div className="contactModalBox">
                                                            <p>{contactData["How Can We Help?"][2]?.content} </p>
                                                        </div>
                                                    </div>
                                                    <div className="bookDemoBox" variant="primary" onClick={showBecomeOurPartnerModal}>
                                                        <div className="bookContactFormModalBox">
                                                            <h5>{contactData["How Can We Help?"][3]?.title}</h5>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.401" height="14.802" viewBox="0 0 8.401 14.802">
                                                                <path id="Path_52" data-name="Path 52" d="M0,0,5.987,5.987,0,11.974" transform="translate(1.414 1.414)" fill="none" stroke="#262626" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" opacity="1" />
                                                            </svg>
                                                        </div>
                                                        <div className="contactModalBox">
                                                            <p>{contactData["How Can We Help?"][3]?.content} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ourOfficeSection" id="contactOurOffices">
                            {ourOfficesData && ourOfficesData.length ?
                                <div className="container">
                                    <h3 className="margin-top: 10px">
                                        <span className="underLineClass">
                                            {ourOfficesData[0]?.heading}
                                        </span>
                                    </h3>
                                    <div className="row">
                                        {ourOfficesData?.map((row, index) => {
                                            return (
                                                <>
                                                    <div className="col-lg-3 col-md-6 mb-lg-0 mt-md-3 mb-5" key={index}>
                                                        <div className="officeAddress">
                                                            <div>
                                                                <img src={publicPath + "/assets/images/footerSocialIcon/call.svg"} />
                                                                <p><a className="addressLink" href={`tel:${row.phoneNumber}`}>{row.phoneNumber}</a></p>
                                                                {/* <p>{row.second_number}</p> */}
                                                            </div>
                                                            <div className="pi">
                                                                <img src={publicPath + "/assets/images/email_logo.svg"} />
                                                                {index === 1 ? <p><a className="addressLink" href={row.location} target="_blank">{row.officeAddress}</a></p> :
                                                                    <p><a className="addressLink" href={row.location} target="_blank">{row.officeAddress}</a></p>}
                                                            </div>
                                                            <div className="mt-3">
                                                                <img src={publicPath + "/assets/images/addres_logo.svg"} />
                                                                <p><a className="addressLink" href={"mailto:info@cubedots.com"}>{row.email}</a></p>
                                                            </div>
                                                            <div className="officeFlag">
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.flagImage}`} className="img-fluid" />
                                                                <h4>{row.countryName}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <EnrollmentModalComponent
                        isOpen={isBecomeOurPartnerModalShow}
                        onRequestClose={closeBecomeOurPartnerModalModal}
                        event="partnerleadForm"
                        insiderEventName="become-our-partner" /><ContactEnrollmentModalComponent
                        isOpen={isContactEnquiryModalShow}
                        onRequestClose={closeContactEnquiryModal}
                        event="contact-usleadForm"
                        insiderEventName="enquiry-about-project-contact-us" /><BookDemoComponent
                        isOpen={isBookDemo}
                        onRequestClose={closeBookDemo}
                        event="contactsleadForm"
                        insiderEventName="create-appointment" /><div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog bookDemoModal">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    <div className="bookDemoContentSection">
                                        <div className="row justify-content-between">
                                            <div className="col-md-3">
                                                <div>
                                                    <img src={publicPath + "/assets/images/cubedotslogo/new-logo.svg"} />
                                                    <h5>Book a Demo</h5>
                                                    <h6>30 Minute Meeting</h6>
                                                    <p>30 Min</p>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="bookDemoCalenderBox">
                                                    <h2>Select a Date & Time</h2>
                                                    <Calendar onChange={onChange} value={value} />
                                                    <div className="timePicker">
                                                        <div className="indiaStatndarTimeaHeading">
                                                            <p>India Standard Time</p>
                                                        </div>
                                                        <TimePicker
                                                            showSecond={false}
                                                            defaultValue={now}
                                                            className="xxx"
                                                            onChange={onChangeTime}
                                                            format={format}
                                                            use12Hours
                                                            inputReadOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isSuccessModalShow ? <SuccessfulPopupComponent isModalShow={isSuccessModalShow} onClose={() => setIsSuccessModalShow(false)} submitMessageData={sendUsMessageData} /> : null}
                </>
                : <Spin className="page-spin-loading" />}
        </>

    )
}
export default ContactPage;