import React, { useEffect } from 'react';
import { useApp } from '../../hooks/AppHooks';

function DeclarationExplict() {
    const { termsValue } = useApp();

    const [declarationData, setDeclarationData] = React.useState();

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (termsValue["Processing of Personal Data via Website/Mobile"]
            && termsValue["Processing of Personal Data via Website/Mobile"]?.length) {
            setDeclarationData(termsValue["Processing of Personal Data via Website/Mobile"]);
        }
    }, [termsValue]);

    return (
        <>
            {declarationData ?
                <div className="declarationSection">
                    <h2 className="declarationHeading vegonHeading">{declarationData[0]?.paragraph}</h2>
                    <p className="declarationParagraph" dangerouslySetInnerHTML={{ __html: `${declarationData[0].content ? declarationData[0].content : null}` }} />
                </div> : null}
        </>
    )
}
export default DeclarationExplict;