import React, { useState, useEffect, useRef } from "react";

import axios from "axios";
import { Spinner } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';

import { useApp } from "../../hooks/AppHooks";
import SuccessfulPopupComponent from "../SuccessfulPopupComponent";
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../../common/PhoneNumberValidation";
import { CMS_PORTAL_BUCKET, insiderEvent, WEBSITE_X_API_KEY, CMS_API_ENDPOINT, ORGZIT_CALL_PERMISSION, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../../common/Constants";


function FormSection() {
    const { aboutUsValue, getInTouchValue, unsoldProjectValue, countries, languageId, language, languageValue } = useApp();

    const simpleValidator = useRef(new SimpleReactValidator());
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    const [lastName, setLastName] = useState("");
    const [showInput, setShowInput] = useState('');
    const [firstName, setFirstName] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [mobileerror, setMobileError] = useState("");
    const [countryselect, setCountrySelect] = useState("");
    const [projectinterest, setProjectInterest] = useState("");
    const [occupationslected, setOccupationSelected] = useState("");

    const [projects, setProjects] = useState([]);

    const [loading, setLoading] = useState(false);
    const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
    const [formData, setFormData] = useState({ firstName: "", lastName: "", email: '', mobile: '', message: '', projectInterest: '', country: '', occupation: '', dial_code: '', event: "get-in-touch-about-us" });

    useEffect(() => {
        if (getInTouchValue && getInTouchValue.length) {
            fetchProjects();
        }
    }, [unsoldProjectValue])

    async function fetchProjects() {
        if (unsoldProjectValue && unsoldProjectValue.length) {
            setProjects(unsoldProjectValue ? unsoldProjectValue?.map((data) => ({
                "value": data.id,
                "label": data.title
            })) : null)
        }
    }

    const HandleInputHide = (event) => {
        const setUserInput = event.target.value;
        setShowInput(setUserInput);
    };

    const resetFrom = () => {
        setFormData({
            projectInterest: "",
            firstName: "",
            lastName: "",
            email: "",
            country: "",
            dial_code: "",
            mobile: "",
            occupation: "",
            message: "",
            event: "get-in-touch-about-us"
        })
        document.getElementById("form1").reset();
    };
    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios
                .post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/enrollment-request", formData);
            return orgzit;
        }
    };

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const onSuccessForm = () => {
        setFirstName("");
        setLastName("");
        setEmailError("");
        setMobileError("");
        setProjectInterest("");
        setOccupationSelected("");
        setCountrySelect("");
        setLoading(false);
        resetFrom();
        setShowInput('');
        setTimeout(() => {
            setIsSuccessModalShow(true);
        }, 1000);
        if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': "partnerleadForm" });
            insiderEvent("get-in-touch-about-us");
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formData.firstName.length == "" || formData.lastName.length == "" || formData.email.length == "" ||
            formData.occupation.length == "" || formData.country.length == "" || formData.mobile.length == "" ||
            formData.mobile.length < 4 || formData.mobile.length > 20) {
            if (getInTouchValue && getInTouchValue.length) {
                setFirstName(getInTouchValue[0]?.firstNameValidation);
                setLastName(getInTouchValue[0]?.lastNameValidation);
                setEmailError(getInTouchValue[0]?.emailValidation);
                setMobileError(getInTouchValue[0]?.mobileNumberValidation);
                setOccupationSelected(getInTouchValue[0]?.interestedAsValidation);
                setCountrySelect(getInTouchValue[0]?.countryValidation);
            }
            return false;
        }
        if (formData.occupation === getInTouchValue[0]?.interestedAsDropdownValues[0]?.paragraph && formData.projectInterest === "") {
            setProjectInterest(getInTouchValue[0]?.projectValidation);
            return false;
        }
        if (!simpleValidator.current.fields.email) {
            return false;
        }
        setLoading(true);
        const postDataValue = {
            name: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            phoneNumber: formData.dial_code + formData.mobile,
            notes: formData.message,
            source: "Cubedots Portal",
            fromWebsite: true,
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);
        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error('Error while form submitting');
        };
        setLoading(false);
    }

    const handleChangeCountry = (e) => {
        e.persist();
        let selectedCountryName = e.target.value;
        setFormData((formData) => ({ ...formData, country: selectedCountryName }));
        let countriesList = countries;
        let newDialCode = Object.keys(countriesList)
            .filter((x) => {
                return countriesList[x].country_name === selectedCountryName;
            })
            .map((x) => {
                return countriesList[x].dial_code;
            });
        let dial_code = newDialCode.length ? newDialCode[0] : "";
        setFormData((formData) => ({ ...formData, dial_code: dial_code }));
    };

    const checkTheLandlineAndPhoneNumber = (PhoneNumber) => {
        const { dial_code } = formData;
        let validationData = PhoneNumber && dial_code ? `${dial_code}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setMobileError(" ")
            } else {
                setMobileError(getInTouchValue[0]?.mobileNumberValidation)
            }
        }
    }

    return (
        <>
            {aboutUsValue["Life At Cubedots"] && aboutUsValue["Life At Cubedots"]?.length || (getInTouchValue && getInTouchValue.length) ?
                <div className="aboutFormSection" id="life-at-cubedots">
                    <div className="container">
                        {aboutUsValue["Life At Cubedots"] ?
                            <><h3 className="lifeCubedotsHeading SubHeadingBottomMargin">
                                <span className="underLineClass">
                                    {aboutUsValue["Life At Cubedots"][0]?.heading}
                                </span>
                            </h3><div className="lifeOfCubedotsSection">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-6 order-lg-1 order-2 mt-lg-0 mt-sm-5 mt-0">
                                            {aboutUsValue["Life At Cubedots"]?.map((element, index) =>
                                                <p key={index}>{element.content}</p>
                                            )}
                                        </div>
                                        <div className="col-lg-5 order-lg-2 order-1">
                                            <img className="lifeOfCubedotsImage img-fluid" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["Life At Cubedots"][0]?.imageLink}`} />
                                        </div>
                                    </div>
                                </div></> :
                            null}
                        <div className="formSectionAboutus" id="form-section">
                            {getInTouchValue && getInTouchValue?.length ?
                                <><h3 className="SubHeadingBottomMargin">
                                    <span className="underLineClass">
                                        {getInTouchValue[0].heading}
                                    </span>
                                </h3><div className="row justify-content-between">
                                        <div className="col-lg-6 order-lg-1 order-2 mt-md-0  mt-2">
                                            <div className="formSection">
                                                <form id="form1" onSubmit={onSubmit}>
                                                    <div className="formSectionInputAbout mt-4 mt-md-0  mb-4">
                                                        <div className="row ">
                                                            <div className="col-md-6">
                                                                <label>{getInTouchValue[0]?.firstName}</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={getInTouchValue[0]?.firstNamePlaceholder}
                                                                    name="firstName"
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor("firstName")}
                                                                    value={formData.firstName}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        firstName: e.target.value.replace(/[^a-z]/gi, ''),
                                                                    })} />
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.firstName.length == 0 ? firstName : ""}</text>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-md-0 mt-3">
                                                                <label>{getInTouchValue[0].lastName}</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={getInTouchValue[0].lastNamePlaceholder}
                                                                    name="lastName"
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor("lastName")}
                                                                    value={formData.lastName}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        lastName: e.target.value.replace(/[^a-z]/gi, ''),
                                                                    })} />
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.lastName.length == 0 ? lastName : ""}</text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="formSectionInputAbout mt-0 mt-md-4 mb-4">
                                                        <div className="row">
                                                            <div className="col-md-6 ">
                                                                <label>{getInTouchValue[0].emailAddress}</label>
                                                                <input type="text" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor('email')} className="form-control" placeholder={getInTouchValue[0].emailAddressPlaceholder} />
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.email.length == 0 ? emailerror : ""}</text>

                                                                    <div className='errorMsg'>{simpleValidator.current.message('email', formData.email, 'email', {
                                                                        messages: {
                                                                            required: emailerror,
                                                                            email: getInTouchValue[0]?.emailValidation
                                                                        }
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 mt-md-0 mt-3">
                                                                {countries.length > 0 ? (
                                                                    <>
                                                                        <label>{getInTouchValue[0]?.country}</label>
                                                                        <select
                                                                            className="form-select mt-2"
                                                                            placeholder={getInTouchValue[0]?.countryPlaceholder}
                                                                            name="country"
                                                                            onChange={handleChangeCountry}
                                                                            defaultValue={formData.country}>
                                                                            <option value="" disabled selected hidden> {getInTouchValue[0]?.countryPlaceholder}
                                                                            </option>
                                                                            {countries.length > 0 &&
                                                                                countries.map((row, index) => (
                                                                                    <option value={row.country_name} key={index}>
                                                                                        {row.country_name}
                                                                                    </option>
                                                                                ))}
                                                                        </select>
                                                                    </>
                                                                ) : null}
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.country.length == "" ? countryselect : ""}</text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="formSectionInputAbout mt-0 mt-md-4 mb-4">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label>{getInTouchValue[0].mobileNumber}</label>
                                                                <div className="input-group">
                                                                    <span className="input-group-text mt-2" id="basic-addon1">
                                                                        {formData.dial_code ? formData.dial_code :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                                class="bi bi-telephone-plus" viewBox="0 0 16 16">
                                                                                <path
                                                                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                                <path fill-rule="evenodd"
                                                                                    d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                                            </svg>}
                                                                    </span>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder={getInTouchValue[0]?.mobileNumberPlaceholder}
                                                                        name="mobile"
                                                                        value={formData.mobile}
                                                                        onKeyUp={() => simpleValidator.current.showMessageFor("mobile")}
                                                                        onChange={(e) => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                mobile: e.target.value.replace(/\D/g, "")
                                                                            })
                                                                            if (formData?.country) {
                                                                                checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""))
                                                                            }
                                                                        }
                                                                        } />
                                                                </div>
                                                                <div className="text-danger formErrorMsg"></div>
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.mobile.length == "" ||
                                                                        mobileerror ? mobileerror : ""}</text>
                                                                </div>
                                                            </div>
                                                            <div className=" col-md-6 mt-md-0 mt-3" onChange={(e) => {
                                                                HandleInputHide(e);
                                                            }}>
                                                                <label>{getInTouchValue[0]?.interestedAs}</label>
                                                                <select className="form-select mt-2" placeholder="Occupation" name="occupation"
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        occupation: e.target.value,
                                                                    })}
                                                                    defaultValue={formData.occupation}>
                                                                    <option value="" disabled selected hidden>{getInTouchValue[0]?.interestedAsPlaceholder}</option>
                                                                    {getInTouchValue[0]?.interestedAsDropdownValues &&
                                                                        getInTouchValue[0]?.interestedAsDropdownValues.length &&
                                                                        getInTouchValue[0]?.interestedAsDropdownValues.map((data) => {
                                                                            return (
                                                                                <option value={data.paragraph}>{data.paragraph}</option>
                                                                            )
                                                                        })}
                                                                </select>
                                                                <div className='validationError'>
                                                                    <text className='errorMsg'>{formData.occupation === "" ? occupationslected : ""}</text>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="formSectionInputAbout mt-0 mt-md-4 mb-4">
                                                        {showInput == getInTouchValue[0]?.interestedAsDropdownValues[0]?.paragraph ?
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    {projects.length > 0 ? (
                                                                        <>
                                                                            <label>{getInTouchValue[0]?.project}</label>
                                                                            <select
                                                                                className="contactComponent form-select mt-2"
                                                                                placeholder={getInTouchValue[0]?.projectPlaceholder}
                                                                                name="country"
                                                                                onChange={(e) => setFormData({
                                                                                    ...formData,
                                                                                    projectInterest: e.target.value,
                                                                                })}
                                                                            >
                                                                                <option value="" disabled selected hidden>
                                                                                    {getInTouchValue[0]?.projectPlaceholder}
                                                                                </option>
                                                                                {projects.length > 0 &&
                                                                                    projects.map((row, index) => (
                                                                                        <option
                                                                                            value={row.value}
                                                                                            key={index}> {row.label}
                                                                                        </option>
                                                                                    ))}
                                                                            </select>
                                                                            <div className='validationError'>
                                                                                <text className='errorMsg'>{formData.projectInterest == "" ? projectinterest : ""}</text>
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </div> : null}
                                                    </div>
                                                    <div className="formSectionInputAbout mt-0 mt-md-4 mb-4">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <label>{getInTouchValue[0]?.message}</label>
                                                                <textarea type="text" className="form-control textArea" placeholder={getInTouchValue[0]?.messagePlaceholder} value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="formSectionInputAbout mt-0 mt-md-4 mb-4">
                                                        <div className="row ">
                                                            <div className="col text-end">
                                                                {loading ? (
                                                                    <button type="button" disabled>
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            size="sm"
                                                                            role="status"
                                                                            aria-hidden="true" />
                                                                        {" "}
                                                                        {getInTouchValue[0]?.submitButton}
                                                                    </button>
                                                                ) : (
                                                                    <button type="submit">{getInTouchValue[0]?.buttonName}</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 text-lg-end text-center order-lg-2 order-1">
                                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${getInTouchValue[0].imageLink}`} className="img-fluid contactImage" />
                                        </div>
                                    </div></>
                                : null
                            }
                        </div>
                    </div>
                </div>
                : null
            }
            {isSuccessModalShow ? <SuccessfulPopupComponent isModalShow={isSuccessModalShow} onClose={() => setIsSuccessModalShow(false)} submitMessageData={getInTouchValue[0]} /> : null}
        </>
    )
}
export default FormSection;