import React, { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useApp } from "../../../hooks/AppHooks";

function AgentPage() {
    const { homeValue } = useApp();
    const [heightIncrease, setHeightIncrease] = useState(true);
    const [widthIncrease, setWidthIncrease] = useState(true);
    const [zindexClass, setZindexClass] = useState(true);

    const [oracleHeight, setOracleHeight] = useState(true);

    const myRef = useRef(null);
    const langId = location.search.split("=")[1];

    const onClickHandlerWidthFalse = () => {
        // myRef.current.scrollIntoView();
        setTimeout(() => {
            setHeightIncrease(false)
        }, 400)
        setOracleHeight(false)
        setTimeout(() => {
            setZindexClass(false)
        }, 500)
    }

    useEffect(() => {
        setTimeout(() => {
            setWidthIncrease(oracleHeight ? true : false)
        }, 420)
    }, [oracleHeight, homeValue])

    return (
        <>{homeValue && homeValue["For Agent And Developers"] ?
            <div className={'oracleSection'} id="for-agents-and-developers">
                <div className='row p-0 m-0'>
                    <div className='col-md-6 p-0'>
                        <div className={"firstDiv"}>
                            <div className={"firstDiv1"}>
                                <Link to={langId ? `/agents/?lang=${langId}` : `/agents`} >
                                    <button className="forAgents" onClick={onClickHandlerWidthFalse}>{homeValue["For Agent And Developers"] && homeValue["For Agent And Developers"][0]?.buttonName && homeValue["For Agent And Developers"][0]?.buttonName}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 p-0'>
                        <div className={"secondDiv"}>
                            <div className={"firstDiv1"}>
                                <Link to={langId ? `/developer/?lang=${langId}` : `/developer`}>
                                    <button className="forAgents forDevelopers" onClick={onClickHandlerWidthFalse}>{homeValue["For Agent And Developers"] && homeValue["For Agent And Developers"][1]?.buttonName && homeValue["For Agent And Developers"][1]?.buttonName}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={myRef}></div>
            </div> : null}
        </>
    )
}
export default AgentPage;