import React, { useEffect, useState } from "react";
import { Image } from 'antd';
import OurPartners from "../Components/aboutus/OurPartners"
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Marquee from "react-fast-marquee";
import Modal from 'react-modal';
import BookDemoComponent from "../Components/contactPage/BookDemoComponent";



function MipimPage() {
    const [visible, setVisible] = useState(false);
    const [whoWe, setWhoWe] = useState([])

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const [modalIsOpenFirst, setIsOpenIsOpenFirst] = React.useState(false);
    const [modalIsOpenSecond, setIsOpenIsOpenSecond] = React.useState(false);
    const [modalIsOpenThird, setIsOpenIsOpenThird] = React.useState(false);
    const [modalIsOpenFourth, setIsOpenIsOpenFourth] = React.useState(false);
    const [modalIsOpenFive, setIsOpenIsOpenFive] = React.useState(false);
    const [modalIsOpenSix, setIsOpenIsOpenSix] = React.useState(false);
    const [isBookDemo, setIsBookDemo] = useState(false);

    function openModalFirst() {
        setIsOpenIsOpenFirst(true);
    }
    function openModalSecond() {
        setIsOpenIsOpenSecond(true)
    }
    function openModalThird() {
        setIsOpenIsOpenThird(true)
    }
    function openModalFourth() {
        setIsOpenIsOpenFourth(true)
    }
    function openModalFive() {
        setIsOpenIsOpenFive(true)
    }
    function openModalSix() {
        setIsOpenIsOpenSix(true)
    }

    function closeModalFirst() {
        setIsOpenIsOpenFirst(false);
    }
    function closeModalSecond() {
        setIsOpenIsOpenSecond(false)
    }
    function closeModalThird() {
        setIsOpenIsOpenThird(false)
    }
    function closeModalFourth() {
        setIsOpenIsOpenFourth(false)
    }
    function closeModalFive() {
        setIsOpenIsOpenFive(false)
    }
    function closeModalSix() {
        setIsOpenIsOpenSix(false)
    }

    async function fetchsetWhoWeData() {
        try {
            const res = await axios.get("/assets/data/mipim/whoweare.json");
            if (res.data) {
                setWhoWe(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        fetchsetWhoWeData();
    }, []);

    const closeBookDemo = () => {
        document.body.style.overflow = 'auto'
        setIsBookDemo(false);
    }

    return (
        <>
            <div className="mimpm_section">
                <div className="mimpm_banner_section">
                    <Video className="videoPlayer" preload={"auto"} playsInline autoPlay loop muted
                        src={`/assets/images/mpim/video.mp4`} />
                    <div className="banner_content">
                        <div>
                            <div className="banner_heading">
                                <h6>COME SEE US AT STAND P-1.E21</h6>
                                <h6> TO SEE HOW WE CAN REVOLUTIONISE YOUR SALES</h6>
                            </div>
                            <div className="cubedots_image d-flex align-items-center justify-content-center">
                                <img src="/assets/images/cubedotslogo/new-logo.svg" className="img-fluid me-5" height="100px" />
                                <img src="/assets/images/mpim/mipim.png" className="img-fluid" />
                            </div>
                            <div className="text-center d-md-block d-none">
                                <a href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                    {/* <button className="banner_btn " onClick={showBookDemo}> */}
                                    <button className="banner_btn ">
                                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                        </svg> Book a Live Demo
                                    </button>
                                </a>
                            </div>
                            <div className="text-center d-md-none d-block">
                                <button className="banner_btn ">
                                    <a target="_blank"
                                        href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                        <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                        </svg> Book Meeting
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="empowering_section">
                        <div className="row text-center justify-content-center">
                            <div className="col-md-9">
                                <h6>Empowering More</h6>
                                <p>
                                    Cubedots is a leading PropTech company operating in the United Kingdom, Turkey, UAE, and India.
                                </p>
                                <p>We are here at MIPIM to revolutionise and empower real estate sales and marketing! </p>
                                <p>Our leading solutions are an Integrated & interactive experience like no other!</p>
                                <p>Our state of art 3D sales systems, made possible through advanced technology combined with unique proprietary software, empower our client's sales process on every level.</p>
                                <p>We are committed to evolution and innovation; we work with some of the best-in-class developers and partners worldwide.</p>
                                <p>Our ever-growing international network of agents and developers have become empowered as partners by using our technology in this evolutionary shift; we are writing a new chapter in the marketing and selling of real estate.</p>
                                <p>This combination allows us to deliver a supercharged and frictionless sales process, launch projects worldwide and give client's an immersive first impression like never before!</p>
                                <h5>
                                    COME SEE US AT THIS YEAR'S MIPIM PROPEL STATION, STAND P-1. E21<br></br>
                                    book in your appointment with us & DISCOVER HOW WE CAN BRING YOUR PROJECT TO LIFE!
                                </h5>
                            </div>
                        </div>
                        <div className="text-center d-md-block d-none">
                            {/* <button className="book_live_demo_btn" onClick={showBookDemo}> */}
                            <a href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                <button className="book_live_demo_btn">
                                    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                    </svg> Book a Live Demo
                                </button>
                            </a>
                        </div>
                        <div className="text-center d-md-none d-block">
                            <button className="book_live_demo_btn">
                                <a target="_blank"
                                    href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                    </svg> Book Meeting
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className="icons_section">
                        <div className="d-md-block d-none">
                            <div className="row justify-content-center">
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/Integrated 3D Sales Systems.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                Integrated 3D <p> Sales Systems </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/Live Inventory & Pricing.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                Live Inventory <p> & Pricing </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/Interactive Visualisation.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                Interactive Visualisation <p> Solutions
                                                </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/Bespoke Project Applications.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                Bespoke Project <p> Applications </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/Dedicated Agent Sales Portal.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                Dedicated Agent <p> Sales Portal </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <div className="empowring_section text-center">
                                        <img src="/assets/images/mpim/BIM Integration.svg" className="img-fluid" />
                                        <p>
                                            <small>
                                                BIM Integration <p> Services
                                                </p>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none d-block">
                            <Marquee gradientColor={false} speed={50} pauseOnHover={true} >
                                <div className="empowring_section text-center me-3 ms-3">
                                    <img src="/assets/images/mpim/Integrated 3D Sales Systems.svg" className="img-fluid" />
                                    <p>
                                        <small>
                                            Integrated 3D <p> Sales Systems </p>
                                        </small>
                                    </p>
                                </div>
                                <div className="empowring_section text-center me-3 ms-3">
                                    <img src="/assets/images/mpim/Live Inventory & Pricing.svg" className="img-fluid" />
                                    <p>
                                        <small>
                                            Live Inventory <p> & Pricing </p>
                                        </small>
                                    </p>
                                </div>
                                <div className="empowring_section text-center me-3 ms-3">
                                    <img src="/assets/images/mpim/360 Online VR Solutions.svg" className="img-fluid" />
                                    <p>
                                        <small>
                                            360 Online VR <p> Solutions </p>
                                        </small>
                                    </p>
                                </div>
                                <div className="empowring_section text-center me-3 ms-3">
                                    <img src="/assets/images/mpim/Bespoke Project Applications.svg" className="img-fluid" />
                                    <p>
                                        <small>
                                            Bespoke Project <p> Applications </p>
                                        </small>
                                    </p>
                                </div>
                                <div className="empowring_section me-3 ms-3 text-center me-3 ms-3">
                                    <img src="/assets/images/mpim/Dedicated Agent Sales Portal.svg" className="img-fluid" />
                                    <p>
                                        <small>
                                            Dedicated Agent <p> Sales Portal </p>
                                        </small>
                                    </p>
                                </div>
                                {/* </div>
                        </div> */}
                                {/* </Carousel> */}
                            </Marquee>
                        </div>
                    </div>
                    <div className="our_client_section">
                        <h6>OUR CLIENTS</h6>
                        <OurPartners />
                    </div>
                    <div className="who_we_are_section">
                        <div className="row">
                            <div className="col">
                                <div className="who_heading">
                                    <h6>WHO WE ARE</h6>
                                </div>
                            </div>
                            <div className="who_we_are_card">
                                <div className="row justify-content-end">
                                    <div className="col-md-11">
                                        <div className="d-md-block d-none">
                                            <div className="row">
                                                {
                                                    whoWe.map((row, index) => {
                                                        return (
                                                            <>
                                                                <div className="col-lg-4 col-md-6">
                                                                    <div className="who_we_are_card_content">
                                                                        <img src={row.image} />
                                                                        <h6>{row.name}</h6>
                                                                        <small>{row.position}</small>
                                                                        <p>{row.contact_number}</p>
                                                                        <p className="email_id">{row.email}</p>
                                                                        <a href={row.linkedin_link}
                                                                            target="_blank">
                                                                            <img src="/assets/images/mpim/Linkedin Icon.png" className="img-fluid" />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-md-none d-block"> */}
                                    <div id="carouselExampleControls" className="carousel d-md-none d-block slide text-center" data-bs-ride="carousel">
                                        <div className="carousel-inner">
                                            {
                                                whoWe.map((row, index) => {
                                                    return (
                                                        <>
                                                            <div className={index === 0 ? 'carousel-item active' : 'carousel-item'} key={index}>
                                                                <div className="who_we_are_card_content">
                                                                    <img className="card_image" src={row.image} />
                                                                    <h6>{row.name}</h6>
                                                                    <small>{row.position}</small>
                                                                    <p>{row.contact_number}</p>
                                                                    <p className="email_id">{row.email}</p>
                                                                    <a href={row.linkedin_link}
                                                                        target="_blank">
                                                                        <img src="/assets/images/mpim/Linkedin Icon.png" className="img-fluid" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            {/* </div> */}
                                        </div>
                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            {/* <span className="visually-hidden">Previous</span> */}
                                        </button>
                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            {/* <span className="visually-hidden">Next</span> */}
                                        </button>
                                    </div>
                                </div>
                                <div className="text-center d-md-block d-none">
                                    {/* <button className="book_live_demo_btn" onClick={showBookDemo}> */}
                                    <a href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                        <button className="book_live_demo_btn">
                                            <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                            </svg>
                                            Book a Live Demo
                                        </button>
                                    </a>
                                </div>
                                <div className="text-center d-md-none d-block">
                                    <button className="book_live_demo_btn">
                                        <a target="_blank"
                                            href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                            <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                            </svg>
                                            Book Meeting
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image_section">
                    {/* <Image.PreviewGroup
                        preview={{
                            visible,
                            onVisibleChange: (vis) => setVisible(vis),
                        }}
                    > */}
                    <div className="row p-0 m-0">
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalFirst}>
                                <img src="/assets/images/mpim/Prorject1.jpg" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <h6>Qatari Diar</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalSecond}>
                                <img src="/assets/images/mpim/Prorject2.jpg" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <h6>Tatweer Misr</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalThird}>
                                <img src="/assets/images/mpim/Prorject3.png" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <div className="text-center">
                                        <h6>Battersea</h6> <h6> Power Station</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalFourth}>
                                <img src="/assets/images/mpim/Prorject4.jpg" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <h6>DAP Yapı</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalFive}>
                                <img src="/assets/images/mpim/Prorject5.jpg" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <h6>Tekfen</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 p-0">
                            <div className="image_section_card" onClick={openModalSix}>
                                <img src="/assets/images/mpim/Prorject6.jpg" className="img-fluid" />
                                <div className="image_section_overlay">
                                    <h6>Palm Hills</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Image.PreviewGroup> */}
                    <div className="container">
                        <div className="text-center d-md-block d-none">
                            <a href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                {/* <button className="book_live_demo_btn" onClick={showBookDemo}> */}
                                <button className="book_live_demo_btn">
                                    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                    </svg> Book a Live Demo
                                </button>
                            </a>
                        </div>
                        <div className="text-center d-md-none d-block">
                            <button className="book_live_demo_btn">
                                <a target="_blank"
                                    href="https://calendly.com/christopher-scriven/mipim-2023-cubedots-live-demo">
                                    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56ZM20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V10.84C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.11 18.3 9 18.37 8.88 18.42C8.76 18.47 8.63 18.5 8.5 18.5C8.37 18.5 8.24 18.47 8.12 18.42C8 18.37 7.89 18.3 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 8 16.63 8.12 16.58C8.36346 16.48 8.63654 16.48 8.88 16.58C9 16.63 9.11 16.7 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.42 14.38C9.37 14.5 9.3 14.61 9.21 14.71C9.11 14.8 9 14.87 8.88 14.92C8.76 14.97 8.63 15 8.5 15C8.37 15 8.24 14.97 8.12 14.92C8 14.87 7.89 14.8 7.79 14.71C7.7 14.61 7.63 14.5 7.58 14.38C7.52863 14.2598 7.50145 14.1307 7.5 14C7.5 13.87 7.53 13.74 7.58 13.62C7.63 13.5 7.7 13.39 7.79 13.29C7.89 13.2 8 13.13 8.12 13.08C8.36346 12.98 8.63654 12.98 8.88 13.08C9 13.13 9.11 13.2 9.21 13.29C9.3 13.39 9.37 13.5 9.42 13.62C9.47 13.74 9.5 13.87 9.5 14C9.5 14.13 9.47 14.26 9.42 14.38ZM12.71 14.71C12.61 14.8 12.5 14.87 12.38 14.92C12.26 14.97 12.13 15 12 15C11.87 15 11.74 14.97 11.62 14.92C11.5 14.87 11.39 14.8 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.39 13.2 11.5 13.13 11.62 13.08C11.86 12.97 12.14 12.97 12.38 13.08C12.5 13.13 12.61 13.2 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z" fill="white" />
                                    </svg> Book Meeting
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={modalIsOpenFirst}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalFirst}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/QatariDiar/1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/QatariDiar/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/QatariDiar/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <Modal
                isOpen={modalIsOpenSecond}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalSecond}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/BloomField/1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/BloomField/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/BloomField/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <Modal
                isOpen={modalIsOpenThird}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalThird}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Battersea/1.png"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Battersea/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Battersea/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <Modal
                isOpen={modalIsOpenFourth}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalFourth}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Nişantaşı/1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Nişantaşı/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Nişantaşı/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <Modal
                isOpen={modalIsOpenFive}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalFive}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Tekfen/1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Tekfen/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/Tekfen/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <Modal
                isOpen={modalIsOpenSix}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModalSix}
                // style={customStyles}
                contentLabel="Example Modal"
                className="mpModal"
            >
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/PHNC/1.jpg"
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/PHNC/2.jpg"
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="/assets/images/mpim/Portfolio images/PHNC/3.jpg"
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Modal>
            <BookDemoComponent
                isOpen={isBookDemo}
                onRequestClose={closeBookDemo}
                event="contactsleadForm"
                insiderEventName="create-appointment"
                showOccupation={false}
                europeanAppointmentTime={true}
                source="MIPIM 2023"
            />
        </>
    )
}
export default MipimPage;