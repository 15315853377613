import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";


function PortalFeature() {
    const { agentsValue } = useApp();
    const publicPath = process.env.PUBLIC_URL;
    const [benefitList, setBenefitList] = useState();
    $(document).ready(function () {
        let lastScrollTop = 0;
        $(window).scroll(function () {
            const windowWidth = $(window).width();
            if (windowWidth > 769) {
                const windowTop = $(window).scrollTop();
                const windowBottom = $(window).scrollTop() + $(window).height();

                const scrollSterTop = $("#text-image-scroll").position().top - 60;
                const scrollSterBottom =
                    $("#text-image-scroll").position().top +
                    $("#text-image-scroll").outerHeight(true);
                const textWrapperHeight = $("#text-wrapper").height();

                if (windowTop > scrollSterTop) {
                    $("#images-wrapper").addClass("images-wrapper-fixed");
                } else {
                    $("#images-wrapper").removeClass("images-wrapper-fixed");
                }

                if (windowBottom > scrollSterBottom) {
                    $("#images-wrapper").removeClass("images-wrapper-fixed");
                    $("#images-wrapper").addClass("images-wrapper-fixed-stop");
                } else {
                    $("#images-wrapper").removeClass("images-wrapper-fixed-stop");
                }

                if (
                    $("#images-wrapper").hasClass("images-wrapper-fixed") &&
                    windowBottom < scrollSterBottom - 100
                ) {
                    const imageWrapperWidth = $("#images-wrapper").width() * benefitList?.length;
                    const scrolled = windowTop - scrollSterTop;
                    const percScrolledOfScrollSter = scrolled / textWrapperHeight;
                    const pxOfImageWrapper =
                        imageWrapperWidth * percScrolledOfScrollSter;
                    const minus = Math.abs(pxOfImageWrapper) * -1;
                    $(".images").css("right", minus);
                }
                lastScrollTop = windowTop;
            }
        });
    });
    useEffect(() => {
        if (agentsValue["Benefits Of Working With Cubedots"]?.length) {
            setBenefitList(agentsValue["Benefits Of Working With Cubedots"])
        }
    }, [agentsValue["Benefits Of Working With Cubedots"]])
    return (
        <>
            {agentsValue["Benefits Of Working With Cubedots"]?.length && benefitList ?
                <>
                    <div className="portalFeatureSection d-xl-block d-none" id="benefits-of-working-with-cubedots">
                        <div className="container">
                            <h3 className="SubHeadingBottomMargin">
                                <span className="underLineClass">
                                    {benefitList ? benefitList[0]?.heading : null}
                                </span>
                            </h3>
                            <div id="text-image-scroll" className="text-image-scroll-viewer">
                                <div id="text-wrapper" className="text-wrapper">
                                    {
                                        benefitList && benefitList?.map((row, index) => {
                                            return (
                                                <div id={row.title} key={index} className="slide-text-container">
                                                    <div className="hero-content">
                                                        <h5 className="main-heading">{row.title}</h5>
                                                        <p>{row.content}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div id="images-wrapper" className="images-wrapper">
                                    {
                                        benefitList && benefitList?.map((row, index) => {
                                            return (
                                                <div id={`center-image${index + 1}`} className="images">
                                                    <img className="ios_devices" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.imageLink}`} alt={row.title} />
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portalFeatureSection d-xl-none d-block">
                        <div className="container">
                            <h3 className="SubHeadingBottomMargin">
                                <span className="underLineClass">
                                    {benefitList[0].heading}
                                </span>
                            </h3>
                            <div className="row justify-content-evenly align-items-center">
                                <div className="col-md-10">
                                    {
                                        benefitList.map((row, index) => {
                                            return index % 2 === 0 ?
                                                <>
                                                    <div key={index} className="row devTrustContentImage justify-content-between align-items-center">
                                                        <div className='col-md-5 '>
                                                            <div className="portalFeatureFreeContent">
                                                                <h5>{row.title}</h5>
                                                                <p>{row.content}</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5'>
                                                            <div className="portalFetatureImage">
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.imageLink}`} className="img-fluid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div key={index} className="row devTrustContentImage justify-content-between align-items-center">
                                                        <div className='col-md-5 order-md-1 order-2'>
                                                            <div className="portalFetatureImage">
                                                                <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.imageLink}`} className="img-fluid" />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-5 order-md-2 order-1'>
                                                            <div className="portalFeatureFreeContent">
                                                                <h5>{row.title}</h5>
                                                                <p>{row.content}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </> : null}
        </>
    )
}

export default PortalFeature;