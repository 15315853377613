import React from "react";

const Header = () => {
    return (
        <>
            <div className="headerSection">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-md-3 col-6">
                            <img src="/assets/images/cityscape/logo_cubedots.png" className="img-fluid" />
                        </div>
                        <div className="col-md-3 col-6 text-end">
                            <img src="/assets/images/cityscape/city_logo.png" className="img-fluid scope" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;