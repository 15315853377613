import React, { useEffect, useState } from "react";

function SuccessfulPopupComponent(props) {
    const [submitMessage, setSubmitMessage] = useState([]);
    const [submitButtonName, setSubmitButtonName] = useState([]);
    useEffect(() => {
        if (props.isModalShow) {
            $("#success-form-modal").modal("show");
        }
        setSubmitMessage(props?.submitMessageData?.submitMessage[0])
        setSubmitButtonName(props?.submitMessageData?.successButtonName)
    }, [props.isModalShow])
    const closeModal = () => {
        $("#success-form-modal").modal("hide");
        props.onClose();
        props.setAgentSuccessMessage("");
        props.setThanksSuccessMessage("");
        window.insider_object = undefined;
    }
    return (
        <>
            <div className="modal fade success-form-modal" id="success-form-modal" tabIndex="-1" aria-labelledby="success-form-modal" aria-hidden="true" onClick={closeModal} >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#EA611D"
                                className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </p>
                        <div className="modal-body">
                            <h6>{props.agentSuccessMessage ? props.agentSuccessMessage : submitMessage?.SuccessContentFirst}</h6>
                            <h3>{submitMessage?.SuccessContentSecond}</h3>
                            <h6>{submitMessage?.SuccessContentThird}</h6>
                            <button type="button" className="okBtn" data-bs-dismiss="modal" onClick={closeModal}>{submitButtonName}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SuccessfulPopupComponent;