import React, { useEffect, useState } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useApp } from '../hooks/AppHooks';
import { CMS_PORTAL_BUCKET } from '../common/Constants';
// import CurrencyFormat from 'react-currency-format';
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import CharactorLimitContent from "../Components/CharactorLimitContent";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import { Spin } from "antd";

function ProjectsPage(props) {
    const { id } = useParams();
    const location = useLocation();
    const { projectValue, unsoldProjectValue, soldProjectValue, headerValue } = useApp()
    const loc = location.search.split("=");

    const [eventName, setEventName] = useState();
    const [selectedProject, setSelectedProject] = useState(props.isOpenEnquireForProject ? true : false);
    const [isCurrent, setIsCurrent] = useState(true);
    const [isPrevious, setIsPrevious] = useState(false);
    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(props.isOpenEnquireForProject ? true : false);

    useEffect(() => {
        if ((projectValue && Object.keys(projectValue).length) ||
            (unsoldProjectValue && Object.keys(unsoldProjectValue).length)) {
            if (id) {
                let anchor = document.createElement('a');
                anchor.href = `#${id}`;
                anchor.click();
            }
        }

    }, [location, id, projectValue, unsoldProjectValue]);
    const showBecomeOurPartnerModal = (project, eventName) => {
        document.body.style.overflow = '';
        setSelectedProject(project);
        setEventName(eventName);
        setIsBecomeOurPartnerModalShow(true);
    }
    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto';
        setSelectedProject(null);
        setIsBecomeOurPartnerModalShow(false);
    }
    const onClickCurrentBtn = () => {
        setIsCurrent(true);
        setIsPrevious(false);
    }

    const onClickPreviousBtn = () => {
        setIsCurrent(false);
        setIsPrevious(true);
    }

    function listingCurrentProjects() {
        return (
            <> {unsoldProjectValue && headerValue ?
                <div className='projectMainListSection'>
                    <div className="container">
                        {
                            unsoldProjectValue?.length ? <>{unsoldProjectValue?.map((row, index) => {
                                return index % 2 == 0 ?
                                    <div className='projectContent' key={row.id}>
                                        <div className="row justify-content-between align-items-center" id="current-previous-description" >
                                            <div className='col-md-6 order-md-1 order-2'>
                                                <div className='projectContentSection' >
                                                    {row.saleStatus && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                        <h4>{row.title}</h4>
                                                    </Link>
                                                    }
                                                    <small className='d-flex priceBox'>
                                                        {projectValue?.Projects && projectValue.Projects?.map((val) => {
                                                            return (
                                                                (row.minPrice && row.maxPrice) ?
                                                                    <>
                                                                        <h6>
                                                                            {val?.priceRange[0]?.min} <CurrencyFormat value={row.minPrice} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} />
                                                                            - {val?.priceRange[0]?.max}  <CurrencyFormat value={row.maxPrice} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} />
                                                                        </h6>
                                                                    </> : null
                                                            )
                                                        })}
                                                    </small>
                                                    <p className='projectLocation'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f8400c" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                        </svg>
                                                        {row.address}, {row.city}, {row.country}</p>
                                                    <p><CharactorLimitContent isShowbutton={false} content={row.mediumDescription} limit={220} /></p>
                                                    {projectValue?.Projects && projectValue.Projects.length ?
                                                        <>
                                                            <div className='progressBarBox'>
                                                                <small className='d-flex statusBox'>
                                                                    <h5>{!Number(row.deliveryDate?.split(" ")[1]) ? row.deliveryDate : projectValue.Projects && projectValue.Projects[0]?.deliveryDate + ": " + row.deliveryDate}</h5>
                                                                </small>
                                                            </div><button disabled={row.saleSataus ? true : false} onClick={() => showBecomeOurPartnerModal(row.title, `enquiry-for-${row.slug}`)}>{projectValue.Projects && projectValue.Projects[0]?.contactUs}</button>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-5 order-md-2 order-1'>
                                                {row.projectMedia && row.projectMedia.length ?
                                                    <div className='projectsImageSection'>
                                                        <div className="row">
                                                            {row.saleStatus && row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row?.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                                <div className='col-md-12'>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path}`}//fileS3Path
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        wrapperClassName="mainBox"
                                                                        alt="" />
                                                                </div>
                                                            </Link>
                                                            }
                                                        </div>
                                                        {row.saleStatus && row.projectMedia && row.projectMedia[row.projectMedia.length - 2]?.fileS3Path && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                            <div className='row'>
                                                                <div className='col-6 pe-0'>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 2]?.fileS3Path}`}
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        wrapperClassName="mainBox"
                                                                        alt="" />
                                                                </div>
                                                                {row.projectMedia[row.projectMedia.length - 3]?.fileS3Path ?
                                                                    <div className='col-6'>
                                                                        <LazyLoadImage
                                                                            src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 3]?.fileS3Path}`}
                                                                            className="img-fluid projectImageRadius"
                                                                            visibleByDefault={false}
                                                                            wrapperClassName="mainBox"
                                                                            alt="" />
                                                                    </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        </Link>
                                                        }
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='projectContent' key={index}>
                                        <div className="row justify-content-between align-items-center">
                                            <div className='col-md-5'>
                                                {row.projectMedia && row.projectMedia.length ?
                                                    < div className='projectsImageSection'>
                                                        <div className="row">
                                                            {row.saleStatus && row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                                <div className='col-md-12'>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path}`}
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        wrapperClassName="mainBox"
                                                                        alt="" />
                                                                </div>
                                                            </Link>
                                                            }

                                                            <div className='col-6 pe-0'>
                                                                {row.saleStatus && row.projectMedia && row.projectMedia[row.projectMedia.length - 2]?.fileS3Path && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 2]?.fileS3Path}`}
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        alt="" />
                                                                </Link>
                                                                }
                                                            </div>
                                                            <div className='col-6 '>
                                                                {row.saleStatus && row.projectMedia && row.projectMedia[row.projectMedia.length - 3]?.fileS3Path && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 3]?.fileS3Path}`}
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        alt="" />
                                                                </Link>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='projectContentSection'>
                                                    {row.saleStatus && <Link to={loc.length > 1 ? `/projects/${row.slug}?lang=${loc[1]}` : `/projects/${row.slug}`} className={row.saleStatus ? 'disabled-link' : ''} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path }}>
                                                        <h4>{row.title}</h4>
                                                    </Link>
                                                    }
                                                    <small className='d-flex priceBox'>
                                                        {projectValue?.Projects && projectValue.Projects?.map((val) => {
                                                            return (
                                                                (row.minPrice && row.maxPrice) ?
                                                                    <>
                                                                        <h6>
                                                                            {val?.priceRange[0]?.min} <CurrencyFormat value={row.minPrice} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} />
                                                                            - {val?.priceRange[0]?.max}  <CurrencyFormat value={row.maxPrice} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} />
                                                                        </h6>
                                                                    </> : null
                                                            )
                                                        })}
                                                    </small>
                                                    <p className='projectLocation'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f8400c" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                    </svg>{row.address}, {row.city}, {row.country}</p>
                                                    <p><CharactorLimitContent isShowbutton={false} content={row.mediumDescription} limit={220} /></p>
                                                    {projectValue?.Projects && projectValue.Projects.length ?
                                                        <>
                                                            <div className='progressBarBox'>
                                                                <small className='d-flex statusBox'>
                                                                    <h5>{!Number(row.deliveryDate?.split(" ")[1]) ? row.deliveryDate : projectValue?.Projects && projectValue.Projects[0]?.deliveryDate + ": " + row.deliveryDate}</h5>
                                                                </small>
                                                            </div>
                                                            <button disabled={row.saleSataus ? true : false} onClick={() => showBecomeOurPartnerModal(row.title, `enquiry-for-${row.slug}`)}>{projectValue?.Projects && projectValue.Projects[0]?.contactUs}</button>
                                                        </>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            })}</> : null
                        }
                    </div>
                    <EnrollmentModalComponent
                        isOpen={isBecomeOurPartnerModalShow}
                        onRequestClose={closeBecomeOurPartnerModalModal}
                        project={selectedProject}
                        event="contactsleadForm"
                        insiderEventName={eventName}
                    />
                </div >
                : <Spin className="page-spin-loading" />}</>
        )
    }
    function listingPreviousProjects() {
        return (
            <>
                {soldProjectValue && headerValue ?
                    <div className='projectMainListSection'>
                        <div className="container">
                            {
                                soldProjectValue.length ? <>{soldProjectValue?.map((row, index) => {
                                    return index % 2 == 0 ?
                                        <div className='projectContent' key={row.id}>
                                            <div className="row justify-content-between align-items-center">
                                                <div className='col-md-6 order-md-1 order-2'>
                                                    <div className='projectContentSection' id="current-previous-description" >
                                                        <h4>{row.title}</h4>
                                                        <small className='d-flex priceBox'>
                                                            {row.saleStatus == null && <><h6><CurrencyFormat value={row.minPrice ? row.minPrice : 0} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} /> - <CurrencyFormat value={row.minPrice ? row.minPrice : 0} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} /></h6></>}
                                                        </small>
                                                        <p className='projectLocation'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f8400c" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                            </svg>
                                                            {row.address}, {row.city}, {row.country}</p>
                                                        <p><CharactorLimitContent isShowbutton={false} content={row.mediumDescription} limit={220} /></p>
                                                        <div className='progressBarBox'>
                                                            <small className='d-flex statusBox'>
                                                                <h5>{row?.deliveryDate}</h5>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-5 order-md-2 order-1'>
                                                    {row.projectMedia && row.projectMedia.length ?
                                                        <div className='projectsImageSection sold'>
                                                            <div className="row">
                                                                <div className='col-md-12'>
                                                                    {row.projectMedia && row.projectMedia.length && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path ?
                                                                        <LazyLoadImage
                                                                            src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path}`}
                                                                            className="img-fluid projectImageRadius"
                                                                            visibleByDefault={false}
                                                                            wrapperClassName="mainBox"
                                                                            alt="" />
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='projectContent' key={index}>
                                            <div className="row justify-content-between align-items-center">
                                                <div className='col-md-5'>
                                                    <div className='projectsImageSection sold'>
                                                        {row.projectMedia && row.projectMedia.length && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path ?
                                                            <div className="row">
                                                                <div className='col-md-12'>
                                                                    <LazyLoadImage
                                                                        src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/${row.projectMedia && row.projectMedia[row.projectMedia.length - 1]?.fileS3Path}`}
                                                                        className="img-fluid projectImageRadius"
                                                                        visibleByDefault={false}
                                                                        wrapperClassName="mainBox"
                                                                        alt="" />
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='projectContentSection'>
                                                        <h4>{row.title}</h4>
                                                        <small className='d-flex priceBox'>
                                                            {row.saleStatus === null && <><h6><CurrencyFormat value={row.minPrice ? row.minPrice : 0} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} /> - <CurrencyFormat value={row.minPrice ? row.minPrice : 0} displayType={'text'} thousandSeparator={true} prefix={row.currencySymbol ? row.currencySymbol : null} /></h6></>}
                                                        </small>
                                                        <p className='projectLocation'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f8400c" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                        </svg>{row.address}, {row.city}, {row.country}</p>
                                                        <p><CharactorLimitContent isShowbutton={false} content={row.mediumDescription} limit={220} /></p>
                                                        <div className='progressBarBox'>
                                                            <small className='d-flex statusBox'>
                                                                <h5>{row.deliveryDate}</h5>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                })}</> : null
                            }
                        </div>
                    </div>
                    : <Spin className="page-spin-loading" />}</>)
    }

    return (
        <>
            {projectValue && headerValue ?
                <>
                    <BecomeOurPartnerButton />
                    <div className="projectMainSection">
                        <div className="projectImagebox commanBannerImageMobile" id="banner">
                            {projectValue?.Banner ?
                                <Carousel autoPlay infiniteLoop loop showThumbs={false} interval="3000">
                                    {projectValue.Banner[0]?.bannerImages?.map((val) => {
                                        return (
                                            <> {val?.imageLink ?
                                                <div>
                                                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${val?.imageLink}`} className="bannerImage img-fluid" />
                                                </div>
                                                : null}
                                            </>
                                        )
                                    })
                                    }
                                </Carousel> : null
                            }
                            <div className="bannerImageHeading container" >
                                <div className="projectContentImage" id="current-previous-title" >
                                    <h3 className='marginBottom'>{projectValue?.Banner ? projectValue.Banner[0]?.title : null}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='container' >
                            {projectValue["Current & Previous Projects"]?.length ?
                                <nav>
                                    <div class="projectsToogleBtnSection nav-tabs row justify-content-center text-center" id="nav-tab" role="tablist">
                                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" onClick={onClickCurrentBtn}>
                                            {projectValue["Current & Previous Projects"][0]?.currentProjects}
                                        </button>
                                        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={onClickPreviousBtn}>
                                            {projectValue["Current & Previous Projects"][0]?.previousProjects}
                                        </button>
                                    </div>
                                </nav> : null}
                        </div>
                        {isCurrent && !isPrevious && listingCurrentProjects()}
                        {isPrevious && !isCurrent && listingPreviousProjects()}
                    </div>
                </> : <Spin className="page-spin-loading" />}
        </>
    )
}
export default ProjectsPage;