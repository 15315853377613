import React from "react"
import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function AboutUsComponents() {
    const { aboutUsValue } = useApp();
    return (
        <>
            {aboutUsValue["About Us"] && aboutUsValue["About Us"]?.length ?
                <div className="aboutUsContent" id="about-us-content">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-md-6 ">
                                <div className="aboutUsContentBox">
                                    {aboutUsValue["About Us"]?.map((aboutUs) =>
                                        <>
                                            <p>{aboutUs.content}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 text-end">
                                <div className="aboutUsContentImageBox">
                                    <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${aboutUsValue["About Us"][0]?.imageLink}`} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    )
}
export default AboutUsComponents;