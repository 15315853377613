import { Outlet } from "react-router-dom";

// common

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { useApp } from "../hooks/AppHooks";

function PublicLayout() {
    const { language } = useApp();
    return (
        <>
            <div className={"app_public " + language}>
                <Navbar />
                <main style={{ minHeight: 200 }}>
                    <Outlet />
                </main>
                <Footer />
            </div>
        </>
    );
}

export default PublicLayout;