import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import ProjectsComponent from "../Components/agentpage/ProjectsComponent"
import OurPartners from "../Components/aboutus/OurPartners";
import ForDevelopersComponent from "../Components/developers/ForDevelopersComponent"
import WhyDevelopersTrustCaourselComponent from "../Components/home/WhyDevelopersTrustCaourselComponent";
import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import CopyPortalFeatureInfoBeans from "../Components/developers/CopyPortalFeatureInfoBeans"
import { Spin } from "antd";

function DevelopersPage() {
    const { id } = useParams();
    const location = useLocation();
    const { devloperValue, headerValue } = useApp();

    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(false);

    useEffect(() => {
        if (devloperValue && Object.keys(devloperValue)?.length) {
            detDeveloperData();
        }
    }, [location, id, devloperValue]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                window.scrollTo({ top: elem.offsetTop - 100, left: 0, behavior: "smooth" });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location])

    const showBecomeOurPartnerModal = () => {
        document.body.style.overflow = '';
        setIsBecomeOurPartnerModalShow(true);
    }
    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'auto';
        setIsBecomeOurPartnerModalShow(false);
    }

    const detDeveloperData = () => {
        if (id) {
            setTimeout(() => {
                let anchor = document.createElement('a');
                anchor.href = `#${id}`;
                anchor.click();
            },1000)
            
        }
    }
    return (
        <div >
            {devloperValue && Object.keys(devloperValue)?.length && headerValue ?
                <>
                    <BecomeOurPartnerButton />
                    <div className="newPortalDevelopersSection" >
                        {devloperValue.Banner?.length ? <div className="developersPageImage commanBannerImageMobile" id="developer-banner" >
                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${devloperValue.Banner[0]?.backgroundImage}`} className="bannerImage img-fluid" />
                            <div className="bannerImageHeading container">
                                <div className="developersPageContentImage">
                                    <div className="developersPageContent">
                                        <h3 className="marginBottom">{devloperValue.Banner[0]?.title}</h3>
                                        <button onClick={showBecomeOurPartnerModal}>{devloperValue.Banner[0]?.buttonName}</button>
                                    </div>
                                </div>
                            </div>
                        </div> : null}
                        <ForDevelopersComponent />
                        <ProjectsComponent />
                        {devloperValue["Our Partners"] ? <OurPartners ourPartnersData={devloperValue["Our Partners"]} /> : null}
                        <CopyPortalFeatureInfoBeans />
                        {devloperValue["Why Developers Trust Us?"] ? <WhyDevelopersTrustCaourselComponent data={devloperValue["Why Developers Trust Us?"]} /> : null}
                        {devloperValue["Our Agents Network"] && devloperValue["Our Agents Network"][0] ? <div className="ourOffices" id="developer-network">
                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${devloperValue["Our Agents Network"][0]?.backgroundImage}`} className="bannerImage img-fluid" />
                            <div className="bannerImageHeading container">
                                <div className="aboutContentImage">
                                    <h3 className="marginBottom ">
                                        <span className="underLineOurOffices">
                                            {devloperValue["Our Agents Network"][0]?.heading}
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                            : null
                        }
                    </div>
                    <EnrollmentModalComponent
                        isOpen={isBecomeOurPartnerModalShow}
                        onRequestClose={closeBecomeOurPartnerModalModal}
                        event="developersForm"
                        insiderEventName={"get-in-touch-by-developer"} />
                </> : <Spin className="page-spin-loading"/>}
        </div >
    )
}
export default DevelopersPage;