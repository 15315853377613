import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import { Link } from "react-router-dom";
import moment from "moment";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import CharactersLimitComponent from "../Components/CharactorLimitContent";
import { useApp } from "../hooks/AppHooks";
import { Spin } from "antd";

function BlogPage() {
    const { blogDetails, blogBanner, headerValue } = useApp();
    const { id } = useParams();
    const location = useLocation();

    const langId = location.search.split("=")[1];
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        getblogsData();
    }, [location, id]);

    const getblogsData = async () => {
        if (id) {
            let anchor = await document.createElement('a');
            anchor.href = `#${id}`;
            anchor.click();
        }
    };

    return ((blogDetails && blogDetails?.length) || (blogBanner && Object.keys(blogBanner)?.length)) && headerValue ? (
        <>
            <BecomeOurPartnerButton />
            <div className="newPortalBlogSection">
                {blogBanner && blogBanner.Banner?.length ? (
                    <div className="blogImagebox commanBannerImageMobile">
                        <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${blogBanner?.Banner[0]["backgroundImage"]}`} className="bannerImage img-fluid" />
                        <div className="bannerImageHeading container">
                            <div className="bannerImageHeading container">
                                <div className="blogContentImage">
                                    <h3 className="ms-3">{blogBanner.Banner[0].title}</h3>
                                </div>
                            </div>
                        </div>
                    </div>) : null}
                {blogDetails && blogDetails.length ? (
                    <div className="blogContentBox">
                        <div className="container">
                            <div className="row">
                                {
                                    blogDetails.map((row, index) => {
                                        return (
                                            <div className="col-md-6 mt-sm-0 mt-3 col-lg-4" key={index}>
                                                <Link to={langId  ? `/blog/${encodeURIComponent(row.heading)}?lang=${langId}` : `/blog/${encodeURIComponent(row.heading)}`} state={{ id: row.id, image: row.imageLink, content: row.content, heading: row.heading, createdAt: row.createdAt }}>
                                                    <div className="blogContent">
                                                        <div className="blogBannerSmallImage">
                                                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.imageLink}`} className="img-fluid" />
                                                        </div>
                                                        <h3>{row.heading}</h3>
                                                        <h5>{moment(row.createdAt).utc().format('DD MMM YYYY')}</h5>
                                                        {
                                                            row.content ? <p><CharactersLimitComponent isShowbutton={false} content={row.content.replace(/(<([^>]+)>)/ig,'')} limit={60} /></p> : ""
                                                        }
                                                        <a>{row.buttonName}</a>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>) : null}
            </div>
        </>
    ) : <Spin className="page-spin-loading"/>}
export default BlogPage;