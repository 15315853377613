import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom"

import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";

import { CMS_PORTAL_BUCKET } from "../common/Constants";
import { useApp } from '../hooks/AppHooks';
import { Spin } from "antd";


function CookiePolicy() {
    const location = useLocation()
    const { id } = useParams();
    const { cookiePolicyValue, headerValue } = useApp();

    useEffect(() => {
        if (id) {
            let atag = document.createElement("a");
            atag.href = `#${id}`;
            atag.click();
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [id, location, cookiePolicyValue]);


    return (cookiePolicyValue && Object.keys(cookiePolicyValue)?.length) && headerValue ? (
        <>
            <BecomeOurPartnerButton />
            <div className="cookieSection cookiesPage">
                {cookiePolicyValue.Banner ?
                    <div className='cookiePolicyImageSection' id='banners'>
                        <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${cookiePolicyValue?.Banner[0].imageLink}`} className="img-fluid" width={"100%"} />
                        <div className="container">
                            <div className="cookiePolicyHeading">
                                <h3 className="marginBottom">{cookiePolicyValue?.Banner[0]?.heading}</h3>
                            </div>
                        </div>
                    </div> : null}
                <div className='container mt-5 mb-5'>
                    <div className="privacyPolicyTerms ">
                        <div className="">
                            {cookiePolicyValue.Content ?
                                <div className="cookiesContent " id="content">
                                    <h2 >{cookiePolicyValue?.Content[0]?.heading}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: `${cookiePolicyValue?.Content[0]?.content}` }} />
                                </div > : null
                            }
                            <div className="cookieGeneralInfo" id="generalinfromation">
                                <>
                                    {cookiePolicyValue["General Information"] ?
                                        <>
                                            <h2> {cookiePolicyValue["General Information"][0]?.heading}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: `${cookiePolicyValue["General Information"][0]?.content}` }} />
                                        </> : null
                                    }
                                    {
                                        cookiePolicyValue["Our Purpose Of Use with Cookies We Use"] ?
                                            <>
                                                <p style={{ fontWeight: 700 }}>{cookiePolicyValue["Our Purpose Of Use with Cookies We Use"][0]?.heading}</p>
                                                <p dangerouslySetInnerHTML={{ __html: `${cookiePolicyValue["Our Purpose Of Use with Cookies We Use"][0]?.content}` }} /><br />
                                            </> : null
                                    }
                                    {
                                        cookiePolicyValue["Procedure For Removing Cookies"] ?
                                            <>
                                                <p id="removing"> {cookiePolicyValue["Procedure For Removing Cookies"][0]?.heading}</p>
                                                <p className='procedure-cookies' dangerouslySetInnerHTML={{ __html: `${cookiePolicyValue["Procedure For Removing Cookies"][0]?.content}` }} /><br />
                                            </> : null
                                    }
                                </>
                            </div>
                        </div >
                    </div >
                </div >
            </div >
        </>
    ) : <Spin className="page-spin-loading" />
}
export default CookiePolicy;