import { useEffect, useState, useRef } from 'react';

import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import { useApp } from '../../hooks/AppHooks';
import SimpleReactValidator from 'simple-react-validator';
import SuccessfulPopupComponent from "../SuccessfulPopupComponent";
import { insiderEvent, WEBSITE_X_API_KEY, CMS_API_ENDPOINT, ORGZIT_CALL_PERMISSION, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../../common/Constants";
import { useLocation } from 'react-router-dom';

function EnquireAboutThisPropertyComponent() {
    const { enquireAboutValue, specificProjectValue, countries, languageValue, language } = useApp();
    const simpleValidator = useRef(new SimpleReactValidator());
    const location = useLocation();
    const formColumns = {
        projectInterest: '', firstName: '', lastName: '',
        occupation: '', email: '', country: '', dial_code: '',
        mobile: '', message: '', appointmentDate: '', appointmentTime: '', terms: false, enquiryProperty: true,
        event: `enquiry-for-${specificProjectValue && specificProjectValue?.slug}`
    };

    const [lastname, setLastName] = useState("");
    const [warnemail, setwarnemail] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [mobileerror, setMobileError] = useState("");
    const [countryselect, setCountrySelect] = useState("");
    const [occupationslected, setOccupationSelected] = useState("");

    const [toTime, setToTime] = useState('10:00:00');
    const [formData, setFormData] = useState(formColumns);
    const [toDayDate, setToDayDate] = useState(new Date());
    const [projectInterest, setProjectInterest] = useState(specificProjectValue && specificProjectValue?.title);

    const [loading, setLoading] = useState(false);
    const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);

    const [enquireAboutFormData, setProjectFormData] = useState([]);
    const [enquireAboutDropdown, setProjectDropDown] = useState([]);
    const [enquireAboutTermsConditions, setProjectTermsConditions] = useState([]);

    const languageId = location.search?.split('=')[1];
    useEffect(() => {
        setLoading(false);
        getEnquireAboutThisFormData();
        if (specificProjectValue?.title) {
            setFormData(formColumns);
            setFormData(formData => ({ ...formData, projectInterest: specificProjectValue.title }));
        }
        setProjectInterest(specificProjectValue && specificProjectValue?.title);
    }, [specificProjectValue, enquireAboutValue]);

    const getEnquireAboutThisFormData = async () => {
        if (enquireAboutValue && enquireAboutValue.length) {
            setProjectFormData(enquireAboutValue ? enquireAboutValue[0] : null)
            setProjectTermsConditions(enquireAboutValue ? enquireAboutValue[0]?.termsAndConditionMessage[0] : null)
            setProjectDropDown(enquireAboutValue ? enquireAboutValue[0]?.interestedAsDropdownValues?.map((data) => ({
                "value": data.content,
                "label": data.content
            })) : null)
        }
    }

    const onClickHanderSuccess = () => {
        $("#exampleModal").modal('hide')
    };

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios
                .post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/enrollment-request", formData);
            return orgzit;
        }
    };
    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const handleChangeCountry = (e) => {
        e.persist();
        let selectedCountryName = e.target.value;
        setFormData(formData => ({ ...formData, country: selectedCountryName }));
        let countriesList = countries;
        let newDialCode = Object.keys(countriesList).filter((x) => {
            return countriesList[x].country_name === selectedCountryName;
        }).map((x) => {
            return countriesList[x].dial_code;
        });
        let dial_code = newDialCode.length ? newDialCode[0] : '';
        setFormData(formData => ({ ...formData, dial_code: dial_code }));
    }

    const handleChangeTerms = (status) => {
        setFormData(formData => ({ ...formData, terms: status }));
    }

    const resetFrom = () => {
        setFormData({ projectInterest: '', firstName: '', lastName: '', email: '', country: '', occupation: '', dial_code: '', mobile: '', message: '', appointmentDate: '', appointmentTime: '', terms: false, event: `enquiry-for-${specificProjectValue ? specificProjectValue?.slug : null}` });
        document.getElementById("form2").reset();
    }

    const onSuccessForm = () => {
        setwarnemail("");
        setLastName("");
        setEmailError("");
        setMobileError("");
        setProjectInterest("");
        setCountrySelect("");
        setOccupationSelected("");
        setLoading(false);
        resetFrom();
        setTimeout(() => {
            setIsSuccessModalShow(true);
        }, 1000);
        if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': "contactsleadForm" });
            insiderEvent(`enquiry-for-${specificProjectValue && specificProjectValue?.slug}`);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (formData.firstName.length == "" || formData.occupation.length == "" || formData.lastName.length == "" || formData.email.length == "" || formData.country.length == "" || formData.mobile.length == "" || formData.mobile.length < 4 || formData.mobile.length > 20) {
            setwarnemail(enquireAboutFormData.firstNameValidation)
            setLastName(enquireAboutFormData.lastNameValidation)
            setEmailError(enquireAboutFormData.emailValidation)
            setMobileError(enquireAboutFormData.mobileNumberValidation)
            setCountrySelect(enquireAboutFormData.countryValidation)
            setOccupationSelected(enquireAboutFormData.interestedAsValidation)
            return false;
        }
        e.preventDefault(); formData.appointmentDate = toDayDate
        e.preventDefault(); formData.appointmentTime = toTime
        if (formData.terms === false) {
            alert(enquireAboutFormData.alertMessageTermsAndCondition);
            return false;
        }
        if (!simpleValidator.current.fields.email) {
            return false;
        }
        setLoading(true);
        const postDataValue = {
            name: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            phoneNumber: formData.dial_code + formData.mobile,
            notes: formData.message,
            source: "Cubedots Portal",
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);
        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error('Error while form submitting');
        };
        setLoading(false);
    }
    return (
        <>
            {enquireAboutFormData && Object.keys(enquireAboutFormData)?.length ?
                <div className="enquireForm sticky-top" id="enquireProject">
                    <h3 className="">{enquireAboutFormData.heading}</h3>
                    <form id="form2" onSubmit={onSubmit}>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            <input type="text" className="form-control" placeholder={enquireAboutFormData.firstNamePlaceholder} name="firstName" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value.replace(/[^a-z]/gi, '') })} />
                            <input className="form-control" type="hidden" placeholder="Project Interest" name="projectInterest" value={projectInterest} />
                            <div className='validationError'>
                                <p className='errorMsg'>{formData.firstName.length == "" ? warnemail : ""}</p>
                            </div>
                        </div>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            <input type="text" className="form-control" placeholder={enquireAboutFormData.lastNamePlaceholder} name="lastName" value={formData.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value.replace(/[^a-z]/gi, '') })} />
                            <div className='validationError'>
                                <p className='errorMsg'>{formData.lastName.length == "" ? lastname : ""}</p>
                            </div>
                        </div>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            <input type="email" className="form-control" placeholder={enquireAboutFormData.emailAddressPlaceholder} name="email" onKeyUp={() => simpleValidator.current.showMessageFor('email')} value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                            <div className='text-danger'>{simpleValidator.current.message('email', formData.email, 'email', {
                                messages: {
                                    required: emailerror,
                                    email: enquireAboutFormData.emailValidation
                                }
                            })}</div>
                            <div className='validationError'>
                                <p className='errorMsg'>{formData.email.length == "" ? emailerror : ""}</p>
                            </div>
                        </div>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            <select className="form-select" placeholder="Occupation" name="occupation" onChange={(e) => setFormData({ ...formData, occupation: e.target.value })} value={formData.occupation}>
                                <option value="" disabled selected hidden required>{enquireAboutFormData.interestedAsPlaceholder}</option>
                                {enquireAboutDropdown?.map((data) => {
                                    return (
                                        <option value={data.value}>{data.label}</option>)
                                })}
                            </select>
                            <div className='validationError'>
                                <p className='errorMsg'>{formData.occupation.length == "" ? occupationslected : ""}</p>
                            </div>
                        </div>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            {countries.length > 0 ?
                                <>
                                    <select className="form-select" placeholder={enquireAboutFormData.countryPlaceholder} name="country" onChange={handleChangeCountry} defaultValue={formData.country}>
                                        <option value="" disabled selected hidden>{enquireAboutFormData.countryPlaceholder}</option>
                                        {countries.length > 0 && countries.map((row, index) => <option value={row.country_name} key={index} >{row.country_name}</option>)}
                                    </select>
                                </>
                                : null}
                            <div className='validationError'>
                                <p className='errorMsg'>{formData.country.length == "" ? countryselect : ""}</p>
                            </div>
                        </div>
                        <div className="mb-3" style={{ marginTop: '30px' }}>
                            <div className="input-group">
                                <span className="input-group-text" id="basic-addon1">{formData.dial_code ? formData.dial_code : '+90'}</span>
                                <input type="text" className="form-control" placeholder={enquireAboutFormData.mobileNumberPlaceholder} name="mobile" onKeyUp={() => simpleValidator.current.showMessageFor('mobile')} value={formData.mobile} onChange={(e) => setFormData({ ...formData, mobile: e.target.value.replace(/\D/g, "") })} />
                            </div>
                            <p className='errorMsg'>{formData.mobile.length == "" || formData.mobile.length < 4 || formData.mobile.length > 20 ? mobileerror : ""}</p>
                        </div>
                        {/* <div className="row">
                        <div className="col-12 mb-3">
                            <label className="form-label">Appointment Date</label>
                            <DatePicker className="form-control" placeholder="Appointment Date" name="appointmentDate" value={toDayDate} onChange={setToDayDate} format="dd/MM/yyyy" />
                        </div>
                        <div className="col-12 mb-3">
                            <label className="form-label">Appointment Time</label>
                            <TimePicker className="form-control" value={toTime} onChange={setToTime} />
                        </div>
                    </div> */}
                        <div className="row" style={{ marginTop: '30px' }}>
                            <div className="col mb-4">
                                <input className="form-control" placeholder={enquireAboutFormData.messagePlaceholder} name="message" defaultValue={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })}></input>
                            </div>
                        </div>

                        <div className="form-check termAndConditionCheckbox">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={() => handleChangeTerms(!formData.terms)} />
                            <label className="form-check-label" >
                                <div className="termsAndConditionSection" style={{ lineHeight: "1.2" }}>
                                    <small className="formFooterContent" style={{ fontSize: '10px', color: '#28292B', opacity: 0.5, fontWeight: 500 }} dangerouslySetInnerHTML={{ __html: `${enquireAboutFormData.termsAndConditionMessage}` }} ></small>
                                    {/* <small className='termsConditionLink' style={{ fontSize: '10px', color: '#dc6641', textDecoration: 'underline' }}><a href='/terms_and_conditions'>{enquireAboutTermsConditions.termsAndConditions}</a></small> */}
                                </div>
                            </label>
                        </div>
                        <div style={{ width: '100%', alignSelf: 'center', alignItem: 'center', height: '100%', textAlign: 'center' }}>
                            {loading ?
                                <button className="btntheme" type="button" disabled >
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> {enquireAboutFormData.submitButton}
                                </button>
                                :
                                <button className="btntheme" type="submit" >{enquireAboutFormData.buttonName}</button>
                            }
                        </div>
                        <div className="socialNewsDetailIcons">
                            <ul className="d-flex p-0 justify-content-center mt-4">
                                <a className="socialIconsEnroll socialIconswhatspp" target="_blank" href="https://wa.me/908505327532">
                                </a>
                                <a className="socialIconsEnroll socialIconsinstagram" target="_blank" href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=">
                                </a>
                                <a className="socialIconsEnroll socialIconsfacebook" target="_blank" href="https://www.facebook.com/cubedots">
                                </a>
                                <a className="socialIconsEnroll socialIconslinkedin" target="_blank" href="https://www.linkedin.com/company/cubedots/mycompany/?viewAsMember=true">
                                </a>
                                <a className="socialIconsEnroll socialIconsYoutube" target="_blank" href="https://www.youtube.com/cubedots">

                                </a>
                                <a className="socialIconsEnroll socialIconsTwitter" target="_blank" href="https://mobile.twitter.com/cubedots">

                                </a>
                            </ul>
                            {/* <ShareIconsComponent/> */}
                        </div>
                    </form>
                </div>
                : null
            }
            {isSuccessModalShow && enquireAboutFormData ? <SuccessfulPopupComponent isModalShow={isSuccessModalShow} onClose={() => setIsSuccessModalShow(false)} submitMessageData={enquireAboutFormData} /> : null}
        </>
    );

}

export default EnquireAboutThisPropertyComponent;