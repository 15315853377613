import React from "react";

const SecondUltra = () => {
    return (
        <>
            <div className="secondSection">
                <div className="container-fluid p-0">
                    <div className="row p-0 m-0">
                        <div className="col-md-4 p-0">
                            <div className="nisnatasiContent">
                                <img src="/assets/images/cityscape/nisantasi_logo.png" className="img-fluid" width={100} />
                                <h6>Ultra-Luxe</h6>
                                <h6>Residences At The</h6>
                                <h6>Poshest Location</h6>
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="imagesSection">
                                <img src="/assets/images/cityscape/2.jpg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SecondUltra;