import React, { useState } from "react";

import CookieConsent from "react-cookie-consent";
import { Link, useLocation } from "react-router-dom";

import EnrollmentModalComponent from "../Components/EnrollmentModalComponent";
import { WEBSITE_URL } from "./Constants";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import { useApp } from "../hooks/AppHooks";

function Footer() {
    const location = useLocation();
    const langId = location.search.split("=")[1];
    const { unsoldProjectValue, footerValue, disclaimerValue } = useApp();
    const publicPath = process.env.PUBLIC_URL;

    const [isBecomeOurPartnerModalShow, setIsBecomeOurPartnerModalShow] = useState(false);

    const showBecomeOurPartnerModal = () => {
        document.body.style.overflow = '';
        setIsBecomeOurPartnerModalShow(true);
    }

    const closeBecomeOurPartnerModalModal = () => {
        document.body.style.overflow = 'unset';
        setIsBecomeOurPartnerModalShow(false);
    }

    return (
        <>
            {footerValue?.Footer && Object.keys(footerValue?.Footer).length ?
                <div className="footerMainSection" id="footer-section">
                    <div className="container">
                        <div className="row justify-content-between">
                            {footerValue?.Footer[0]?.logo ?
                                <div className="col-md-2 col-6 mt-md-0 mb-md-0 mt-3 mb-3 order-1">
                                    <a href={langId ? `/?lang=${langId}` : '/'}><img className="footerCubedotsLogo img-fluid" src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${footerValue.Footer[0].logo}`} width={260} /></a>
                                    <button onClick={showBecomeOurPartnerModal}>{footerValue?.Footer[0]?.enrollNowButtonName && footerValue?.Footer[0]?.enrollNowButtonName}</button>
                                </div> : null
                            }
                            <div className="col-md-2 col-6 mt-md-0 mb-md-0 mt-3 mb-3 order-md-2 order-3">
                                <h3>{footerValue?.Footer[0]?.quickLink}</h3>
                                {Object.keys(footerValue?.Footer[0]?.quickLinksList[0]).length ?
                                    <ul className="homePageMenuBtn">
                                        <li><span> <a href={langId ? `/aboutus?lang=${langId}` : '/aboutus'}>{footerValue?.Footer[0]?.quickLinksList[0].aboutUs}</a></span></li>
                                        <li><span> <a href={langId ? `/agents?lang=${langId}` : '/agents'}>{footerValue?.Footer[0]?.quickLinksList[0].agents}</a></span></li>
                                        <li><span> <a href={langId ? `/developer?lang=${langId}` : '/developer'}>{footerValue?.Footer[0]?.quickLinksList[0].developers}</a></span></li>
                                        <li><span> <a href={langId ? `/projects?lang=${langId}` : '/projects'}>{footerValue?.Footer[0]?.quickLinksList[0].projects}</a></span></li>
                                        <li><span> <a href={langId ? `/blogs?lang=${langId}` : '/blogs'}>{footerValue?.Footer[0]?.quickLinksList[0].blogs}</a></span></li>
                                        <li><span> <a href={langId ? `/contact?lang=${langId}` : '/contact'}>{footerValue?.Footer[0]?.quickLinksList[0].contactUs}</a></span></li>
                                    </ul> : null}
                            </div>
                            <div className="col-md-3 col-6 mt-md-0 mb-md-0 mt-3 mb-3 order-3">
                                <h3>{footerValue?.Footer[0]?.projects}</h3>
                                <ul className="homePageMenuBtn">
                                    {
                                        unsoldProjectValue?.length ? unsoldProjectValue?.map((row, i) => {
                                            return (<li>
                                                <span>
                                                    <Link key={i} to={langId ? `/projects/${row.slug}?lang=${langId}` : `/projects/${row.slug}`} state={{ id: row.id, bannerImage: row.projectMedia && row.projectMedia[0]?.fileS3Path }}>
                                                        {row.title}</Link>
                                                </span>
                                            </li>)
                                        }) : null
                                    }
                                </ul>
                            </div>
                            <div className="col-xl-3 col-md-4 col-sm-6 col-10 mt-md-0 mb-md-0 mt-2 mb-3 order-md-3 order-2">
                                <ul className="d-flex p-0 mt-md-0 mt-sm-2 mt-0">
                                    <a className="socialIconsEnroll socialIconswhatspp" target="_blank" href="https://api.whatsapp.com/send?phone=908505327532">
                                    </a>
                                    <a className="socialIconsEnroll socialIconsfacebook" target="_blank" href="https://www.facebook.com/cubedots">
                                    </a>
                                    <a className="socialIconsEnroll socialIconslinkedin" target="_blank" href="https://www.linkedin.com/company/cubedots/mycompany/?viewAsMember=true">
                                    </a>
                                    <a className="socialIconsEnroll socialIconsinstagram" target="_blank" href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=">
                                    </a>
                                    <a className="socialIconsEnroll socialIconsTwitter" target="_blank" href="https://mobile.twitter.com/cubedots">
                                    </a>
                                    <a className="socialIconsEnroll socialIconsYoutube" target="_blank" href="https://www.youtube.com/cubedots">
                                    </a>
                                </ul>
                                <div className="row infoFooter">
                                    {/* <div className="col-12">
                                        <h6 style={{ marginBottom: '20px' }}>{footerValue?.Footer[0]?.city}, {footerValue?.Footer[0]?.country}</h6>
                                    </div> */}
                                    <div className="row align-items-center">
                                        <div className="col-2">
                                            <img src={publicPath + "/assets/images/footerSocialIcon/call.svg"} width={"16px"} />
                                        </div>
                                        <div className="col-10">
                                            <p><a className="telephoneLink" href="tel: +90 544 382 51 21">{footerValue?.Footer[0]?.phone}</a></p>
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-2">
                                            <img src={publicPath + "/assets/images/footerSocialIcon/mail.svg"} width={"16px"} />
                                        </div>
                                        <div className="col-10">
                                            <p><a className="telephoneLink" href="mailto:info@cubedots.com">{footerValue?.Footer[0]?.email}</a></p>
                                        </div>
                                    </div>
                                    <div className="row pb-2">
                                        <div className="col-2">
                                            <img src={publicPath + "/assets/images/footerSocialIcon/location.svg"} width={"15px"} />
                                        </div>
                                        <div className="col-10">
                                            <p><a className="telephoneLink" href="https://goo.gl/maps/pM2qKSTDdWmPc66x6" target="_blank">{footerValue?.Footer[0]?.address}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footerCopyrightBox">
                            {footerValue?.Footer[0]?.footerContent?.length && Object.keys(footerValue?.Footer[0]?.footerContent[0]).length ?
                                <div className="row justify-content-between d-flex align-items-center footerLine">
                                    <div className="col-lg-9">
                                        <div className="copyrightSection">
                                            <ul className="">
                                                <div className="">
                                                    <li className="d-flex beforeFooter footerSiteFontSize">{footerValue?.Footer[0]?.footerContent[0]?.copyRight}</li>
                                                </div>
                                                <Link to={langId ? `/privacy_policy/?lang=${langId}` : `/privacy_policy`} className="ms-4 footerSiteFontSize beforeFooter">
                                                    <li>{footerValue?.Footer[0]?.footerContent[0]?.privacyPolicy}</li>
                                                </Link>
                                                <Link to={langId ? `/terms_and_conditions/?lang=${langId}` : `/terms_and_conditions`} className="ms-4 footerSiteFontSize beforeFooter">
                                                    <li>{footerValue?.Footer[0]?.footerContent[0]?.termsConditions}</li>
                                                </Link>
                                                <Link to={langId ? `/sitemap/?lang=${langId}` : `/sitemap`} className="ms-4 footerSiteFontSize beforeFooter">
                                                    <li>{footerValue?.Footer[0]?.footerContent[0]?.siteMap}</li>
                                                </Link>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 text-lg-end text-start footerSiteFontSize">
                                        <p className="footerSiteFontSize text-lg-end text-start">{footerValue?.Footer[0]?.footerContent[0]?.poweredBy} <a href={WEBSITE_URL}>Cubedots.com</a></p>
                                    </div>
                                </div> : null}
                        </div>
                    </div>
                </div> : null
            }
            <EnrollmentModalComponent
                isOpen={isBecomeOurPartnerModalShow}
                onRequestClose={closeBecomeOurPartnerModalModal}
                event="partnerleadForm"
                insiderEventName="enrollment" />
            {disclaimerValue?.Disclaimer && disclaimerValue?.Disclaimer.length ? <CookieConsent
                location="bottom"
                buttonText={disclaimerValue?.Disclaimer[0]?.disclaimerButton[0]?.accept}
                buttonClasses="acceptBtn"
                declineButtonClasses="cancelBtn"
                declineButtonText={disclaimerValue?.Disclaimer[0]?.disclaimerButton[0]?.cancel}
                cookieName="myAwesomeCookieName2"
                style={{ background: "#FFFF", color: "#444", fontSize: "13px", position: "sticky", }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                enableDeclineButton>
                <div className="dislaimerSection">
                    <p dangerouslySetInnerHTML={{ __html: `${disclaimerValue?.Disclaimer[0]?.disclaimerContent}` }} />
                </div>
            </CookieConsent> : null}
        </>


    )
}
export default Footer;