import React, { useState, useEffect } from 'react';

import { useApp } from '../../hooks/AppHooks';

function Information() {

    const { termsValue } = useApp();
    const [informationData, setInfmationData] = useState();

    useEffect(() => {
        if (termsValue["Information Security Policy Of Cubedots"]
            && termsValue["Information Security Policy Of Cubedots"]?.length) {
            setInfmationData(termsValue["Information Security Policy Of Cubedots"]);
        }
    }, [termsValue]);

    return (
        <>
            {informationData ?
                <>
                    <h3 className="vegonHeading">{informationData[0]?.paragraph}</h3>
                    <p dangerouslySetInnerHTML={{ __html: `${informationData[0]?.content[0]?.content ? informationData[0].content[0].content : null}` }} />
                    <h6 style={{ opacity: 0.8, color: "#000" }} >{informationData[0]?.title}</h6>
                    <p className="information-list" dangerouslySetInnerHTML={{ __html: `${informationData[0]?.content[0]?.subContent ? informationData[0].content[0].subContent : null}` }} />
                </> : null}
        </>
    )
}
export default Information;