import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';
import Modal from 'react-modal';
import { Spinner } from "react-bootstrap";
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../common/PhoneNumberValidation"

import { useApp } from "../hooks/AppHooks";
import SimpleReactValidator from 'simple-react-validator';
import SuccessfulPopupComponent from "../Components/SuccessfulPopupComponent";
import { insiderEvent, CMS_API_ENDPOINT, WEBSITE_X_API_KEY, ORGZIT_CALL_PERMISSION, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, INTERNAL_CRM_CALL_PERMISSION } from "../common/Constants";


function EnrollmentModalComponent(props) {
    const { enquireAboutData, unsoldProjectValue, enquireAboutProjectValue, languageId, countries, language, languageValue } = useApp();
    const publicPath = process.env.PUBLIC_URL;
    const simpleValidator = useRef(new SimpleReactValidator());
    const [projects, setProjects] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [mobileerror, setMobileError] = useState("")
    const [projectinterest, setProjectInterest] = useState("");
    const [countryselect, setCountrySelect] = useState("");
    const [occupationslected, setOccupationSelected] = useState("");
    const [project, setProject] = useState(null);
    const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
    const [enquireAboutFormData, setProjectFormData] = useState([]);
    const [enquireAboutTermsConditions, setProjectTermsConditions] = useState([]);
    const [enquireAboutDropdown, setProjectDropDown] = useState([]);

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        projectInterest: "",
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        dial_code: "",
        mobile: "",
        occupation: "",
        projectName: "",
        message: "",
        security_code: "",
        terms: false,
        event: "enquiry-about-project-contact-us"
    });
    const [securityCode, setSecurityCode] = useState(null);
    const phoneNumberUtil = PhoneNumberUtil.getInstance();

    useEffect(() => {
        setProject(props.project);
        genRandomString();
        setLoading(false);
        getEnquireAboutFormData();
        fetchProjects()
    }, [props.project, enquireAboutData, unsoldProjectValue, enquireAboutProjectValue]);

    const getEnquireAboutFormData = async () => {
        if (enquireAboutProjectValue && enquireAboutProjectValue.length) {
            setProjectFormData(enquireAboutProjectValue ? enquireAboutProjectValue[0] : null)
            setProjectTermsConditions(enquireAboutProjectValue ? enquireAboutProjectValue[0]?.termsAndConditionMessage[0] : null)
            setProjectDropDown(enquireAboutProjectValue ? enquireAboutProjectValue[0]?.interestedAsDropdownValues?.map((data) => ({
                "value": data.content,
                "label": data.content
            })) : null)
        }
    }

    async function fetchProjects() {
        if (unsoldProjectValue && unsoldProjectValue.length) {
            setProjects(unsoldProjectValue ? unsoldProjectValue?.map((data) => ({
                "value": data.title,
                "label": data.title
            })) : null)
        }
    }

    const handleChangeCountry = (e) => {
        e.persist();
        let selectedCountryName = e.target.value;
        setFormData((formData) => ({ ...formData, country: selectedCountryName }));
        let countriesList = countries;
        let newDialCode = Object.keys(countriesList)
            .filter((x) => {
                return countriesList[x].country_name === selectedCountryName;
            })
            .map((x) => {
                return countriesList[x].dial_code;
            });
        let dial_code = newDialCode.length ? newDialCode[0] : "";
        setFormData((formData) => ({ ...formData, dial_code: dial_code }));
    };

    const handleChangeTerms = (status) => {
        setFormData((formData) => ({ ...formData, terms: status }));
    };

    const genRandomString = () => {
        let text = "";
        const length = 6;
        const possible =
            "ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        setSecurityCode(text);
        return text;
    };

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_CALL_PERMISSION) {
            const orgzit = await axios
                .post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/enrollment-request", formData);
            return orgzit;
        }
    };


    const createLead = async (postBody) => {
        if (INTERNAL_CRM_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const resetFrom = () => {
        setFormData({
            projectInterest: "",
            firstName: "",
            lastName: "",
            email: "",
            country: "",
            dial_code: "",
            mobile: "",
            occupation: "",
            message: "",
            security_code: "",
            terms: false,
            event: "enquiry-about-project-contact-us"
        });
        document.getElementById("form1").reset();
    };

    const resetStateValues = () => {
        setFirstName("");
        setLastName("");
        setEmailError("");
        setMobileError("");
        setProjectInterest("");
        setOccupationSelected("");
        setCountrySelect("");
        setLoading(false);
        genRandomString();
        resetFrom();
        props.onRequestClose();
    }

    const onSuccessForm = () => {
        resetStateValues();
        formData.message = ""
        formData.security_code = ""
        document.body.style.overflow = "hidden"
        props.onRequestClose()
        setIsSuccessModalShow(true);
        if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'event': props.event });
            insiderEvent("enquiry-about-project-contact-us");
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formData && formData.firstName.length == "" || formData.lastName.length == "" || formData.email.length == "" ||
            formData.occupation.length == "" || formData.country.length == "" || formData.mobile.length == "" ||
            formData.mobile.length < 4 || formData.mobile.length > 20 || formData.occupation.length == ""
            || formData.projectInterest.length == "") {
            setFirstName(enquireAboutFormData.firstNameValidation);
            setLastName(enquireAboutFormData.lastNameValidation);
            setEmailError(enquireAboutFormData.emailValidation);
            setMobileError(enquireAboutFormData.mobileNumberValidation);
            setOccupationSelected(enquireAboutFormData.interestedAsValidation);
            setProjectInterest(enquireAboutFormData.projectValidation);
            setCountrySelect(enquireAboutFormData.countryValidation);
            return;
        }
        if (formData.security_code !== securityCode) {
            alert(enquireAboutFormData.alertMessageSecurityCode);
            return false;
        }
        if (formData.terms === false) {
            alert(enquireAboutFormData.alertMessageTermsAndCondition);
            return false;
        }
        if (project) {
            formData.projectInterest = project;
        }
        if (!simpleValidator.current.fields.email) {
            return false;
        }
        setLoading(true);
        const postDataValue = {
            name: formData.firstName + ' ' + formData.lastName,
            email: formData.email,
            phoneNumber: formData.dial_code + formData.mobile,
            notes: formData.message,
            source: "Cubedots Portal",
            fromWebsite: true,
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        if (orgzitCall?.data?.status === "Success") {
            onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error('Error while form submitting');
        };
        setLoading(false);
    };

    const checkTheLandlineAndPhoneNumber = (PhoneNumber) => {
        const { dial_code } = formData;
        let validationData = PhoneNumber && dial_code ? `${dial_code}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setMobileError(" ")
            } else {
                setMobileError(enquireAboutFormData.mobileNumberValidation)
            }
        }
    }

    return (
        <div>
            xcbgj
            {enquireAboutFormData && Object.keys(enquireAboutFormData).length ?
                <Modal{...props} style={{ overflow: 'auto' }}
                    onRequestClose={resetStateValues}
                >
                    <div className="p-3 enrollmentModal">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="enrollmentContent">
                                    <div className="position-relative">
                                        {<h3><span className="underLineClass">{enquireAboutFormData?.heading}</span></h3>}
                                        <a className="cloneBtnNewModal" onClick={props.onRequestClose}><img src={publicPath + "/assets/images/Close.png"} onClick={resetStateValues} /></a>
                                    </div>

                                    <form id="form1" onSubmit={onSubmit}>
                                        <div className="row ">
                                            <div className="col-md-6 mb-3" >
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={enquireAboutFormData?.firstNamePlaceholder}
                                                    name="firstName"
                                                    onKeyUp={() => simpleValidator.current.showMessageFor("firstName")}
                                                    value={formData.firstName}
                                                    onChange={(e) =>
                                                        setFormData({
                                                            ...formData,
                                                            firstName: e.target.value.replace(/[^a-z]/gi, ''),
                                                        })}
                                                />
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData.firstName.length == "" ? firstName : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={enquireAboutFormData?.lastNamePlaceholder}
                                                    name="lastName"
                                                    onKeyUp={() =>
                                                        simpleValidator.current.showMessageFor("lastName")}
                                                    value={formData.lastName}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        lastName: e.target.value.replace(/[^a-z]/gi, ''),
                                                    })}
                                                />
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData?.lastName.length == "" ? lastname : ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={enquireAboutFormData?.emailAddressPlaceholder}
                                                    name="email"
                                                    onKeyUp={() => simpleValidator.current.showMessageFor("email")}
                                                    value={formData.email}
                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                />
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData?.email.length == "" ? emailerror : ""}</p>

                                                    <div className='errorMsg'>{simpleValidator?.current.message('email', formData.email, 'email', {
                                                        messages: {
                                                            required: emailerror,
                                                            email: enquireAboutFormData.emailValidation
                                                        }
                                                    })}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <select className="form-select" placeholder="Occupation" name="occupation"
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        occupation: e.target.value,
                                                    })}
                                                    defaultValue={formData.occupation}>
                                                    <option value="" disabled selected hidden> {enquireAboutFormData?.interestedAsPlaceholder}</option>
                                                    {enquireAboutDropdown?.map((data) => {
                                                        return (
                                                            <option value={data.value}>{data.label}</option>)
                                                    })}
                                                </select>
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData.occupation === "" ? occupationslected : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <select
                                                    className="contactComponent form-select"
                                                    placeholder={enquireAboutFormData?.projectPlaceholder}
                                                    name="selectProject"
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        projectInterest: e.target.value,
                                                    })}
                                                >
                                                    <option value="" disabled selected hidden>
                                                        {enquireAboutFormData?.projectPlaceholder}
                                                    </option>
                                                    {projects.length > 0 ?
                                                        projects.map((row, index) => (
                                                            <option
                                                                value={row.value}
                                                                key={index}>
                                                                {row.label}
                                                            </option>
                                                        )) : null}
                                                </select>
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData?.projectInterest === "" ? projectinterest : ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                {countries.length > 0 ? (
                                                    <>
                                                        <select
                                                            className="form-select scroller"
                                                            placeholder="Country"
                                                            name="country"
                                                            onChange={handleChangeCountry}
                                                            defaultValue={formData?.country_name}>
                                                            <option value="" disabled selected hidden>{enquireAboutFormData?.countryPlaceholder}
                                                            </option>
                                                            {countries.length > 0 ?
                                                                countries.map((row, index) => (
                                                                    <option value={row.country_name} key={index}>
                                                                        {row.country_name}
                                                                    </option>
                                                                )) : null}
                                                        </select>
                                                    </>
                                                ) : null}
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData?.country.length == "" ? countryselect : ""}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        {formData.dial_code ? formData.dial_code :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                className="bi bi-telephone-plus" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                <path fillRule="evenodd"
                                                                    d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                            </svg>}
                                                    </span>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder={enquireAboutFormData?.mobileNumberPlaceholder}
                                                        name="mobile"
                                                        value={formData.mobile}
                                                        onKeyUp={() => simpleValidator.current.showMessageFor("mobile")}
                                                        onChange={(e) => {
                                                            setFormData({
                                                                ...formData,
                                                                mobile: e.target.value.replace(/\D/g, "")
                                                            })
                                                            if (formData?.country) {
                                                                checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="text-danger formErrorMsg"></div>
                                                <div className='validationError'>
                                                    <p className='errorMsg'>{formData?.mobile.length == ""
                                                        || mobileerror ? mobileerror : ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col mb-3">
                                                <input
                                                    className="form-control"
                                                    placeholder={enquireAboutFormData?.messagePlaceholder}
                                                    name="message"
                                                    defaultValue={formData.message}
                                                    style={{ height: '110%' }}
                                                    onChange={(e) => { setFormData({ ...formData, message: e.target.value }) }}>
                                                </input>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="captchInput">
                                                <input
                                                    type="text"
                                                    placeholder={enquireAboutFormData?.securityCode}
                                                    name="security_code"
                                                    autoComplete="off"
                                                    defaultValue={formData.security_code}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        security_code: e.target.value,
                                                    })} />
                                                <span className="captha_code mr-sm-2 captcha-code-disable" id="notcp">
                                                    {securityCode}
                                                </span>
                                                <button
                                                    type="button"
                                                    onClick={() => genRandomString()}
                                                    className="btn btn-default">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-arrow-repeat"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form-check termAndConditionCheckbox">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexCheckDefault"
                                                        defaultValue={formData?.terms}
                                                        onClick={() => handleChangeTerms(!formData?.terms)} />
                                                    <label className="form-check-label">
                                                        <div className="termsAndConditionSection">
                                                            <small className="formFooterContent" dangerouslySetInnerHTML={{ __html: `${enquireAboutFormData.termsAndConditionMessage}` }}>

                                                            </small>

                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="text-center mt-1">
                                                    {loading ? (
                                                        <button className="btntheme" type="button" disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true" />
                                                            {" "}
                                                            {enquireAboutFormData?.submitButton}
                                                        </button>
                                                    ) : (

                                                        <button className="btntheme" type="submit">
                                                            {enquireAboutFormData?.buttonName}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div>
                                        <div className="socialIconsDiv">
                                            <div className="row socialIconsCompo">
                                                <ul>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://wa.me/908505327532">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/whatspp.png"} />
                                                        </a>
                                                    </small>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/instagram.png"} />
                                                        </a>
                                                    </small>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://www.facebook.com/cubedots">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/facebook.png"} />
                                                        </a>
                                                    </small>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://www.linkedin.com/company/cubedots/mycompany/?viewAsMember=true">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/linkedin.png"} />
                                                        </a>
                                                    </small>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://www.youtube.com/cubedots">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/Youtube.png"} />
                                                        </a>
                                                    </small>
                                                    <small className="socialIconsEnroll">
                                                        <a target="_blank" href="https://mobile.twitter.com/cubedots">
                                                            <img src={publicPath + "/assets/images/newsdetailicon/Twitter.png"} />
                                                        </a>
                                                    </small></ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                : null
            }
            {isSuccessModalShow ? <SuccessfulPopupComponent isModalShow={isSuccessModalShow} onClose={() => setIsSuccessModalShow(false)} submitMessageData={enquireAboutFormData} /> : null}
        </div>
    )
}

export default EnrollmentModalComponent;