import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './contexts';

const root = ReactDOM.createRoot(document.getElementById('root'));
const publicPath = process.env.PUBLIC_URL;

root.render(

  <React.StrictMode>
    <BrowserRouter basename={publicPath}>
      <AppProvider>
        <App />
      </AppProvider>
    </BrowserRouter >
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
