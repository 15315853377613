import React from "react";

const SecondUltra = () => {
    return (
        <>
            <div className="secondSection">
                <div className="container-fluid p-0">
                    <div className="row p-0 m-0">
                        <div className="col-md-4 p-0">
                            <div className="nisnatasiContent">
                                <div className="youndBusinessText">
                                    <h6>Become A Cubedots Valued</h6>
                                    <h6>Associated And Your Local Clients</h6>
                                    <h6>Will Trust You More To Invest</h6>
                                    <h6>In Turkey</h6>
                                </div>
                                <div>
                                    <img src="/assets/images/cityscape/young_business.jpg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                            <div className="imagesSection">
                                <img src="/assets/images/cityscape/2.jpg" className="img-fluid" />
                                <div className="nisantasiLogo">
                                    <img src="/assets/images/cityscape/nisantasi_logo.png" className="img-fluid" width={100} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default SecondUltra;