import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Modal } from 'antd';
// import $ from 'jquery';
import SimpleReactValidator from 'simple-react-validator';
import { PhoneNumberUtil, PhoneNumberType } from 'google-libphonenumber';
import { validatePhoneNumber, validateLandlinePhoneNumber } from "../../../../common/PhoneNumberValidation";
import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, WEBSITE_X_API_KEY, CMS_API_ENDPOINT, ORGZIT_LANDING_CALL_PERMISSION, INTERNAL_CRM_LANDING_CALL_PERMISSION } from "../../../../common/Constants";

const FormSection = () => {
    const simpleValidator = useRef(new SimpleReactValidator());
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const [landingFormData, setLandingFormData] = useState({
        firstName: "",
        email: "",
        source: "Landing Page - CITY SCAPE GLOBAL",
        phone: "",
        dialCode: "+90",
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [dialCodeWarn, setDialCodeWarn] = useState('');
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    async function fetchCountries() {
        try {
            const res = await axios.get("/assets/data/countryCode.json");
            if (res) {
                setCountries(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setLoading(false)
        setLandingFormData({
            firstName: "",
            email: "",
            phone: "",
            source: "Landing Page - CITY SCAPE GLOBAL",
            dialCode: "+90"
        })
    }

    const createLeadOnOrgzit = async () => {
        if (ORGZIT_LANDING_CALL_PERMISSION) {
            const orgzit = await axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT +
                "become-our-partner/landing-enrollment-request", landingFormData
            )
            return orgzit;
        }
    };

    const createLead = async (postBody) => {
        if (INTERNAL_CRM_LANDING_CALL_PERMISSION) {
            const lead = await axios
                .post(CMS_API_ENDPOINT + "crm/lead/add", postBody, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
            return lead;
        }
    };

    const onSuccessForm = () => {
        setLandingFormData()
        resetStateValues();
        $("#exampleModal").modal('show')
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if ((landingFormData.firstName.length == "" || landingFormData.firstName.trim() === "" ||
        landingFormData.email.length == "" || landingFormData.email.trim() === "" ||
        landingFormData.phone.length == "") || 
        simpleValidator.current.errorMessages.email || dialCodeWarn) {
            setFirstNameWarn("Please Enter Name")
            setPhoneWarn("Please Enter Phone number")
            setEmailWarn("Please Enter Email id")
            setLoading(false)
            return false;
        }
        setBtnLoading(true);
        const postDataValue = {
            name: landingFormData.firstName.trim(),
            email: landingFormData.email.trim(),
            phoneNumber: landingFormData.dialCode + landingFormData.phone.trim(),
            source: "Landing Page - City Scape",
            fromWebsite: true,
        };
        const [orgzitCall, internalCRM] = await Promise.all([
            createLeadOnOrgzit(),
            createLead(postDataValue)
        ]);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'alya4MevsimLPTR' });
        if (orgzitCall?.data?.status === "Success") {
           onSuccessForm();
        } else if (internalCRM?.data?.status === "Success") {
            onSuccessForm();
        } else {
            message.error("Error while form submitting");
        }
        setLoading(false);
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCountries();
    }, []);

    const checkTheLandlineAndPhoneNumber = (PhoneNumber, dialCode) => {
        let validationData = PhoneNumber && dialCode ? `${dialCode}${PhoneNumber}` : "";
        const parsedNumber = phoneNumberUtil.parse(validationData);
        const isoCode = parsedNumber ? phoneNumberUtil.getRegionCodeForNumber(parsedNumber) : "";
        if (validationData && isoCode) {
            if (validateLandlinePhoneNumber(validationData, isoCode, phoneNumberUtil, PhoneNumberType) ||
                validatePhoneNumber(validationData, isoCode, phoneNumberUtil)) {
                setDialCodeWarn("")
            } else {
                setDialCodeWarn("please enter valid phone number");
            }
        }
    }

    const handleSelectChange = (event) => {
        setLandingFormData({
            ...landingFormData,
            dialCode: event.target.value
        })
        checkTheLandlineAndPhoneNumber(landingFormData?.phone, event.target.value)
    };

    return (
        <div className="form_box">
            <div className="container-fluid">
                <div className="text-center">
                    <span>
                        <button>REGISTER NOW</button>
                    </span>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 name_col">
                        <input
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            onChange={(e) => setLandingFormData({
                                ...landingFormData,
                                firstName: e.target.value
                            })}
                            value={landingFormData.firstName}
                        />
                        <div className='validationError'>
                            <p className='errorMsg'>{landingFormData?.firstName.length == "" || landingFormData.firstName.trim() === ""  ? firstNameWarn : ""}</p>
                        </div>
                    </div>
                    <div className="col-md-4 code_col">
                        <div className="row align-items-center">
                            <div className="col-5 pe-1">
                            <select value={landingFormData.dialCode} className="form-control" onChange={handleSelectChange} >
                                    {countries.length > 0 && countries.map((row, index) =>
                                        <option value={row.dial_code} key={index}>{row.name}{row.dial_code}</option>)}
                                </select>
                            </div>
                            <div className="col-7 ps-1">
                                <input
                                    type="tel"
                                    id="txtPhone"
                                    className="txtbox form-control"
                                    onChange={(e) => {
                                        setLandingFormData({
                                            ...landingFormData,
                                            phone: e.target.value.replace(/\D/g, "")
                                        })
                                        checkTheLandlineAndPhoneNumber(e.target.value.replace(/\D/g, ""), landingFormData.dialCode)
                                    }}
                                    placeholder="Phone No."
                                    value={landingFormData.phone}
                                />
                            </div>
                        </div>
                        <div className='validationError phoneValidation'>
                            <p className='errorMsg'>{landingFormData?.phone?.length == "" ? phoneWarn : dialCodeWarn ? dialCodeWarn : ""}</p>
                        </div>
                    </div>
                    <div className="col-md-4 email_col">
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            onKeyUp={() => simpleValidator?.current?.showMessageFor("email")}
                            className="form-control"
                            onChange={(e) => setLandingFormData({
                                ...landingFormData,
                                email: e.target.value
                            })}
                            value={landingFormData.email}
                        />
                        <div className='validationError'>
                           <p className='errorMsg'>{landingFormData?.email.length == "" || landingFormData.email.trim() === "" ? emailWarn : ""}</p>
                            <p className='errorMsg'>{simpleValidator?.current?.message('email', landingFormData?.email, 'email')}</p>
                        </div>
                    </div>
                    <div className="text-center">
                        <span>
                            {loading ? (
                                <button className="second_send_btn" type="button"
                                    >
                                    Submiting...
                                </button>
                            ) : (
                                <button className="second_send_btn" id="btnSubmit"
                                    onClick={onSubmit}>Submit</button>
                            )}
                        </span>
                    </div>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#33485b"
                                        className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </p>
                                <div className="modal-body">
                                    <h3>Thank You !</h3>
                                    <h6>We will contact you soon.</h6>
                                    <button type="button" className="okBtn" data-bs-dismiss="modal">Tamam</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FormSection;