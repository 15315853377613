import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CMS_PORTAL_BUCKET } from "../../common/Constants";

function WhyDevelopersTrustCaourselComponent(props) {


    useEffect(() => {

        $(function () {
            const $rootSingle = $('.cSlider--sing');
            const $rootNav = $('.cSlider--nav');

            $rootSingle.not('.slick-initialized').slick({
                slide: '.cSlider__item',
                slidesToShow: 1,
                slidesToScroll: 1,
                arrow:false,
                dots: false,
                fade: true,
                autoplay: true,
                pauseOnHover: false,
                pauseOnFocus:false,
                adaptiveHeight: true,
                infinite: true,
                useTransform: true,
                speed: 400,
                cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
            });

            $rootNav.on('init', function (event, slick) {
                $(this).find('.slick-slide.slick-current').addClass('is-active');
            }).not('.slick-initialized')
                .slick({
                    slide: '.cSlider__item',
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    
                    dots: false,
                    arrow:false,
                    focusOnSelect: false,
                    responsive: [{
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    }, {
                        breakpoint: 640,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    }, {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    }]
                });

            $rootSingle.on('afterChange', function (event, slick, currentSlide) {
                $rootNav.slick('slickGoTo', currentSlide);
                $rootNav.find('.slick-slide.is-active').removeClass('is-active');
                $rootNav.find('.slick-slide[data-slick-index="' + currentSlide + '"]').addClass('is-active');
            });

            $rootNav.on('click', '.slick-slide', function (event) {
                event.preventDefault();
                var goToSingleSlide = $(this).data('slick-index');

                $rootSingle.slick('slickGoTo', goToSingleSlide);
            });


        })

    }, []);
    return (
        <div>
            {props.data && props.data.length ?
                <div className="NewwhyDeveloperCarouselSection" id="why-developers-trust-us">
                    <div className="">
                        <h1 className="container" id="carousel-heading" >
                            <span className="underLineClass">
                                {props.data[0]?.heading}
                            </span>
                        </h1>

                    </div>
                </div>
                : null}
            <div>
                <div className="container">
                    <div class="cSlider  cSlider--nav"  >
                        {props?.data?.map((element, index) => {
                            const image = `https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${element.imageLink}`;
                            return (
                                <>
                                    <div class="cSlider__item"> <img className="carousel-image"
                                        src={image} /> <h2 className="carousel-border"></h2></div>

                                </>
                            );

                        })}

                    </div>
                </div>
                <div class=' column small-11 small-centered '>
                <div class="cSlider  cSlider--sing" >
                    {props?.data?.map((element, index) => {
                        return (
                            <div class="cSlider__item" id ="banner-div"><img className="slider-image"
                                src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${element.backgroundImage}`}
                                alt="banner image" />

                                <div class="centered">
                                    <h5 className="slider-h5">{element.title}</h5>
                                    <p className="slider-p"><span className="leftQuote">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19.125"
                                            height="16.734"
                                            viewBox="0 0 19.125 16.734" >
                                            <path
                                                id="Icon_awesome-quote-left"
                                                data-name="Icon awesome-quote-left"
                                                d="M17.332,10.617H14.344V8.227a2.393,2.393,0,0,1,2.391-2.391h.3a.894.894,0,0,0,.9-.9V3.146a.894.894,0,0,0-.9-.9h-.3a5.975,5.975,0,0,0-5.977,5.977v8.965a1.793,1.793,0,0,0,1.793,1.793h4.781a1.793,1.793,0,0,0,1.793-1.793V12.41A1.793,1.793,0,0,0,17.332,10.617Zm-10.758,0H3.586V8.227A2.393,2.393,0,0,1,5.977,5.836h.3a.894.894,0,0,0,.9-.9V3.146a.894.894,0,0,0-.9-.9h-.3A5.975,5.975,0,0,0,0,8.227v8.965a1.793,1.793,0,0,0,1.793,1.793H6.574a1.793,1.793,0,0,0,1.793-1.793V12.41A1.793,1.793,0,0,0,6.574,10.617Z"
                                                transform="translate(0 -2.25)"
                                                fill="#dc6641"
                                            />
                                        </svg>
                                    </span>{element.content} <span className="rightQuote">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19.125"
                                                height="16.734"
                                                viewBox="0 0 19.125 16.734"
                                            >
                                                <path
                                                    id="Icon_awesome-quote-right"
                                                    data-name="Icon awesome-quote-right"
                                                    d="M17.332,2.25H12.551a1.793,1.793,0,0,0-1.793,1.793V8.824a1.793,1.793,0,0,0,1.793,1.793h2.988v2.391A2.393,2.393,0,0,1,13.148,15.4h-.3a.894.894,0,0,0-.9.9v1.793a.894.894,0,0,0,.9.9h.3a5.975,5.975,0,0,0,5.977-5.977V4.043A1.793,1.793,0,0,0,17.332,2.25Zm-10.758,0H1.793A1.793,1.793,0,0,0,0,4.043V8.824a1.793,1.793,0,0,0,1.793,1.793H4.781v2.391A2.393,2.393,0,0,1,2.391,15.4h-.3a.894.894,0,0,0-.9.9v1.793a.894.894,0,0,0,.9.9h.3a5.975,5.975,0,0,0,5.977-5.977V4.043A1.793,1.793,0,0,0,6.574,2.25Z"
                                                    transform="translate(0 -2.25)"
                                                    fill="#dc6641"
                                                />
                                            </svg>
                                        </span></p>
                                    <h5 className="slider-h5">{element.subTitle}
                                    </h5>
                                </div>
                            </div>
                        )
                    })}
                </div>
                </div>
            </div>
        </div>
    )
}


export default WhyDevelopersTrustCaourselComponent;