import React, { useEffect, useState } from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '@splidejs/react-splide/css';
import 'animate.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import { useLocation, useParams } from 'react-router-dom';

import { useApp } from "../hooks/AppHooks";
import { CMS_PORTAL_BUCKET } from "../common/Constants";
import AgentDevelopersPage from "./AgentDevelopersPage"
import WhoWeAreComponent from "../Components/home/WhoWeAreComponent"
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";
import WhyDevelopersTrustCaourselComponent from "../Components/home/WhyDevelopersTrustCaourselComponent";
import { Spin } from "antd";

function HomePage(props) {
    const { section } = useParams();
    const { homeValue, headerValue } = useApp();
    const location = useLocation();

    const carousl = () => {
        $(function () {
            $(".slider").not('.slick-initialized').slick({
                infinite: true,
                arrows: false,
                dots: false,
                autoplay: false,
                speed: 800,
                slidesToShow: 1,
                slidesToScroll: 1,
                playsinline: true,
                preload: true
            });

            //ticking machine
            var percentTime;
            var tick;
            var time = 1;
            var progressBarIndex = 0;

            $('.progressBarContainer .progressBar').each(function (index) {
                var progress = "<div class='inProgress inProgress" + index + "'></div>";
                $(this).html(progress);
            });

            function startProgressbar() {
                resetProgressbar();
                percentTime = 0;
                tick = setInterval(interval, 7);
            }

            function interval() {
                if (($('.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
                    progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data("slickIndex");
                    startProgressbar();
                } else {
                    percentTime += 1 / (time + 5);
                    $('.inProgress').parent().parent().removeClass("yellow-text");
                    $('.inProgress' + progressBarIndex).parent().parent().addClass("yellow-text");
                    $('.inProgress' + progressBarIndex).css({
                        width: percentTime + "%"
                    });
                    if (percentTime >= 100) {
                        $('.single-item').slick('slickNext');
                        progressBarIndex++;
                        if (progressBarIndex > 2) {
                            progressBarIndex = 0;
                        }
                        startProgressbar();
                    }
                }
            }

            function resetProgressbar() {
                $('.inProgress').css({
                    width: 0 + '%'
                });
                clearInterval(tick);
            }

            startProgressbar();
            // End ticking machine

            $('.progressBarContainer div').click(function () {
                clearInterval(tick);
                var goToThisIndex = $(this).find("span").data("slickIndex");
                $('.single-item').slick('slickGoTo', goToThisIndex, false);
                startProgressbar();
            });
        });
    }

    useEffect(() => {
        if (homeValue) {
            getHomeData();
        }
        carousl()
    }, [homeValue]);

    if (homeValue) {
        carousl()
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (location.hash) {
            let elem = document.getElementById(location.hash.slice(1));
            if (elem) {
                window.scrollTo({ top: elem.offsetTop - 100, left: 0, behavior: "smooth" });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
    }, [location.hash])

    const getHomeData = () => {
        if (section) {
            let anchor = document.createElement('a');
            anchor.href = `#${section}`;
            anchor.click();
        }
    }

    return (
        <>
            <BecomeOurPartnerButton isOpen={props?.isOpen} />
            {homeValue && Object.keys(homeValue)?.length && headerValue ?
                <div className="mainHomePage" id="banner">
                    {homeValue.Banner && homeValue.Banner?.length ?
                        <section id="slideshow">
                            <div className="slick">
                                <div className="sliderContainer ">
                                    <div className="slider single-item">
                                        {
                                            homeValue?.Banner?.map((row, index) => {
                                                let src = `https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${row.videoLink}`;
                                                return (
                                                    <>
                                                        <div key={index}>
                                                            <div className="autoplay-video">
                                                                <Video className="videoPlayer" preload={"auto"} playsInline autoPlay loop muted src={src} />
                                                                <div className="hello position-absolute">
                                                                    <span className={`${row.content ? "anytimeVideoHeading" : null }`}>{row.content}</span>
                                                                </div>
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="projectDetailSlider ">
                                                                            <h1>{row.title}</h1>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                                    <div className="sliderProgressBarClass">
                                        <div className="container">
                                            <div className="row">
                                                <div className="progressBarContainer">
                                                    {homeValue?.Banner?.map((e, i) =>
                                                        <div className="progressLine">
                                                            <span data-slick-index={i} className="progressBar"></span>
                                                            <h3>{e.subTitle}</h3>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                        : null}
                    <AgentDevelopersPage />
                    <WhoWeAreComponent />
                    {homeValue["Why Developers Trust Us"] ? <WhyDevelopersTrustCaourselComponent data={homeValue["Why Developers Trust Us"]} /> : null}
                    {homeValue["Our Agents Network"] && homeValue["Our Agents Network"]?.length ?
                        <div className="ourOffices" id="our-agents-network">
                            <img src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${homeValue["Our Agents Network"][0]?.backgroundImage}`} className="img-fluid" />
                            <div className="bannerImageHeading container">
                                <div className="aboutContentImage">
                                    <h3 className="marginBottom">
                                        <span className="underLineOurOffices">
                                            {homeValue["Our Agents Network"][0]?.heading}
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div> : null}
                </div > : <Spin className="page-spin-loading"/>}
        </>
    )
}
export default HomePage;