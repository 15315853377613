import { useState, useEffect } from "react";
import AmenitiesModalComponent from "../project-detail/AmenitiesModalComponent"
import { Tabs, Tab } from 'react-bootstrap'
import { CMS_API_ENDPOINT, CMS_PORTAL_BUCKET, WEBSITE_X_API_KEY } from "../../common/Constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useApp } from "../../hooks/AppHooks";

function AmenitiesComponent() {
    // amenities={specificProjectValue?.amenities} title={projectBannerValue?.Content[0]?.projectDetails[1]?.amenities}projectBannerValue?.Content[0]
    const { specificProjectValue, projectBannerValue } = useApp();

    const [isAmenitiesModalShow, setIsAmenitiesModalShow] = useState(false);
    const [amenities, setAmenities] = useState([]);
    const location = useLocation();
    let langId = location.search.split("=")[1];

    useEffect(() => {
        featchAmenities()
    }, [projectBannerValue?.Content&&projectBannerValue?.Content[0]?.projectDetails[1]?.amenities]);

    async function featchAmenities() {
        const amenitiesData = await axios
            .get(`${CMS_API_ENDPOINT}cms-portal-content-management/amenitie-website-list?languageId=${langId ? langId : 1}&amenitie=${specificProjectValue?.amenities ? JSON.stringify(specificProjectValue?.amenities) : [""]}`, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
        if (amenitiesData.status === 200) {
            setAmenities(amenitiesData?.data?.data)
        }
    }

    const closeAmenitiesModalModal = () => {
        setIsAmenitiesModalShow(false);
    }
    return (
        <>
            {projectBannerValue?.Content&&projectBannerValue?.Content[0] && specificProjectValue ?
                <div className="amenties">
                    <div className="amentiesHeadingSecion">
                                <div className='text-center my-3 justify-content-between'>
                                    {amenities?.length > 0 ?
                                        <div className="row">
                                            {amenities.map((amenity) =>
                                                <div className="col-md-3 col-4 pb-md-4 text-align-center" key={amenity}>
                                                    <div className="amenitiesIcons">
                                                        <img alt={amenity} src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${amenity.fileS3Path && amenity.fileS3Path}`} className="img-fluid" width={"50"} />
                                                        <p className="mb-0">{amenity ? amenity.name : ''}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <> <div className="row"><div className="col text-center">No data available.</div></div></>}
                                </div>
                    </div>
                </div> : null}

            <AmenitiesModalComponent show={isAmenitiesModalShow} onHide={closeAmenitiesModalModal} />
        </>
    )
}
export default AmenitiesComponent;







