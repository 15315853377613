import React from "react";
import ScrollToTop from "react-scroll-to-top";

function WatsupComponent() {
    return (
        <>
            <div className="watsupImage">
                <ScrollToTop smooth />
            </div>
        </>
    )
}
export default WatsupComponent;