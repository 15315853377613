import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import BecomeOurPartnerButton from "../Components/BecomeOurPartnerButton";

function NewsPage() {
    const publicPath = process.env.PUBLIC_URL;
    const [newsData, setnewsData] = useState([])
    async function fetchnewsData() {
        try {
            const res = await axios.get(publicPath + "/assets/data/news.json");
            if (res.data) {
                setnewsData(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }
    useEffect(() => {
        fetchnewsData();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }, [])
    return (
        <>
        <BecomeOurPartnerButton/>
            <div className="newPortalNewsSection">
                {/* <WatsupComponent/> */}
                <div className="newsImageBox commanBannerImageMobile">
                    <img src={publicPath + "/assets/images/bannerImage/newsBanner.png"} className="bannerImage img-fluid" />
                    <div className="bannerImageHeading container">
                        <div className="newsContentImage">
                                <h3 className="marginBottom">Articles /</h3>
                                <h3 className="marginBottomhide">/</h3>
                                <h3>News</h3>
                                {/* <button>Know More</button> */}
                            </div>
                    </div>
                </div>
                <div className="newsContentSection">
                    <div className="container">
                        {
                            newsData.map((row, index) => {
                                return (
                                    <>
                                        <div className="newsData" key={row.id}>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="newsImageBoxContent">
                                                        <img src={row.news_image} className="img-fluid" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="newsHeadingBox">
                                                        <h3>{row.news_heading}</h3>
                                                        <p>{row.news_date}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="newsDescriptionBox">
                                                        <p>{row.news_description}</p>
                                                        <div>
                                                            <a href="newsdetail">{row.news_button}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewsPage;