import React from "react";

import { DefaultPlayer as Video } from 'react-html5video';

import { CMS_PORTAL_BUCKET } from "../../common/Constants";
import { useApp } from "../../hooks/AppHooks";

function ForDevelopersComponent() {
    const { devloperValue } = useApp();

    return (
        <>
            {devloperValue && Object.keys(devloperValue).length && devloperValue["For Developers"] && devloperValue["For Developers"]?.length ?
                < div className="forDevelopersContent">
                    <div className="container" >
                        <div className="row justify-content-between" id="for-developer-content">
                            <div className="col-md-6">
                                <h5 style={{ marginBottom: 20 }}>{devloperValue["For Developers"] && devloperValue["For Developers"][0]?.title}</h5>
                                {devloperValue["For Developers"]?.slice(0, 4)?.map((data, i) => {
                                    return (
                                        <p key={i}>{data.content}</p>
                                    )
                                })}
                            </div>
                            <div className="col-md-6 videoSectionDevelopers">
                                <Video className="videoPlayer" preload playsInline autoPlay controls={false}
                                    playButton muted
                                    src={`https://${CMS_PORTAL_BUCKET}.s3.amazonaws.com/public/content-management/${devloperValue["For Developers"] && devloperValue["For Developers"][0]['videoLink']}`} />
                            </div>
                            <p>
                                <br />{devloperValue["For Developers"]?.slice(4, 5).content}
                            </p>
                            {devloperValue["For Developers"]?.slice(4)?.map((data, i) => {
                                return (
                                    <p key={i}>{data.content}</p>
                                )
                            })}
                        </div>
                    </div>
                </div >
                : null
            }
        </>
    )
}
export default ForDevelopersComponent;