import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import "../src/sass/new.scss"
import "../src/sass/letter.scss"
import "../src/sass/info.scss"
import "../src/sass/custom.scss"
import "../src/App.css"
import PublicLayout from './layouts/PublicLayout';
import AboutUs from "./Pages/AboutUs";
import HomePage from "./Pages/HomePage";
import ProjectPage from "./Pages/ProjectPage";
import AgentsPage from './Pages/AgentsPage';
import DevelopersPage from './Pages/DevelopersPage';
import BlogPage from './Pages/BlogPage';
import NewsPage from './Pages/NewsPage';
import ContactPage from "./Pages/ContactPage"
import ProjectDetail from "./Pages/ProjectDetail"
import BlogDetailPage from './Pages/BlogDetailPage';
import WatsupComponent from './Components/WatsupComponent';
import TermsOfUsePage from './common/TermsOfUsePage';
import SiteMapPage from './common/SiteMapPage';
import PrivacyPolicyPage from './common/PrivacyPolicyPage';
import TemaMarketingPage from "../src/Pages/marketingPages/TemaMarketingPage";
import SkylandMarketingPage from "../src/Pages/marketingPages/SkylandMarketingPage";
import AlyaEnglishMarketingPage from "../src/Pages/marketingPages/alya/AlyaEnglishMarketingPage";
import AlyaTurkishMarketingPage from "../src/Pages/marketingPages/alya/AlyaTurkishMarketingPage";
import CookiePolicy from "./Pages/CookiePolicyPage";
import CityScape from "./Pages/marketingPages/cityscape/CityScape";
import CityScape1 from "./Pages/marketingPages/cityscape1/CityScape1";
import MipimPage from "./Pages/MipimPage";

function App() {
  const location = useLocation();
  const pathName = location.pathname.split("/")[2];

  return (
    <>
      <WatsupComponent />
      <Suspense >
        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route exact path="/:section" element={<HomePage />}></Route>
            <Route exact path="/" element={<HomePage />}></Route>
            <Route path="aboutus" element={<AboutUs />}></Route>
            <Route path="aboutus/:id" element={<AboutUs />}></Route>
            <Route path="agents" element={<AgentsPage />}></Route>
            <Route path="agents/:id" element={<AgentsPage />}></Route>
            <Route path="developer" element={<DevelopersPage />}></Route>
            <Route path="developer/:id" element={<DevelopersPage />}></Route>
            <Route path="projects" element={<ProjectPage />}></Route>
            <Route path="projects/:id"
              element={pathName === "banner" || pathName === "current-previous-title" || pathName === "current-previous-description"
                ? <ProjectPage /> : <ProjectDetail />}>
            </Route>
            <Route path="blogs" element={<BlogPage />}></Route>
            <Route path="blogs/:id" element={<BlogPage />}></Route>
            <Route path="news" element={<NewsPage />}></Route>
            <Route path="contact" element={<ContactPage />}></Route>
            <Route path="contact/:id" element={<ContactPage />}></Route>
            <Route path="terms_and_conditions" element={<TermsOfUsePage />}></Route>
            <Route path="terms_and_conditions/:id" element={<TermsOfUsePage />}></Route>
            <Route path="privacy_policy" element={<PrivacyPolicyPage />}></Route>
            <Route path="privacy_policy/:id" element={<PrivacyPolicyPage />}></Route>
            <Route path="sitemap" element={<SiteMapPage />}></Route>
            <Route path="sitemap/:id" element={<SiteMapPage />}></Route>
            {/* <Route path="alya4mevsim_en" element={<AlyaEnglishMarketingPage />}></Route>
            <Route path="alya4mevsim_tr" element={<AlyaTurkishMarketingPage />}></Route> */}
            <Route path="skyland" element={<SkylandMarketingPage />}></Route>
            <Route path="cityscape" element={<CityScape />}></Route>
            <Route path="cityscape1" element={<CityScape1 />}></Route>
            <Route path="temaIstanbul" element={<TemaMarketingPage />}></Route>
            <Route path="/blog/:id" element={<BlogDetailPage />}></Route>
            <Route path="cookie-policy" element={<CookiePolicy />}></Route>
            <Route path="cookie-policy/:id" element={<CookiePolicy />}></Route>
            <Route path="become-our-partner" element={<HomePage isOpen={true} />}></Route>
            <Route path="mipim" element={<MipimPage />}></Route>
            <Route path="create-appointment" element={<ContactPage isOpenForAppointment={true} />}></Route>
            <Route path="enquire-about-project" element={<ContactPage isOpenForEnquireProject={true} />}></Route>
            <Route path="enquire-for-project" element={<ProjectPage isOpenEnquireForProject={true} />}></Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
