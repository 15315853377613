import React from "react";

const Banner = () => {
    return (
        <>
            <div className="bannerSection">
                <div className="bannerSection">
                    <div className="bannerContent">
                        <h6>Make Cubedots Your</h6>
                        <h6>Trusted Partner In</h6>
                        <h6>Turkey!</h6>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner;